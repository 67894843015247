<template>
    <div
        class="event"
        :class="[
            `--${size}`,
            {
                '--inactive':
                    !isSample &&
                    (!termine ||
                        isPastTermine ||
                        isPendingAttendance ||
                        termine?.canceled_at),
            },
        ]"
    >
        <ion-nav-link
            router-direction="forward"
            :class="{ '--clickable': redirect }"
            @click="
                redirect
                    ? pushStateRoute(
                          {
                              name: 'app-event-slug',
                              params: { slug: event.slug },
                              query: { t: termine?.id },
                          },
                          {
                              event,
                          }
                      )
                    : null
            "
        >
            <div class="event__body">
                <figure class="event__bg-image">
                    <!-- prettier-ignore -->
                    <ImageResource
                        v-if="eventStore.getThumbnail(event)"
                        :src="eventStore.getThumbnail(event)[size == 'lg' ? 'portraitThumbnail' : 'thumbnail']"
                        alt=""
                    />
                    <img v-else :src="useDefaultEventImage()" />
                </figure>
                <div class="event__content">
                    <div
                        v-if="event.user"
                        class="event__organiser"
                        @click="eventStore.goToUser(event)"
                    >
                        <CircleAvatar
                            :src="useActualUser(event.user)?.thumbnail"
                            :avatar="true"
                            :size="24"
                        />

                        <div class="event__info">
                            <strong>
                                {{ useActualUser(event.user)?.username }}
                            </strong>
                        </div>
                    </div>

                    <div class="event__header">
                        <div class="event__header-content">
                            <div class="d-flex gap-1">
                                <div
                                    class="event__type"
                                    v-if="eventName != sportName"
                                >
                                    {{ sportName }}
                                </div>

                                <slot name="badges" />
                            </div>

                            <h3 class="event__name">
                                {{ eventName }}
                            </h3>
                            <div class="event__stats">
                                <div class="event__location">
                                    <ion-icon :icon="locationOutline" />
                                    <!-- prettier-ignore -->
                                    <div class="wrap-text">{{ event.location?.name || __('Vlastná poloha') }}</div>
                                </div>
                                <!-- <div class="event__level">
                                    <ion-icon :icon="statsChart" />
                                    {{ eventStore.getName(event) }}
                                </div> -->
                                <div
                                    class="event__comments"
                                    v-if="event.stats_messages"
                                    @click="eventStore.goToChat(event)"
                                >
                                    <ion-icon
                                        :icon="chatboxEllipses"
                                    ></ion-icon>
                                    {{ event.stats_messages }}
                                </div>
                                <div
                                    class="event__members"
                                    @click.prevent.stop="
                                        useLazyClick($event, eventAttendance)
                                    "
                                >
                                    <ion-icon :icon="peopleOutline" />
                                    <!-- prettier-ignore -->
                                    {{ __('Pripojených %d', attendeesCount) }}
                                </div>
                            </div>
                        </div>
                        <div class="event__date" v-if="termine">
                            <template v-if="termine.date">
                                <strong>
                                    {{
                                        termine.has_time
                                            ? moment(termine.date).format(
                                                  'HH:mm'
                                              )
                                            : __('žiaden čas')
                                    }}
                                </strong>
                                <span>
                                    {{
                                        moment(termine.date).format('MMMM, DD.')
                                    }}
                                </span>
                            </template>
                            <template v-else>
                                <strong>{{ __('Kedykoľvek') }}</strong>
                                <span class="text-transform-none">
                                    {{ __('Bez presného dátumu') }}
                                </span>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </ion-nav-link>

        <div class="event__footer" v-if="footer">
            <slot name="footer">
                <template v-if="!isDeleted">
                    <EventChat
                        v-if="useIsMe(event.user) || termine?.approved"
                        :event="event"
                    />

                    <EventActions
                        v-if="useIsMe(event.user)"
                        :event="event"
                        :termine="termine"
                        :buttons="{ share: false }"
                    />
                </template>

                <EventButtons
                    v-if="termine"
                    :event="event"
                    :termine="termine"
                    :counter="true"
                    :canShowNextTermine="canShowNextTermine"
                    :canShowPastTermine="canShowPastTermine"
                />
            </slot>
        </div>
    </div>
</template>

<script setup>
import moment from 'moment';
import _ from 'lodash';
import {
    chatboxEllipses,
    peopleOutline,
    locationOutline,
} from 'ionicons/icons';

const props = defineProps({
    event: {},
    termine: {},
    redirect: {
        default: true,
    },
    isSample: {
        default: false,
    },
    size: {
        type: String,
        default: 'md',
    },
    footer: {
        default: false,
    },
    canShowNextTermine: {
        default: true,
    },
    canShowPastTermine: {
        default: true,
    },
});

const sportStore = useSportStore();
const authStore = useAuthStore();
const eventStore = useEventStore();

//TODO: Remporary fix !!!!!!!!
const event = computed(() => props.event || eventStore.sampleEvent);

const termine = computed(
    () => props.termine || useGetDefaultTermine(event.value?.termines)
);

const attendeesCount = computed(() => {
    return termine.value?.attendees_accepted || event.value.stats_players || 1;
});

const sportName = computed(() => {
    return sportStore.getById(event.value.sport_id)?.name;
});

const eventName = computed(() => {
    return event.value.name || sportName.value;
});

const isPendingAttendance = computed(() => {
    let attendance = _.find(termine.value?.attendees || [], {
        id: authStore.user?.id,
    });

    return ['pending', 'declined'].includes(
        termine.value?.attendance_status || attendance?.status
    )
        ? true
        : false;
});

const isPastTermine = computed(() => {
    return isFutureTermine(termine.value) === false;
});

const isDeleted = computed(() => {
    return event.value.deleted_at ? true : false;
});

const eventAttendance = async () => {
    if (!authStore.loggedIn) {
        return;
    }

    await useWithAttendees(termine.value, (attendees) => {
        useOpenModal('event-attendance', {
            event: event.value,
            termine: termine.value,
            attendees,
        });
    });
};
</script>

<style lang="scss" scoped>
.event__body {
    height: 14rem;
    position: relative;
    border-radius: 1.6rem;
    overflow: hidden;

    .event.--lg & {
        height: 40rem;
    }
}

ion-nav-link.--clickable {
    cursor: pointer;
}

.event__bg-image {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;

    .event.--inactive & {
        filter: grayscale(1);
    }

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background-image: linear-gradient(
            179deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.7) 100%
        );
    }

    :deep(img) {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.event__organiser {
    display: flex;
    align-items: center;
    gap: 1rem;

    .event.--lg & {
        position: absolute;
        left: 2rem;
        top: 2.5rem;
    }
}

.event__info {
    display: flex;
    flex-direction: column;

    strong {
        font-weight: 500;
        font-size: 1rem;
        color: #ffffff;
    }

    span {
        font-weight: 400;
        font-size: 1rem;
        color: #89cbd2;
    }
}

.event__content {
    position: relative;
    z-index: 2;
    padding: 1.2rem;
    height: 100%;
    display: flex;
    flex-direction: column;

    .event.--lg & {
        padding: 2rem;
    }
}

.event__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 1rem;
    margin-top: auto;
}

.event__date {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    font-family: var(--secondary-font-family);

    .event.--lg & {
        position: absolute;
        right: 2rem;
        top: 2rem;
    }

    strong {
        font-weight: 700;
        font-size: 3.2rem;
        color: #ffffff;
        line-height: 1;
    }

    span {
        font-weight: 600;
        font-size: 1.4rem;
        color: #ffffff;
        text-transform: capitalize;

        &.text-transform-none {
            text-transform: none;
        }
    }
}

.event__type {
    font-weight: 700;
    font-size: 0.9rem;
    color: #ffffff;
    display: inline-flex;
    padding: 0.2rem 0.7rem;
    border-radius: 2rem;
    background: var(--ion-color-primary);

    .event.--inactive & {
        color: #000;
        background: #fff;
    }
}

.event__name {
    margin: 0.55rem 0 0.55rem;
    font-weight: 700;
    font-size: 2.2rem;
    font-family: var(--secondary-font-family);
    color: #ffffff;
    letter-spacing: -0.19px;
    line-height: 125%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    .event.--lg & {
        font-size: 3.2rem;
        letter-spacing: -0.31px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0.75rem 0;
    }
}

.event__stats {
    display: flex;
    align-items: center;
    gap: 1rem;

    .event.--lg & {
        gap: 1.5rem;
    }
}

.event__location,
.event__comments,
.event__level,
.event__members {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.9rem;
    color: #ffffff;
    gap: 0.5rem;
    max-width: 12rem;
    white-space: nowrap;

    .wrap-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: calc(100% - 1rem);
    }

    .event.--lg & {
        font-size: 1.2rem;
    }

    > * {
        flex-shrink: 0;
    }
}

.event__footer {
    margin-top: 1.5rem;
    min-height: 4.5rem;

    :deep(.loader) {
        position: relative;
        top: 0.5rem;
    }

    &:empty {
        display: none;
    }
}
</style>
