<template>
    <AppModal :modal="modal" :title="data?.title || __('Potvrdenie')">
        <p v-if="data && data.message">{{ data.message }}</p>

        <div class="d-flex gap-1">
            <FormButton
                full
                visual="secondary"
                @click="
                    useLazyClick(
                        $event,
                        data.cancel
                            ? data.cancel
                            : () => {
                                  useCloseModal(modal);
                              }
                    )
                "
            >
                {{ data?.cancelText || __('Zrušiť') }}
            </FormButton>

            <FormButton full @click="useLazyClick($event, onSuccess)">
                {{ data?.confirmText || __('Potvrdiť') }}
            </FormButton>
        </div>
    </AppModal>
</template>

<script setup>
const props = defineProps({
    modal: { default: 'modal-confirm' },
});

const data = computed(() => useGetModalData(props.modal));

const onSuccess = async () => {
    let response = await data.value.success();

    //Do not auto close modal
    if (response === false) {
        return;
    }

    useCloseModal(props.modal);
};
</script>

<style lang="scss" scoped>
a.link {
    color: black;
    font-weight: 600;
    text-decoration: underline;
    font-size: 1.2rem;
}

p {
    font-size: 1.4rem;
    padding: 0;
    margin: 0 0 2rem 0;
}
</style>
