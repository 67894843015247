<template>
    <ion-modal
        class="sheet-modal"
        :is-open="useIsModalOpen(modal)"
        :initial-breakpoint="1"
        :breakpoints="[0, 1]"
        :showBackdrop="backdrop"
        :canDismiss="canDismiss"
        @willDismiss="backdrop = false"
        @didDismiss="
            backdrop = true;
            useCloseModal(modal);
            emit('close', false);
        "
    >
        <ion-header
            v-if="header && (!autoSelect || hasCancelButton || canSave)"
            :class="{ '--center': !hasCancelButton && !canSave }"
        >
            <ion-toolbar :class="{ '--big': title }">
                <ion-title>
                    <div class="title">
                        {{ name || __('Vyberte') }}
                        <small v-if="title">{{ title }}</small>
                    </div>
                </ion-title>

                <ion-buttons slot="end">
                    <ion-button
                        v-if="hasCancelButton"
                        class="--cancel"
                        @click="
                            change(props.default || (isArrayValue ? [] : null));
                            save($event);
                            useCloseModal();
                        "
                    >
                        {{ cancelText || __('Zrušiť') }}
                    </ion-button>

                    <ion-button
                        v-if="canSave"
                        class="--save"
                        @click="saveButton($event)"
                    >
                        {{
                            loading
                                ? __('Ukládam...')
                                : isLazy
                                  ? chooseText || __('Uložiť')
                                  : chooseText || __('Vybrať')
                        }}
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <div class="sheet-modal__container" :class="{ '--full': full }">
            <slot
                :callback="useGetModal(modal)?.callback"
                :data="useGetModal(modal)?.callback"
                :loading="loading"
                :value="toRaw(value)"
                :change="change"
                :save="save"
                :$cleanAttrs="
                    _.pickBy(
                        $attrs,
                        (v, k) =>
                            ![
                                'value',
                                'modelValue',
                                'onUpdate:modelValue',
                            ].includes(k)
                    )
                "
            />
        </div>
    </ion-modal>
</template>

<script setup>
import _ from 'lodash';

const props = defineProps({
    modal: {
        default: 'dynamic-modal',
    },
    isOpen: {
        type: Boolean,
        default: false,
    },
    header: {
        default: true,
    },
    name: {},
    title: {},
    full: {
        default: false,
    },
    modelValue: {},
    isLazy: {
        default: false,
    },
    dynamicSelect: {
        default: false,
    },
    autoSelect: {
        default: false,
    },
    autoClose: {
        default: true,
    },
    canClear: {
        default: false,
    },
    canSave: {
        default: true,
    },
    multiple: {
        default: false,
    },
    default: {
        default: null,
    },
    saveDelay: {
        default: 0,
    },
    chooseText: {
        default: null,
    },
    cancelText: {
        default: null,
    },
});

const emit = defineEmits(['close', 'save', 'input', 'update:modelValue']);

const value = ref(props.modelValue || props.default);

//Temporary IOS bug, when closing modal, backdrop blinks.
const backdrop = ref(true);

const loading = ref(false);

const isLazy = computed(() => props.isLazy !== false && !_.isNil(props.isLazy));

const autoClose = computed(
    () => props.autoClose !== false && !_.isNil(props.autoClose)
);

const isArrayValue = computed(() => _.isArray(value.value));

const hasCancelButton = computed(
    () =>
        props.canClear &&
        (isArrayValue.value ? value.value?.length : value.value)
);

const resetModal = () => {
    loading.value = false;

    // Reset value
    value.value = props.modelValue || props.default;
};

useOnModalOpen(props.modal, resetModal);
watch(() => props.isOpen, resetModal);

watch(
    () => props.modelValue,
    () => {
        value.value = props.modelValue;
    }
);

const change = (e) => {
    value.value = e;

    //Select and close
    if (props.autoSelect) {
        save();
    }

    //Fire change value
    if (props.dynamicSelect) {
        save(null, false);
    }
};

const save = async (event, close = true) => {
    const i = getCurrentInstance();

    if (isLazy.value) {
        await useLazyClick(event, async () => {
            loading.value = true;

            await new Promise((resolve) => {
                emit('input', {
                    value: value.value,
                    callback: () => {
                        resolve();
                    },
                });
            });

            loading.value = false;
        });
    } else {
        emit('update:modelValue', value.value);
        emit('input', value.value);
    }

    //Auto close modal
    if (close !== false && autoClose.value) {
        useCloseModal();
    }
};

const saveButton = async ($event) => {
    //BUG: for wheel datepicker we need wait till wheel ends and value changes.
    if (props.saveDelay) {
        await useSleep(props.saveDelay);
    }

    emit('save');

    save($event);
};

const canDismiss = (data, role) => {
    // Disable swipe and click on backdrop when is lazy component
    if (isLazy.value && ['gesture', 'backdrop'].includes(role)) {
        return false;
    }

    return true;
};
</script>

<style lang="scss" scoped>
.--cancel {
    position: relative;
    left: -4rem;
}

ion-header.--center {
    ion-title {
        text-align: center !important;
    }

    ion-toolbar {
        padding-top: 1rem !important;
    }
}

ion-toolbar.--big {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
}

.title {
    display: flex;
    flex-direction: column;

    small {
        font-weight: 400;
        font-size: 1.4rem;
        padding-top: 0.5rem;
        color: #021e26;
    }
}
</style>
