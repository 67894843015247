<template>
    <div
        class="app-logo"
        :class="{ '--light': light, '--dev': !appStore.isProdApi }"
        @click="useDevModeToggle()"
    ></div>
</template>

<script setup>
import _ from 'lodash';

const props = defineProps({
    light: {
        default: false,
    },
});

const appStore = useAppStore();

/**
 * Debug mode toggle
 */
const debugCounter = ref(0);

// Create debounced reset function
const resetDebugCounter = _.debounce(() => {
    debugCounter.value = 0;
}, 1000);

const useDevModeToggle = () => {
    debugCounter.value++;

    if (debugCounter.value >= 5) {
        debugCounter.value = 0;

        useOpenModal('app-environment');
    } else {
        resetDebugCounter();
    }
};
</script>

<style lang="scss" scoped>
.app-logo {
    width: 22.5rem;
    height: 4rem;
    background: url($basePath + 'icons/logo-dark.svg') no-repeat center;
    background-size: contain;
    position: relative;
    cursor: pointer;

    &.--light {
        background: url($basePath + 'icons/logo.svg') no-repeat center;
        background-size: contain;
    }

    &.--dev {
        &:before {
            content: 'DEV MODE';
            color: red;
            position: absolute;
            bottom: -2.2rem;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 600;
        }
    }
}
</style>
