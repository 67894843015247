<template>
    <VOnboardingWrapper
        ref="onboarding"
        :steps="steps"
        @finish="onClose"
        class="onboarding_wrapper"
    >
        <template #default="{ next, step, isLast, index }" class="test">
            <VOnboardingStep
                v-if="step"
                :class="{
                    '--pinned': step.pinned,
                    '--transparent': step.transparent,
                }"
                :style="{
                    '--pinned-top': step.pinned
                        ? getPinnedElementPos(step.pinned, 'y')
                        : 0,
                    '--pinned-left': step.pinned
                        ? getPinnedElementPos(step.pinned, 'x')
                        : 0,
                }"
            >
                <div class="onboarding-step__content">
                    <div v-if="step.content">
                        <h3 v-if="step.content.title">
                            {{ step.content.title }}
                        </h3>
                        <p v-if="step.content.description">
                            {{ step.content.description }}
                        </p>
                    </div>
                    <div class="cta">
                        <span @click="finish" type="button" class="finish">
                            {{ __('Preskočiť tutoriál') }}
                        </span>
                        <button @click="next" type="button" class="next">
                            {{ isLast ? __('Hotovo') : __('Ďalej') }}
                        </button>
                    </div>
                    <div class="indicator">
                        <span
                            :style="{
                                width: ((index + 1) / steps.length) * 100 + '%',
                            }"
                        ></span>
                    </div>
                </div>
            </VOnboardingStep>
        </template>
    </VOnboardingWrapper>
</template>

<script setup>
import {
    VOnboardingWrapper,
    VOnboardingStep,
    useVOnboarding,
} from 'v-onboarding';

const router = useRouter();
const onboarding = ref(null);
const onboardingStore = useOnboardingStore();
const filterStore = useFilterStore();
const eventStore = useEventStore();

//Reset state
if (onboardingStore.tutorialOpened) {
    onboardingStore.tutorialOpened = false;
}

const { start, goToStep, finish } = useVOnboarding(onboarding);

const steps = [
    {
        attachTo: { element: '[data-tutorial="filter"]' },
        content: {
            title: __('Nájdi športových partnerov'),
            description: __(
                'Scorentino ti umožňuje jednoducho nájsť a spojiť sa s novými športovými partnermi, aby si mal vždy s kým trénovať, hrať alebo len tráviť čas aktívne.'
            ),
        },
        on: {
            beforeStep: () => {
                filterStore.form.type = 'event';

                fixScroll();
            },
        },
    },
    eventStore.isEventTypeEnabled('training')
        ? {
              attachTo: { element: '[data-tutorial="filter"]' },
              content: {
                  title: __('Nájdeme ti trénera'),
                  description: __(
                      'Pomôžeme ti nájsť a rezervovať kvalifikovaných trénerov, ktorí ti poskytnú individuálnu pozornosť a odborné vedenie v tvojom obľúbenom športe.'
                  ),
              },
              on: {
                  beforeStep: () => {
                      filterStore.form.type = 'training';

                      fixScroll();
                  },
              },
          }
        : null,
    eventStore.isEventTypeEnabled('service')
        ? {
              attachTo: { element: '[data-tutorial="filter"]' },
              content: {
                  title: __('Nájdeme ti maséra'),
                  description: __(
                      'Pomôžeme ti nájsť a rezervovať profesionálnych masérov, ktorí ti pomôžu zlepšiť výkonnosť a urýchliť zotavenie po náročnom tréningu alebo zápase.'
                  ),
              },
              on: {
                  beforeStep: () => {
                      filterStore.form.type = 'service';

                      fixScroll();
                  },
              },
          }
        : null,
    {
        attachTo: { element: '[data-tutorial="create-event"]' },
        content: {
            title: __('Vytvor vlastnú partičku'),
            description: __(
                'Nenašiel/a si šport, ktorý hľadáš alebo ti nesadol čas? Vytvor vlastnú partičku, pozvi svojich kamarátov alebo len počkaj kým sa prihlásia ďalší účastníci.'
            ),
        },
        on: {
            beforeStep: () => {
                filterStore.form.type = 'event';

                fixScroll();
            },
        },
    },
    {
        attachTo: { element: '[data-tutorial="navbar"]' },
        pinned: 'ion-tab-button#tab-button-sports',
        transparent: true,
        content: {
            title: __('Manažér partičiek'),
            description: __(
                'Spravuj svoje partičky pomocou funkcií na organizovanie, plánovanie a komunikáciu s členmi tvojich partičiek, aby všetko prebiehalo hladko.'
            ),
        },
        on: {
            async beforeStep() {
                await router.push({ name: 'app-manager-listing' });

                await useSleep(350);

                fixScroll();
            },
            async afterStep() {
                await useGoToFeed();
            },
        },
    },
    // {
    //     attachTo: { element: '[data-tutorial="sports-field"]' },
    //     content: {
    //         title: _('Nájdi a rezervuj dostupné ihrisko'),
    //         description:
    //             _('Rýchlo a jednoducho si zabezpeč miesto na svoj športový tréning alebo zápas.'),
    //     },
    //     on: {
    //         async beforeStep() {
    //             await useGoToFeed();

    //             await useSleep(350);

    //             fixScroll();
    //         },
    //     },
    // },
].filter((item) => item);

function isHidden(el) {
    return el.offsetParent === null;
}

const isAnyModalOpen = computed(() => {
    return openedModals.value.length > 0;
});

useEmitter().on('tutorial.start', async () => {
    //Already opened
    if (onboardingStore.tutorialOpened) {
        return;
    }

    openTutorial();
});

watch(
    () => isAnyModalOpen.value,
    (state) => {
        // When any modal has been opened, close tutorial. Because click on modals may be bugged.
        if (state === true && onboardingStore.tutorialOpened) {
            finish();
        }
    }
);

const openTutorial = async () => {
    // Don't open tutorial when any modal is present.
    do {
        await useSleep(300);
    } while (isAnyModalOpen.value);

    //Check if attachTo element is visible,
    //Othwerise whole app bill be bugged
    const attachTo = steps[0]?.attachTo?.element,
        attachToElement = attachTo ? document.querySelector(attachTo) : null;

    if (!attachToElement || isHidden(attachToElement)) {
        return;
    }

    onboardingStore.tutorialOpened = true;

    start();
};

const getPinnedElementPos = (selector, position = 'x') => {
    let el = document.querySelector(selector);

    if (!el) {
        return 0;
    }

    let p = el.getBoundingClientRect();

    if (position == 'x') {
        return p.x + p.width / 2 + 'px';
    } else if (position == 'y') {
        return window.innerHeight - p.y + 'px';
    }
};

const onClose = () => {
    onboardingStore.tutorialOpened = false;

    useOnboardingStore().tutorial = true;

    useEmitter().emit('tutorial.close');
};

//When ion-content scrolls down, this plugin is not receiving scroll change events
//So we need readjust overlay by ourself
const fixScroll = () => {
    //Fix black overlay after scrool on element
    for (var duration of [250, 500, 750, 100]) {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, duration);
    }
};
</script>

<style lang="scss">
.onboarding_wrapper {
    --v-onboarding-overlay-opacity: 0.7;

    & > div {
        &.--transparent {
            /* Show partial visibility to content in case of pinned content*/
            --v-onboarding-overlay-opacity: 0.5;
        }

        &.--pinned {
            & > svg {
                height: calc(100% - var(--pinned-top)) !important;
            }

            & > div {
                inset: auto 0 var(--pinned-top) 0 !important;

                &:after {
                    content: '';
                    width: 0px;
                    height: 0px;
                    border-left: 1rem solid transparent;
                    border-right: 1rem solid transparent;
                    border-top: 1rem solid #92280e;
                    position: absolute;
                    left: var(--pinned-left);
                    transform: translateX(-50%);
                }
            }
        }

        & > div {
            transform: none !important;
            inset: auto 0 0 0 !important;
        }
    }

    .onboarding-step__content {
        background: var(--ion-color-primary);
        padding: 3.2rem;
        position: relative;

        h3 {
            font-family: var(--secondary-font-family);
            margin: 0 0 0.8rem;
            padding: 0;
            color: #fff;
            font-size: 2.4rem;
            font-weight: 900;
            line-height: 120%;
            letter-spacing: 0.048rem;
        }

        p {
            color: #fff;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2.2rem;
        }

        .cta {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .finish {
                color: #fff;
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 2.2rem;
                text-decoration-line: underline;
            }

            .next {
                appearance: none;
                font-family: var(--ion-font-family);
                padding: 1.1rem 1.6rem;
                display: inline-flex;
                border-radius: 1.2rem;
                background: #92280e;
                color: #fff;
                font-size: 1.6rem;
                font-weight: 600;
                line-height: 2.2rem;
                border: 0;

                &:focus {
                    outline: none;
                }
            }
        }

        .indicator {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 0.8rem;
            background: rgba(#b03c20, 0.75);

            span {
                display: block;
                height: 100%;
                background: #92280e;
                transition: all 0.2s;
            }
        }
    }
}
</style>
