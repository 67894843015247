import _ from 'lodash';

const defaultForm = {
    type: 'event',
    date: null,
    time: null,
    sport_ids: [],
    service_ids: [],
    location_ids: [],
    radius: null,
};

export const useFilterStore = defineStore('filter', {
    persist: true,

    state() {
        return {
            form: _.cloneDeep(defaultForm),

            //Data
            locations: [],
        };
    },

    actions: {
        resetTime() {
            this.date = null;
            this.time = null;
        },
        flushMyLocationFromFilter() {
            this.form.location_ids = _.xor(this.form.location_ids, [0]);
        },
        async getCurrentPositionData() {
            if (this.hasCurrentLocation) {
                const locationStore = useLocationStore();

                //Retreive current position if is missing
                await locationStore.fetchCurrentPosition();

                if (locationStore.position) {
                    return {
                        position: _.pick(locationStore.position, [
                            'lat',
                            'lng',
                        ]),
                    };
                } else {
                    this.flushMyLocationFromFilter();
                }
            }

            return {};
        },
    },
    getters: {
        hasCurrentLocation() {
            return this.form.location_ids.includes(0);
        },
        locationsWithMyLocation() {
            return [{ id: 0, name: __('Moja poloha') }].concat(this.locations);
        },
    },
});
