import { Network as CapacitorNetwork } from '@capacitor/network';
import _ from 'lodash';
import moment from 'moment';
// import imageDownloader from './imageDownloader.js';

export default class Network {
    /*
     * On boot of this method we will run all network informations required for app
     * also we'll try send all failed requests.
     *
     * Methods in this boot method needs to have remove listeners feature. Because boot may be stared many times in app run.
     */
    boot() {
        this.listenConnection();
    }

    getUser() {
        return useAuthStore().user;
    }

    // Returns how often app should reload all data
    getRefreshTimeout() {
        return useBackendEnv('APP_REFRESH_SECONDS')||600;
    }

    /*
     * This method will be initialized then connection changes or app state changes
     */
    initConnectionState(forceCheckOnNetworkBoot = false) {
        try {
            //We does not want check images on desktop...
            if ((isPlatform('mobileweb') || isPlatform('desktop')) === false) {
                // imageDownloader.checkFileStorage();
            }
        } catch (e) {
            console.error(e);
        }

        this.trySendIncompleteRequests(forceCheckOnNetworkBoot);

        this.initRefreshingApp();
    }

    /*
     * This method will send previous failed requests.
     * And then removes them from the failed stack.
     */
    async trySendIncompleteRequests(forceCheckOnNetworkBoot = false) {
        const appStore = useAppStore(),
            ajaxStore = useAjaxStore();

        //If internet is turned off. We does not need to try send requests...
        const canTry =
            appStore.connected == true && this.getUser() ? true : false;

        if (!canTry) {
            var toProcess = [];
        } else {
            var toProcess = _.cloneDeep(ajaxStore.unsent).filter((request) => {
                //Check only requests made during offline state
                if (forceCheckOnNetworkBoot) {
                    return request.connected == false;
                }

                //Only scheduled requests
                else {
                    return moment() >= moment(request.nextTryAt);
                }
            });
        }

        for (let request of toProcess) {
            //Request does not exists anymore.
            if (!_.find(ajaxStore.unsent, { uuid: request.uuid })) {
                return;
            }

            let requestMethodName = request.method.toUpperCase();

            try {
                await useAxios()['$' + request.method + 'Async'](
                    request.url,
                    request.data,
                    {
                        _forceCheck: forceCheckOnNetworkBoot,
                        _isRepeatedTry: true,
                        // prettier-ignore
                        callback(){
                            //We can remove this request from unsent stack
                            ajaxStore.removeRequest(request);

                            console.log('[' + requestMethodName + ' SENT AGAIN]', request);
                        },
                    }
                );
            } catch (e) {
                console.log('[' + requestMethodName + ' FAILED]', request);

                ajaxStore.addErrorCount(request.uuid);
            }
        }

        //In case of multiple calls of this method. Clear previous timeouts.
        if (this._incompletedTimeout) {
            clearTimeout(this._incompletedTimeout);
        }

        //Try again after x seconds
        this._incompletedTimeout = setTimeout(() => {
            this.trySendIncompleteRequests();
        }, 2000);
    }

    /*
     * Check for internet connection
     */
    async listenConnection() {
        const appStore = useAppStore();

        const setState = (state, forceCheckOnNetworkBoot = false) => {
            appStore.setConnection(state);

            //On network change try send failed requests or do something with ajax...
            if (state) {
                this.initConnectionState(forceCheckOnNetworkBoot);
            }
        };

        //We need reset all listeners on initializing
        CapacitorNetwork.removeAllListeners();

        CapacitorNetwork.addListener('networkStatusChange', (status) => {
            setState(status.connected, true);
        });

        //Update actual network status
        let status = await CapacitorNetwork.getStatus();

        setState(status.connected);
    }

    /*
     * Refresh user account every x time
     */
    async initRefreshingApp() {
        var response,
            appStore = useAppStore();

        try {
            //We need wait till request will end.
            response = await this.tryRefreshAppData();
        } catch (e) {
            console.log(e);
        }

        //Clear closest upcoming interval
        //if refresh app data was not canceled.
        if (this.refreshInterval) {
            clearTimeout(this.refreshInterval);
        }

        var totalTimeout = this.getRefreshTimeout() * 1000;

        //Get difference between previous update
        if (appStore.lastUpdateTime) {
            let timeLeft = moment() - moment(appStore.lastUpdateTime);

            if (timeLeft > 0) {
                totalTimeout -= timeLeft;
            }

            //If last update was so long time ago. We want wait
            //few seconds, because app should initialize request, and probably has been failed.
            if (totalTimeout < 0) {
                totalTimeout = 30 * 1000;
            }
        }

        console.log(
            'remaining time to next refresh: ' +
                (totalTimeout / 1000).toFixed(1) +
                's'
        );

        this.refreshInterval = setTimeout(
            this.initRefreshingApp.bind(this),
            totalTimeout
        );
    }

    /*
     * Refresh app data
     */
    async tryRefreshAppData() {
        const appStore = useAppStore();

        //If internet is turned off. We does not need sync data.
        if (appStore.connected == false) {
            return false;
        }

        //If last update date is lower than minimum refresh timeout
        if (appStore.lastUpdateTime) {
            let lastUpdateDuration =
                (Date.now() - parseInt(appStore.lastUpdateTime)) / 1000;

            if (lastUpdateDuration < this.getRefreshTimeout()) {
                return false;
            }
        }

        this.refreshApp();
    }

    async refreshApp() {
        var data = {};

        useAppStore().refreshApp();
    }
}
