import moment from 'moment';
import _ from 'lodash';

const throwAwayMessagesFromOtherPages = (messages, store, oldestMessage) => {
    // No messages or no pagination, then don't display any additional data.
    if (!store.pagination) {
        return [];
    }

    return messages.filter((message) => {
        //Add all previous events before first message
        if (store.isPaginationEnd) {
            return true;
        }

        return oldestMessage
            ? moment(message.created_at) >= moment(oldestMessage.created_at)
            : true;
    });
};

const getEventTermineMessages = (store) => {
    if (!store.data?.event_id) {
        return [];
    }

    let event = useEventStore().getEvent(store.data.event_id);

    return (event?.termines || []).map((termine) => ({
        type: 'event_termine',
        created_at: termine.created_at,
        id: event.id + '_' + termine.id,
        termine,
    }));
};

const getJoinedUsersMessages = (store) => {
    const thread = store.thread,
        messages = [];

    _.each(thread?.attendees_data || [], (data, uid) => {
        const user = _.find(store.threadData.attendees || [], {
            id: parseInt(uid),
        });

        if (user) {
            messages.push({
                type: 'user_joined',
                created_at: moment(data.from).toISOString(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                id: 'user_' + user.id,
                user,
            });
        }
    });

    return messages;
};

export const useChatStore = defineStore('chat', {
    persist: true,

    state() {
        return {
            threadId: null,
            threads: {},
            data: {},
            loading: false,
            refresh: false,
        };
    },

    actions: {
        initialize(threadId, data) {
            threadId = threadId ? parseInt(threadId) : null;

            //Reset pagination data if opened thread id has been changed
            if (threadId != this.threadId && this.threadData) {
                this.threadData.pagination = null;
            }

            this.threadId = threadId;
            this.data = data;
            this.loading = false;
            this.refresh = true;
        },
        mergeMessages(messages, overideMessages = false) {
            if (!this.threadData) {
                return;
            }

            messages = _.castArray(messages);

            //Merge or override
            messages = overideMessages
                ? messages
                : (this.threadData.messages || []).concat(messages);

            //Sort and remove duplicates if any
            messages = _.orderBy(_.uniqBy(messages, 'id'), 'id', 'asc');

            this.threadData.messages = messages;

            let latestMessage = this.messages[this.messages.length - 1];
            if (latestMessage) {
                useEventStore().bindLastMessage(latestMessage);
            }
        },
        setThread(thread, pagination, attendees) {
            this.threadId = thread.id;

            if (!(thread.id in this.threads)) {
                this.threads[thread.id] = {
                    messages: [],
                    seen_total: 0,
                };
            }

            this.threads[thread.id].thread = thread;
            this.threads[thread.id].attendees = attendees;

            if (pagination) {
                this.threads[thread.id].pagination = pagination;
                this.threads[thread.id].seen_total = pagination.total;

                this.mergeMessages(
                    pagination.data,
                    pagination.current_page == 1
                );
            }
        },
        close() {
            if (this.threadData) {
                //Flush messages after 1 pagination
                this.threadData.messages = _.orderBy(
                    this.messages,
                    'id',
                    'desc'
                ).slice(0, this.pagination?.per_page || 24);

                //Reset pagination
                this.threadData.pagination = null;
            }

            this.threadId = null;
            this.refresh = false;
        },
    },
    getters: {
        thread(state) {
            return this.threadData?.thread;
        },
        threadData(state) {
            return this.threads[this.threadId];
        },
        messages() {
            return this.threadData?.messages || [];
        },
        pagination() {
            return this.threadData?.pagination;
        },
        messagesBuilded() {
            let messages = this.messages,
                oldestMessage = _.orderBy(this.messages, 'id', 'asc')[0],
                additionalMessages = getEventTermineMessages(this).concat(
                    getJoinedUsersMessages(this)
                );

            additionalMessages = throwAwayMessagesFromOtherPages(
                additionalMessages,
                this,
                oldestMessage
            );

            messages = messages.concat(additionalMessages);

            return messages;
        },
        messagesGroups() {
            return _.groupBy(
                _.orderBy(this.messagesBuilded, 'created_at', 'asc'),
                (row) => {
                    return moment(row.created_at).format('Y-MM-DD');
                }
            );
        },
        getData() {
            return {
                thread_id: this.threadId,
                ...this.data,
            };
        },
        isPaginationEnd() {
            if (!this.pagination) {
                return true;
            }

            return this.pagination.current_page >= this.pagination.last_page;
        },
        getThreads() {
            const eventStore = useEventStore();

            const threads = eventStore.events
                .filter((event) => eventStore.hasEventChatAccess(event))
                .filter((event) => event.type?.includes('-search') == false) //Throw away searches
                .map((event) => {
                    return {
                        id: event.last_message?.chat_thread_id,
                        event_id: event.id,
                        name: eventStore.getName(event),
                        thumbnail: eventStore.getThumbnail(event, true)
                            ?.portraitThumbnail,
                        last_message: event.last_message,
                        total: event.stats_messages,
                        date:
                            event.last_message?.created_at || event.created_at,
                        players_count: event.stats_players || 0,
                    };
                })
                .filter((thread) => {
                    // Throw away empty threads without players
                    if (thread.players_count == 0 && !thread.last_message) {
                        return false;
                    }

                    return true;
                })
                .map((thread) => {
                    // prettier-ignore
                    let seenTotalMessages = this.threads[thread.id]?.seen_total || 0;

                    // prettier-ignore
                    thread.is_unseen = thread.last_message && !thread.last_message.is_seen;
                    thread.unseen_count =
                        thread.total > seenTotalMessages
                            ? thread.total - seenTotalMessages
                            : 0;

                    return thread;
                });

            return _.orderBy(threads, 'date', 'desc');
        },
    },
});
