<template>
    <CreateEventModal />
    <EventGoingModal />
    <EventEditModal />

    <!-- Test from empty value save click -->
    <DateModal
        modal="event_date"
        v-model="form.date"
        :min="moment().format()"
        :name="__('Dátum')"
        :prefer-wheel="true"
    />

    <DateModal
        modal="event_time"
        v-model="form.time"
        presentation="time"
        :hourValues="useHourValuesForToday(form.date)"
        minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"
        :name="__('Čas')"
        :prefer-wheel="true"
    />

    <PickerModal
        modal="event-players"
        v-model="form.players"
        :options="
            _.range(2, 50)
                .map((n) => ({ id: n, name: n }))
                .concat([{ id: 0, name: __('Bez obmedzenia') }])
        "
    />

    <EventRequestsModal />
    <EventAttendanceModal />
    <EventActionsModal />
</template>

<script setup>
import _ from 'lodash';
import moment from 'moment';

const eventStore = useEventStore();

const form = computed(() => eventStore.form);
</script>
