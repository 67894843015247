<template>
    <AppModal
        :modal="modal"
        :title="isApproved ? __('Tvoja účasť') : __('Tvoja žiadosť')"
    >
        <div class="d-flex gap-1 mt-1" v-if="data">
            <FormButton
                full
                visual="secondary"
                @click="useLazyClick($event, data.callback('add'))"
            >
                {{ data.approved ? __('Zúčastnim sa') : __('Ponechať') }}
            </FormButton>
            <FormButton
                full
                @click="useLazyClick($event, data.callback('cancel'))"
            >
                {{ data.approved ? __('Neprídem') : __('Zrušiť žiadosť') }}
            </FormButton>
        </div>

        <div class="d-flex justify-content-center mt-2" v-if="isApproved">
            <a
                class="link"
                href="#"
                @click="useLazyClick($event, data.callback('cancel'))"
            >
                {{ __('Ešte som sa nerozhodol') }}
            </a>
        </div>
    </AppModal>
</template>

<script setup>
const props = defineProps({
    modal: { default: 'event-going' },
});

const data = computed(() => {
    return useGetModalData(props.modal);
});

const isApproved = computed(() => data.value?.approved == true);
</script>

<style lang="scss" scoped>
a.link {
    color: black;
    font-weight: 600;
    text-decoration: underline;
    font-size: 1.2rem;
}
</style>
