<template>
    <ion-modal
        ref="wrapper"
        :is-open="isOpen"
        @didPresent="
            emit('didPresent', $event);
            onModalOpen();
        "
    >
        <slot />
    </ion-modal>
</template>

<script setup>
const props = defineProps({
    step: {},
    authorized: {
        default: false,
    },
    isOpen: {
        default: false,
    },
});

const emit = defineEmits(['didPresent']);

const wrapper = ref();

const isUnauthorizedOpen = computed(() => {
    if (
        props.isOpen == true &&
        props.authorized &&
        useAuthStore().loggedIn == false
    ) {
        return true;
    }

    return false;
});

const isOpen = computed(() => {
    if (props.isOpen && isUnauthorizedOpen.value) {
        useCloseModal();

        nextTick(() => {
            useOpenModal('register-modal');
        });

        return false;
    }
    return props.isOpen;
});

watch(isOpen, (state) => {
    //When is full modal, remove default modal styling
    //to fix sub-modal backdrop.
    //(Attendance acceptance has sub modal about confirming)
    if (state && wrapper.value.$el.classList.contains('full-modal')) {
        wrapper.value.$el.classList.remove('modal-default');
    }
});

watch(
    () => props.step,
    (step, prevStep) => {
        //Select only on forward...
        if (step > prevStep) {
            useAutoFocusInput(wrapper.value);
        }
    }
);

const onModalOpen = () => {
    useAutoFocusInput(wrapper.value, 0);
};
</script>
