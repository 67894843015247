<template>
    <EventStep :errors="getValidationError" v-if="location">
        <div class="form-custom__wrapper --dark">
            <ion-icon :icon="locationOutline" />
            <span class="text_wrapper">{{ location.name }}</span>
            <span @click="eventStore.currentStep--" class="end">
                {{ __('Zmeniť') }}
            </span>
        </div>

        <div class="info mt-2">
            <div class="info__icon">
                <ion-icon :icon="alertOutline" />
            </div>
            {{ __('Športovisko môže byť potrebné rezervovať') }}
        </div>

        <div class="contact-box mt-2" v-if="location.phone">
            <div class="contact-box__images">
                <div class="contact-box__image">
                    <img :src="location.thumbnail || '/images/marker.png'" />
                </div>
                <div class="contact-box__icon">
                    <ion-icon :icon="callOutline" />
                </div>
            </div>

            <div class="contact-box__info">
                <a :href="'tel:' + location.phone" target="_blank">
                    {{ location.phone }}
                </a>
                <span>{{ __('Kontakt pre rezerváciu') }}</span>
            </div>
        </div>

        <FormCheckbox v-model="eventStore.form.accepted" class="mt-2">
            <!-- prettier-ignore -->
            {{ __('Mám rezervovaný termín / rezervácia nie je potrebná.') }}
        </FormCheckbox>
    </EventStep>
</template>

<script setup>
import { locationOutline, alertOutline, callOutline } from 'ionicons/icons';

const eventStore = useEventStore();

if (eventStore.isCustomLocation) {
    eventStore.currentStep--;
}

const location = computed(() => eventStore.location);

const getValidationError = computed(() => {
    if (!eventStore.form.accepted) {
        return __('Potvrďte súhlas ohľadom rezervácie športoviska.');
    }

    return false;
});
</script>

<style lang="scss" scoped>
.info {
    background: rgba(#d8eff1, 0.8);
    border-radius: 2rem;
    padding: 0.3rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.2rem;
    color: #001f27;
}

.info__icon {
    width: 2.4rem;
    height: 2.4rem;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ion-icon {
        color: #000;
        font-size: 1.4rem;
    }
}

.contact-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-box__images {
    display: flex;
    align-items: center;
}

.contact-box__image {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #fff;
    position: relative;
    z-index: 2;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: white;
    }
}

.contact-box__icon {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: #020311;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -0.6rem;

    ion-icon {
        font-size: 2rem;
        color: white;
    }
}

.contact-box__info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #020310;
    text-align: right;

    a {
        color: #020310;
        text-decoration: underline;
        font-weight: 400;
        font-size: 1.8rem;
        letter-spacing: -0.026rem;
        line-height: 3rem;
    }

    span {
        font-weight: 600;
        font-size: 0.9rem;
        color: #000000;
    }
}
</style>
