<template>
    <ModalWrapper
        :class="{ 'custom-modal': !full, 'full-modal': full }"
        :isOpen="isOpen || useIsModalOpen(modal)"
        @didDismiss="
            useCloseModal(modal);
            emit('close', false);
        "
    >
        <component :is="full ? 'ion-header' : 'div'">
            <ion-toolbar>
                <ion-buttons slot="start" v-if="full">
                    <ion-button
                        @click="
                            useCloseModal(modal);
                            emit('close', false);
                        "
                        class="full-modal__close"
                    >
                        <ion-icon :icon="arrowBack"></ion-icon>
                    </ion-button>
                </ion-buttons>

                <ion-title>
                    <slot name="title">{{ title }}</slot>
                </ion-title>

                <ion-buttons slot="end" v-if="!full || canClose">
                    <slot name="close">
                        <ion-button
                            @click="
                                useCloseModal(modal);
                                emit('close', false);
                            "
                            class="custom-modal__close"
                        >
                            <ion-icon :icon="closeOutline"></ion-icon>
                        </ion-button>
                    </slot>
                </ion-buttons>
            </ion-toolbar>
        </component>

        <ion-content class="ion-padding" v-if="full">
            <div class="full-modal__content">
                <slot
                    :callback="useGetModal(modal)?.callback"
                    :data="useGetModal(modal)?.callback"
                />
            </div>
        </ion-content>

        <div v-else class="custom-modal__container">
            <slot
                :callback="useGetModal(modal)?.callback"
                :data="useGetModal(modal)?.callback"
            />
        </div>
    </ModalWrapper>
</template>

<script setup>
import { closeOutline, arrowBack } from 'ionicons/icons';

defineProps({
    modal: {},
    isOpen: {
        default: false,
    },
    title: String,
    canClose: {
        default: false,
    },
    full: {
        default: false,
    },
});

const emit = defineEmits(['close']);
</script>

<style lang="scss" scoped>
ion-modal.custom-modal {
    --width: 90%;
    --height: fit-content;
    --border-radius: 2.4rem;
    --box-shadow: none;

    @include more-than(md) {
        --width: 40rem;
    }

    &::part(backdrop) {
        background: #020310;
        opacity: 0.2;
    }

    ion-toolbar {
        --background: #fff;
        padding: 2rem 2rem 1rem;

        ion-title {
            padding: 0;
            font-weight: 600;
            font-size: 2rem;
            color: #020310;
            letter-spacing: -0.029rem;
            line-height: 2.6rem;
            text-align: left;
        }
    }
}

ion-modal {
    @include more-than(md) {
        --width: 40rem;
    }
}

.custom-modal__close {
    border: 1px solid #dddddd;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    margin: 0 !important;
    padding: 0 !important;

    ion-icon {
        color: #000;
    }
}

.custom-modal__container {
    padding: 0 2rem 3rem;
    color: #000;
    background: #fff;
}
</style>
