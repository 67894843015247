<template>
    <aside class="desktop-menu">
        <ul class="desktop-menu__list">
            <li>
                <ion-nav-link
                    router-link="/app/feed"
                    router-direction="forward"
                    class="desktop-menu__link"
                >
                    <span class="desktop-menu__icon --feed"></span>
                    {{ __('Dashboard partičiek') }}
                </ion-nav-link>
            </li>
            <!-- <li>
                <ion-nav-link
                    router-link="/app/search"
                    router-direction="forward"
                    class="desktop-menu__link"
                >
                    <span class="desktop-menu__icon --loupe"></span>
                    Vyhľadávanie
                </ion-nav-link>
            </li> -->
            <li v-if="authStore.loggedIn">
                <ion-nav-link
                    router-link="/app/manager/listing"
                    router-direction="forward"
                    class="desktop-menu__link"
                >
                    <span class="desktop-menu__icon --game"></span>
                    {{ __('Moje hry') }}
                </ion-nav-link>
            </li>
            <li v-if="authStore.loggedIn">
                <ion-nav-link
                    router-link="/app/notifications"
                    router-direction="forward"
                    class="desktop-menu__link"
                >
                    <span class="desktop-menu__icon --notifications"></span>
                    {{ __('Notifikácie') }}
                </ion-nav-link>
            </li>
            <li v-if="authStore.loggedIn">
                <ion-nav-link
                    router-link="/app/profile/account"
                    router-direction="forward"
                    class="desktop-menu__link"
                >
                    <span class="desktop-menu__icon --profile"></span>
                    {{ __('Môj profil') }}
                </ion-nav-link>
            </li>
            <li v-if="!authStore.loggedIn">
                <ion-nav-link
                    class="desktop-menu__link"
                    @click="useOpenModal('login-modal')"
                >
                    <span class="desktop-menu__icon --profile"></span>
                    {{ __('Prihlásenie') }}
                </ion-nav-link>
            </li>

            <li v-if="!authStore.loggedIn">
                <ion-nav-link
                    class="desktop-menu__link"
                    @click="useOpenModal('register-modal')"
                >
                    <span class="desktop-menu__icon --game"></span>
                    {{ __('Registrácia') }}
                </ion-nav-link>
            </li>

            <li>
                <ion-nav-link
                    class="desktop-menu__link"
                    @click="useOpenModal('inquiry')"
                >
                    <span class="desktop-menu__icon --message"></span>
                    {{ __('Kontaktujte nás') }}
                </ion-nav-link>
            </li>
        </ul>
    </aside>
</template>

<script setup>
const authStore = useAuthStore();
</script>

<style lang="scss" scoped>
.desktop-menu {
    position: fixed;
    top: 8rem;
    bottom: 0;
    left: 0;
    width: calc((100vw - 70rem) / 2);
    border-right: 1px solid var(--desktop-border-color);
    z-index: 100;
    display: none;
    padding: 5rem;

    @include more-than(md) {
        display: block;

        body:has(.--auth-layout) & {
            display: none !important;
        }
    }
}

.desktop-menu__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 0;
    margin: 0;
}

.desktop-menu__link {
    font-weight: 600;
    font-size: 12px;
    color: var(--ion-text-color);
    line-height: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.desktop-menu__icon {
    width: 2.4rem;
    height: 2.4rem;
    min-width: 2.4rem;
    background: #5e777d;
    user-select: none;

    &.--feed {
        background: url($basePath + 'icons/tab-feed.svg') no-repeat center;
        background-size: 100% auto;
    }

    &.--loupe {
        mask: url($basePath + 'icons/tab-loupe.svg') no-repeat center;
        mask-size: 100% auto;
    }

    &.--game {
        mask: url($basePath + 'icons/tab-game.svg') no-repeat center;
        mask-size: 100% auto;
    }

    &.--notifications {
        mask: url($basePath + 'icons/tab-notifications.svg') no-repeat center;
        mask-size: 100% auto;
    }

    &.--message {
        mask: url($basePath + 'icons/tab-message.svg') no-repeat center;
        mask-size: 100% auto;
    }

    &.--profile {
        border-radius: 50%;
        background: url($basePath + 'placeholders/user.png') no-repeat center;
        background-size: cover;
        border: 1px solid #80cad2;
    }
}
</style>
