<template>
    <div class="form-group" :class="$attrs.class">
        <ion-input
            v-bind="{ ...$attrs, class: '' }"
            ref="el"
            :label-placement="
                $attrs.label ? $attrs.labelPlacement || 'floating' : null
            "
            v-model="model"
            :type="type === 'password' ? switcherType : type"
            :class="{ '--rounded': rounded, '--code': code, '--light': light }"
            class="form-text"
            @keydown.enter="useEnterFieldEvent"
        >
            <slot />

            <ion-icon
                v-if="searchIcon"
                slot="start"
                :icon="search"
                aria-hidden="true"
                size="large"
            ></ion-icon>

            <ion-button
                fill="clear"
                slot="end"
                aria-label="Show/hide"
                class="form-text__eye"
                v-if="type === 'password'"
                @click.prevent="switchInputType()"
                tabindex="-1"
            >
                <ion-icon
                    slot="icon-only"
                    :icon="switcherType === 'password' ? eye : eyeOff"
                ></ion-icon>
            </ion-button>
        </ion-input>
    </div>
</template>

<script setup>
defineOptions({
    inheritAttrs: false,
});

import { eye, eyeOff, search } from 'ionicons/icons';

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    rounded: {
        type: Boolean,
        default: false,
    },
    light: {
        type: Boolean,
        default: false,
    },
    code: {
        type: Boolean,
        default: false,
    },
    searchIcon: {
        type: Boolean,
        default: false,
    },
    modelValue: {},
});

const el = ref();

const switcherType = ref('password');

const switchInputType = () => {
    switcherType.value =
        switcherType.value === 'password' ? 'text' : 'password';
};

const model = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});

const emit = defineEmits(['update:modelValue']);

defineExpose({
    $input: el,
});
</script>

<style lang="scss" scoped>
.form-group {
    margin-bottom: 1.2rem;

    &:last-child {
        margin-bottom: 0;
    }
}

ion-input.form-text {
    min-height: auto;

    &:deep(.input-wrapper) {
        border: 1px solid #dddddd;
        border-radius: 0.5rem;
        font-size: 1.4rem;
        transition: all 0.2s;
        color: #000;
        height: 4.6rem;
        min-height: 4.6rem;
        --padding-start: 1.2rem;
        --padding-end: 1.2rem;
        --placeholder-color: rgba(0, 0, 0, 0.5);
        --placeholder-opacity: 1;
        font-family: var(--ion-font-family);
    }

    :deep(ion-icon[slot='start']) {
        margin-inline-end: 1rem;
    }

    &.has-focus:deep(.input-wrapper) {
        border-color: #000000;
    }

    &.ion-invalid {
        :deep(.input-wrapper) {
            border-color: var(--ion-color-danger);
        }

        &.label-floating.has-value {
            :deep(.input-wrapper) {
                .label-text {
                    color: var(--ion-color-danger);
                }
            }
        }
    }

    &.--rounded:deep(.input-wrapper) {
        border-radius: 4rem;
        --padding-start: 2rem;
        --padding-end: 1rem;
    }

    &.--light:deep(.input-wrapper) {
        color: #fff;
        --placeholder-color: rgba(255, 255, 255, 0.5);

        &.has-focus {
            border-color: #fff;
        }
    }

    &.--code:deep(.input-wrapper) {
        border-radius: 0;
        --padding-start: 0;
        --padding-end: 0;
        height: 6rem;
        border: 0;
        border-bottom: 2px solid #dddddd;
        font-size: 3.4rem;
        text-align: center;
    }

    &.input-label-placement-floating :deep(.label-text-wrapper) {
        position: absolute;
        color: rgba(0, 0, 0, 0.5);
        min-height: 100%;
        height: 100%;
        transform: none;
    }

    &.label-floating.has-value :deep(.label-text-wrapper) {
        transform: translateY(-1.7rem) scale(0.75);

        .label-text {
            background: white;
            padding: 0 0.5rem;
            margin-left: -0.5rem;
            color: #000000;
        }
    }
}

.form-text__eye {
    color: #565656;
    transition: all 0.2s;

    &:hover {
        opacity: 1;
        color: var(--ion-color-primary);
    }
}
</style>

<style lang="scss">
.input-wrapper {
    max-height: 100%;
}
</style>
