import _ from 'lodash';

import { PushNotifications } from '@capacitor/push-notifications';

export const notificationsSupported = () => {
    return useIsDesktop() == false;
};

const showNotificationsErrorToast = () => {
    // prettier-ignore
    useShowErrorToast({
        message: __('Notifikácie neboli povolené. Skontrolujte povolenia v nastaveniach.'),
        duration : 5000,
    })
};

export const useIsNotificationsEnabled = async () => {
    if (notificationsSupported() === false) {
        return false;
    }

    try {
        let permStatus = await PushNotifications.checkPermissions();

        return ['granted'].includes(permStatus.receive);
    } catch (e) {
        console.error(e);

        return false;
    }
};

// Refresh latest notifications, but only once per minute, to not overload the server.
const refreshLatestNotifications = _.throttle(() => {
    useAppStore().refreshApp(['notification']);
}, 60 * 1000);

const displayNotificationToast = async (notification) => {
    const title = notification?.title;

    useShowToast({
        message: title
            ? title
            : __('Nové hlásenie, skontrolujte prosím notifikácie.'),
        duration: 5000,
        click() {
            let data = notification?.data || {};

            logEvent('notification_inapp_click', { type: data?.type });

            if (useTryGoToNotificationPage(data)) {
                return;
            }

            useRouter().push({ name: 'app-notifications' });
        },
    });
};

const displayMatchPendingModal = (notification) => {
    useOpenModal('match-partner-found', {
        id: notification.data?.id,
        data: notification.data,
    });
};

const onNotificationReceived = (notification) => {
    useVibrate();

    console.log('Push notification received: ', notification);

    // Don't allow to go anywhere if user is not logged in
    if (useAuthStore().loggedIn === false) {
        return false;
    }

    const type = notification?.data?.type;

    logEvent('notification_inapp_receive', { type });

    if (['event-match-pending', 'event-match-accepted'].includes(type)) {
        displayMatchPendingModal(notification);
    } else {
        displayNotificationToast(notification);
    }
};
export const useRegisterNotifications = async (showForceModal = false) => {
    if (!useAuthStore().loggedIn || !notificationsSupported()) {
        return;
    }

    console.log('Notification register:');

    const modalOpen = async () => {
        return await new Promise((resolve, reject) => {
            useOpenModal('allow-notifications', {
                callback: async () => {
                    let status = await PushNotifications.requestPermissions();

                    if (showForceModal) {
                        if (status?.receive == 'granted') {
                            resolve(status);
                        } else {
                            showNotificationsErrorToast();
                        }
                    } else {
                        resolve(status);
                    }
                },
            });
        });
    };

    try {
        let permStatus = await PushNotifications.checkPermissions();

        if (['prompt', 'prompt-with-rationale'].includes(permStatus.receive)) {
            permStatus = await modalOpen();
        }

        if (permStatus?.receive == 'granted') {
            useCloseModal('allow-notifications');

            return await PushNotifications.register();
        } else if (permStatus?.receive == 'denied') {
            if (showForceModal === true) {
                permStatus = await modalOpen();
            } else {
                showNotificationsErrorToast();
            }

            throw new Error('User denied permissions!');
        }
    } catch (e) {
        console.error(e);
    }
};

export const useUnregisterNotifications = async () => {
    if (!notificationsSupported()) {
        return;
    }

    try {
        let response = await PushNotifications.unregister();

        console.log('Notifications unregistered:', response);
    } catch (e) {
        console.error(e);
    }
};

const canDisplayNotification = (notification) => {
    // Don't allow to go anywhere if user is not logged in
    if (useAuthStore().loggedIn === false) {
        return false;
    }

    // Don't show app notifications in opened chat which received notification.
    if (
        ['message-event'].includes(notification?.data?.type) &&
        useRoute()?.name == 'app-chat' &&
        (notification?.data?.eid == useRoute().query?.event_id ||
            notification?.data?.ctid == useRoute().query?.thread_id)
    ) {
        return false;
    }

    return true;
};

export const useAddNotificationListeners = async () => {
    if (!notificationsSupported()) {
        return;
    }

    console.log('Notifications listeners registered.');

    await PushNotifications.addListener('registration', (token) => {
        console.log('Notification token:', token?.value);

        useAuthStore().setDeviceId(token?.value);
    });

    await PushNotifications.addListener('registrationError', (err) => {
        console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
            // Check if notification can be displayed
            if (canDisplayNotification(notification) === false) {
                return;
            }

            onNotificationReceived(notification);

            // Refresh latest notifications
            refreshLatestNotifications();
        }
    );

    await PushNotifications.addListener(
        'pushNotificationActionPerformed',
        async (notification) => {
            const user = useAuthStore().user;

            //Wait till vue app boots, if is not yet.
            while (window.$appReady !== true) {
                await useSleep(250);
            }

            if (!user) {
                return;
            }

            let data = notification?.notification?.data || {};

            //Log event when user clicks on notification
            logEvent('notification_cold_click', { type: data?.type });

            if (useTryGoToNotificationPage(data)) {
                return;
            }

            //Redirect to notifications page
            useRouter().push({ name: 'app-notifications' });
        }
    );
};

const tryGoToEventPage = (data) => {
    let eventId = parseInt(data?.eid),
        termineId = parseInt(data?.tid);

    //If event has been found from notification data
    //Redirect to given event.
    if (!eventId) {
        return;
    }

    const eventStore = useEventStore(),
        notificationStore = useNotificationStore();

    let localEvent = eventStore.getEvent(eventId),
        notificationEvent = notificationStore.getEvent(eventId),
        event = localEvent || notificationEvent,
        termine = termineId
            ? eventStore.getTermine({
                  id: termineId,
                  event_id: eventId,
              })
            : null;

    // No event has been found in local storage
    if (!event) {
        return;
    }

    pushStateRoute(
        {
            name: 'app-event-slug',
            params: { slug: event.slug },
            query: {
                t: termine?.id,
                n: data?.type,
            },
        },
        {
            //Pass only when termines are available
            event: localEvent,
        }
    );

    return true;
};

const tryGoToChatPage = (data) => {
    let eventId = parseInt(data?.eid),
        threadId = parseInt(data?.ctid);

    if (threadId) {
        useRouter().push({
            name: 'app-chat',
            query: {
                thread_id: threadId,
                event_id: eventId || null,
            },
        });

        return true;
    }
};

const tryGoToMatchPage = (data) => {
    let eventId = parseInt(data?.eid),
        termineId = parseInt(data?.tid),
        eventType = data?.etp;

    if (['event-match-pending'].includes(data.type) === false) {
        return;
    }

    // If event is not matched from both sides, go to matcher screen
    // Otherwise go to event screen
    if (eventType == 'event-search') {
        const event = useEventStore().getEvent(eventId);

        if ((event?.type || '').includes('-search')) {
            useRouter().push({
                name: 'app-matches',
                query: {
                    eid: eventId,
                    tid: termineId,
                },
            });

            return true;
        }

        // No event has been found
        else if (!event) {
            useShowErrorToast(__('Toto aktívne vyhľadávanie už vypršalo.'));

            return true;
        } else {
            //.. fallback will be triggered and user will be redirect to the event.
        }
    }
};

export const useTryGoToNotificationPage = (data) => {
    // Don't allow to go anywhere if user is not logged in
    if (useAuthStore().loggedIn === false) {
        return true;
    }

    if (tryGoToMatchPage(data)) {
        return true;
    }

    if (tryGoToChatPage(data)) {
        return true;
    }

    if (tryGoToEventPage(data)) {
        return true;
    }
};

export const useCheckMatcherNotifications = () => {
    if (useAuthStore().loggedIn == false) {
        return;
    }

    const notificationStore = useNotificationStore();

    const pending = _.filter(
        notificationStore.notifications,
        (n) =>
            ['event-match-pending', 'event-match-accepted'].includes(n.type) &&
            notificationStore.isRead(n) === false
    );

    // When there is a pending match notification, open the modal
    if (pending.length > 0) {
        useOpenModal('match-partner-found', {
            id: pending[0].id,
            data: pending[0].data,
        });
    }
};
