<template>
    <ModalWrapper :is-open="useIsModalOpen(modal)" class="full-modal">
        <ion-header>
            <ion-toolbar>
                <ion-title>
                    {{ name || __('Vyberte') }}
                </ion-title>
                <ion-buttons slot="start">
                    <ion-button
                        @click="useCloseModal()"
                        class="full-modal__close"
                    >
                        <ion-icon :icon="arrowBack"></ion-icon>
                    </ion-button>
                </ion-buttons>

                <ion-buttons slot="end">
                    <ion-button
                        v-if="canClear"
                        @click="
                            resetValue();
                            useCloseModal();
                        "
                        class="--cancel"
                    >
                        {{ __('Zrušiť') }}
                    </ion-button>

                    <ion-button
                        v-else-if="!autoSelect"
                        class="--save"
                        @click="useCloseModal()"
                    >
                        {{ __('Vybrať') }}
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <div class="full-modal__content" :class="{ 'pt-0': !hasSearch }">
                <FormText
                    v-if="hasSearch"
                    v-model="query"
                    :placeholder="__('Vyhladať')"
                    rounded
                    searchIcon
                    :clearInput="true"
                    :autofocus="autoFocus"
                    class="mb-2"
                />

                <slot name="filter"></slot>

                <div
                    class="results"
                    :class="{ '--lines': !optionsFiltrated[0]?.icon }"
                >
                    <label
                        class="result"
                        v-for="option in optionsFiltrated"
                        :key="option[valueProp]"
                    >
                        <input
                            type="radio"
                            class="result__input"
                            :key="option[valueProp]"
                            :checked="isChecked(option)"
                            @click="toggle(option)"
                        />

                        <div class="result__content">
                            <div class="result__icon" v-if="option.icon">
                                <img :src="option.icon" alt="" />
                            </div>

                            {{ option.name }}
                        </div>
                    </label>
                </div>
            </div>
        </ion-content>
    </ModalWrapper>
</template>

<script setup>
import { arrowBack } from 'ionicons/icons';
import _ from 'lodash';
import Fuse from 'fuse.js';

const props = defineProps({
    modal: {
        default: 'form-choose',
    },
    isOpen: {
        type: Boolean,
        default: false,
    },
    name: {},
    multiple: {
        default: false,
    },
    canClear: {
        default: false,
    },
    autoSelect: {
        default: true,
    },
    options: {
        default: [],
    },
    valueProp: {
        default: 'id',
    },
    autoFocus: {
        default: true,
    },
    searchFromItemsCount: {
        default: 10,
    },
});

const query = ref();
const value = defineModel();
const options = ref([]);

useOnModalChange(props.modal, () => {
    query.value = null;
});

useOnModalOpen(props.modal, () => setOptionsBySelectedSort());

const hasSearch = computed(() => {
    return options.value.length >= props.searchFromItemsCount;
});

const optionsFiltrated = computed(() => {
    if (query.value) {
        let list = new Fuse(options.value, {
            keys: ['name'],
            ...useFuseOptions(),
        });

        return list.search(query.value).map((item) => item.item);
    }

    return options.value;
});

const isSingle = computed(() => props.multiple == false);
const isArrayValue = computed(() => _.isArray(value.value));

const isChecked = (item) => {
    let iv = item[props.valueProp],
        isEmpty = _.isNil(value.value) || _.castArray(value.value).length === 0;

    if (isSingle.value) {
        return value.value === iv || (_.isNil(iv) && isEmpty);
    } else {
        let val = _.castArray(value.value);

        return val?.includes(iv) || (_.isNil(iv) && isEmpty);
    }
};

const resetValue = () => {
    if (isSingle.value) {
        value.value = null;
    } else {
        value.value = [];
    }
};

const toggle = (item) => {
    let id = item[props.valueProp],
        newValue,
        reset = _.isNil(id);

    if (isSingle.value) {
        newValue = reset || value.value == id ? null : id;
    } else {
        newValue = reset ? [] : Object.values(_.xor(value.value || [], [id]));
    }

    value.value = newValue;

    if (props.autoSelect == true || reset) {
        useCloseModal();
    }
};

const setOptionsBySelectedSort = () => {
    let _options = props.options;

    //Sort by checked if is enabled search
    if (hasSearch.value) {
        _options = _.orderBy(
            _options,
            (option) => {
                if (_.isNil(option[props.valueProp])) {
                    return 2;
                }

                return isChecked(option) ? 1 : 0;
            },
            'desc'
        );
    }

    options.value = _options;
};

watch(
    () => props.options,
    () => {
        setOptionsBySelectedSort();
    },
    { immediate: true }
);
</script>

<style lang="scss" scoped>
.results {
    display: flex;
    flex-direction: column;

    &.--lines {
        .result {
            border-bottom: 1px solid #e0e0e0;
        }
    }
}

.result {
    position: relative;
    min-height: 5rem;
    display: flex;
    align-items: center;
}

.result__content {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 400;
    font-size: 1.3rem;
    color: #011e26;
    transition: all 0.2s;
}

.result__input {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;

    &:checked ~ .result__content {
        font-weight: 600;

        .result__icon {
            opacity: 1;
            background: #d8eff1;
            border-color: #011e26;
        }
    }
}

.result__icon {
    border: 1px solid #dddddd;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    transition: all 0.2s;

    img {
        max-width: 60%;
        max-height: 60%;
    }
}
</style>
