<template>
    <div class="wrapper">
        <div
            class="countdown-circle"
            :style="{ width: size + 'px', height: size + 'px' }"
        >
            <svg
                :width="size"
                :height="size"
                :style="'transform: rotate(-90deg);'"
            >
                <circle
                    :cx="radius"
                    :cy="radius"
                    :r="radius - strokeWidth / 2"
                    :stroke-width="strokeWidth"
                    fill="none"
                    class="bg"
                />

                <!-- Countdown circle -->
                <circle
                    :cx="radius"
                    :cy="radius"
                    :r="radius - strokeWidth / 2"
                    :stroke-width="strokeWidth"
                    fill="none"
                    :stroke-dasharray="dashArray"
                    :stroke-dashoffset="
                        (dashArray / membersMutated) *
                        (membersMutated - current)
                    "
                    class="progress"
                />
            </svg>

            <span>{{ current }}/{{ members || '∞' }}</span>
        </div>
    </div>
</template>

<script setup>
import _ from 'lodash';
const props = defineProps({
    size: {
        type: Number,
        default: 28,
    },
    strokeWidth: {
        type: Number,
        default: 3,
    },
    members: {
        default: null,
    },
    current: {
        default: 0,
    },
});

const radius = ref(props.size / 2);
const dashArray = computed(
    () => Math.PI * (radius.value - props.strokeWidth / 2) * 2
);

const membersMutated = computed(() => {
    // For non max players, make max. as 100%
    return !props.members ? props.current * 2 : props.members;
});
</script>

<style scoped lang="scss">
.wrapper {
    padding: 0.3rem;
    background: #fff;
    border-radius: 50%;
}
.countdown-circle {
    position: relative;
    display: flex;

    span {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0px 0 1px;
        font-weight: 700;
        font-size: 0.9rem;
        color: #000000;
        text-align: center;
    }
}
.bg {
    stroke: #80cad2;
}
.progress {
    stroke: #020310;
}
</style>
