<template>
    <CheckboxModal
        :modal="modal"
        :name="__('Vyberte úroveň zručnosti')"
        :title="sport?.name"
        type="radio"
        valueProp="level"
        :autoSelect="true"
        :options="sportStore.levels"
        :modelValue="data?.level"
        @input="onLevelChange"
        labelPlacement="start"
        :key="sportId"
        :full="true"
    >
        <template v-slot:option="{ option, value, change }">
            <div class="level-option">
                <div class="title">
                    {{ option.name }}

                    <SportLevelBadge
                        class="sport__badge"
                        :level="option.level"
                        color="dark"
                    />
                </div>

                <small class="description">{{ option.title }}</small>
            </div>
        </template>
    </CheckboxModal>
</template>

<script setup>
const props = defineProps({
    modal: {
        default: 'sport-level',
    },
});

const sportStore = useSportStore();

const data = computed(() => useGetModalData(props.modal));

const sport = computed(() => data.value?.sport);
const sportId = computed(() => sport.value?.id || 0);

const onLevelChange = async (event) => {
    if (data.value.callback) {
        data.value.callback(event);
    }
};
</script>

<style lang="scss" scoped>
:deep(.--radio) {
    border: 1px solid #dddddd;
    padding: 0.6rem 1.5rem 0.6rem 0.5rem;
    border-radius: 0.6rem;
}

:deep(ion-checkbox) {
    small.description {
        color: #021e26;
        opacity: 0.5;
    }
}
</style>
