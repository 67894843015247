<template>
    <div class="map-container" :class="{ '--hasTitles': hasTitles }" ref="map">
        <slot />
    </div>
</template>

<script setup>
const props = defineProps({
    markerKey: {
        default: 'id',
    },
    config: {},
    currentPosition: { default: false },
    titlesFromZoom: { default: 15 },
});
import _ from 'lodash';

import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import olms from 'ol-mapbox-style';
import Overlay from 'ol/Overlay';

const map = ref();
const mapInstance = ref();
const markers = ref([]);

const locationStore = useLocationStore();
const settingStore = useSettingStore();

const defaultPositionZoom = useBackendEnv('MAP_DEFAULT_LOCATED_ZOOM');
const moving = ref(false);
const zoom = ref();
const manualZoomTo = ref();

const createMap = async (config) => {
    mapInstance.value = new Map({
        target: map.value,
        layers:
            config.provider == 'openmapstreet'
                ? [
                      new TileLayer({
                          source: new OSM(),
                      }),
                  ]
                : [],
        view: new View({
            center: fromLonLat(config.center),
            zoom: config.zoom,
        }),
    });

    if (config.provider == 'maptiler') {
        await olms(mapInstance.value, config.url);
    }

    setZoomListener();
};

const setZoomListener = () => {
    zoom.value = mapInstance.value.getView().getZoom();

    mapInstance.value.on('movestart', function (e) {
        moving.value = true;
    });

    mapInstance.value.on('moveend', function (e) {
        moving.value = false;
        zoom.value = mapInstance.value.getView().getZoom();
    });
};

const getMarkerElement = (marker, point) => {
    var e = document.createElement('div'),
        image = point.image || '/images/marker.png';

    // prettier-ignore
    e.className = [
        'marker',
        point.class,
        (!point.image ? '--noImage' : ''),
    ].filter(i => i).join(' ');

    e.innerHTML =
        (image ? `<img src="${image}">` : '') + `<span>${point.name}</span>`;

    e.addEventListener('click', (e) => {
        //Disable click during moving a map
        if (moving.value) {
            return;
        }

        marker.setElement(
            getMarkerElement(marker, { ...marker.point, active: true })
        );

        if (point.click) {
            point.click(marker);
        }
    });

    //Save object type into marker
    marker.point = point;

    if (point.active) {
        //Remove previously active marker state
        removeActiveMarkers(point);
    }

    marker.element.classList[point.active ? 'add' : 'remove']('--active');

    return e;
};

const createMarker = (point) => {
    //Skip map alerts
    if (!point.lat || !point.lng) {
        return;
    }

    //Remove markers with same id
    markers.value.forEach((m) => {
        if (
            m.point[props.markerKey] &&
            m.point[props.markerKey] == point[props.markerKey]
        ) {
            removeMarker(m);
        }
    });

    const position = fromLonLat([parseFloat(point.lng), parseFloat(point.lat)]);

    var marker = new Overlay({
        position,
        positioning: 'center-center',
        stopEvent: false,
    });

    marker.setElement(getMarkerElement(marker, point));

    mapInstance.value.addOverlay(marker);

    markers.value.push(marker);
};

const zoomToMarker = (point, zoom = 16) => {
    const position = fromLonLat([parseFloat(point.lng), parseFloat(point.lat)]);

    mapInstance.value.updateSize();
    mapInstance.value.getView().setCenter(position);
    mapInstance.value.getView().setZoom(zoom);

    manualZoomTo.value = { position, zoom };
};

const removeActiveMarkers = (point) => {
    markers.value.forEach((marker) => {
        if (!marker.point.active || (point && _.isEqual(marker.point, point))) {
            return;
        }

        marker.setElement(
            getMarkerElement(marker, { ...marker.point, active: false })
        );
    });
};

const removeMarker = (marker) => {
    marker.setElement(null);

    markers.value.forEach((m, i) => {
        if (!m.getElement()) {
            markers.value.splice(i, 1);
        }
    });
};

const hasTitles = computed(() => zoom.value >= props.titlesFromZoom);

const mapConfig = computed(() => {
    const config = _.cloneDeep(settingStore.map || {});

    //If curent position is set already, boot map actual position already
    const p = locationStore.position;
    if (props.currentPosition && p) {
        config.center = [p.lng, p.lat];
        config.zoom = defaultPositionZoom;
    }

    return config;
});

onMounted(async () => {
    await createMap({ ...mapConfig.value, ...props.config });

    //Set current position listener
    if (props.currentPosition) {
        //Set current position
        watch(
            () => locationStore.position,
            (position) => {
                //If manual marker has not been set yet, because we would override setted position after longer delay
                if (position && !manualZoomTo.value) {
                    zoomToMarker(position, defaultPositionZoom);
                }
            }
        );
    }
});

onBeforeUnmount(() => {});

defineExpose({
    mapInstance,
    removeActiveMarkers,
    createMarker,
    removeMarker,
    zoomToMarker,
});
</script>

<style scoped lang="scss">
.map-container {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    :deep(.ol-control) {
        button {
            width: 3rem;
            height: 3rem;
        }
    }

    :deep(.ol-zoom) {
        top: auto;
        left: auto;
        bottom: 4rem;
        right: 2rem;
    }

    :deep(.ol-overlay-container) {
        &.--active {
            z-index: 2;

            .marker.--location {
                &:not(.--noImage) img {
                    border-color: var(--ion-color-primary);
                    border-width: 4px;
                }

                img {
                    max-width: 5rem;
                    max-height: 5rem;
                }
            }
        }

        .marker {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            text-align: center;
            font-weight: 600;
            font-size: 1.1rem;
            cursor: pointer;
            user-select: none;

            &.--location {
                img {
                    border: 2px solid white;
                    border-radius: 100%;
                    overflow: hidden;
                    box-shadow: 0 3px 6px #00000008;
                }
            }

            img {
                flex-shrink: 0;
                max-width: 3rem;
                max-height: 3rem;
            }

            span {
                color: black;
                position: absolute;
                top: calc(100% + 1rem);
                width: 15rem;
                display: none;
                pointer-events: none;
            }
        }
    }

    &.--hasTitles :deep(.marker span) {
        display: block;
    }
}
</style>
