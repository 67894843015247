import _ from 'lodash';
import moment from 'moment';

export const useNotificationStore = defineStore('notification', {
    persist: true,

    state() {
        return {
            notifications: [],
            clickedIds: [],

            // Relation table to join data into notifications
            events: [],
            users: [],
        };
    },

    actions: {
        setClicked(notification) {
            let key = this.notificationKey(notification);

            // Save last 30 clicked ids
            this.clickedIds = _.uniq(this.clickedIds.slice(-30).concat(key));
        },
        setEvents(events) {
            this.events = _.uniqBy(events.concat(this.events), 'id').slice(0, 100);
        },
        setUsers(users) {
            this.users = _.uniqBy(users.concat(this.users), 'id').slice(0, 100);
        },
    },

    getters: {
        notificationKey() {
            return (notification) => {
                return notification.id + '_' + notification.created_at;
            };
        },
        isRead:
            (state) =>
            (notification, delay = false) => {
                // Notification has been clicked
                // prettier-ignore
                if ( state.clickedIds.includes(state.notificationKey(notification)) ) {
                    return true;
                }

                if (!notification.read_at) {
                    return false;
                }

                //Set delay to minutage
                const delayMinutage = delay
                    ? parseInt(useBackendEnv('UNREAD_NOTIFICATIONS_MINUTAGE'))
                    : false;

                const readMinuageAgo = moment().diff(
                    moment(notification.read_at),
                    'minutes'
                );

                if (delayMinutage) {
                    return readMinuageAgo > delayMinutage ? true : false;
                } else {
                    return true;
                }
            },
        unreadNotifications: (state) => {
            return (delay) => {
                return state.notifications.filter((notification) => {
                    return !state.isRead(notification, delay);
                });
            };
        },
        getEvent(state) {
            return (id) => {
                return id ? _.find(state.events, { id: parseInt(id) }) : null;
            };
        },
        getUser(state) {
            return (id) => {
                return id ? _.find(state.users, { id: parseInt(id) }) : null;
            };
        },
    },
});
