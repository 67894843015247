<template>
    <AccountSportsModal
        @input="useLazyUpdateProfile('assigned_sports_levels', $event)"
    />

    <CheckboxModal
        modal="account-position"
        isLazy
        :autoClose="false"
        :name="__('Vyberte pozíciu')"
        type="radio"
        :options="_.orderBy(sportStore.positions, 'name', 'asc')"
        :modelValue="user.assigned_position_id"
        @input="useLazyUpdateProfile('assigned_position_id', $event)"
    />

    <CheckboxModal
        modal="account-type"
        isLazy
        :autoClose="false"
        :name="__('Vyberte typ účtu')"
        type="radio"
        :options="authStore.account_types"
        :modelValue="user.account_type"
        @input="useLazyUpdateProfile('account_type', $event)"
    />

    <CheckboxModal
        modal="account-gender"
        isLazy
        :autoClose="false"
        :name="__('Vyberte pohlavie')"
        type="radio"
        :options="authStore.genders"
        :modelValue="user.gender"
        @input="useLazyUpdateProfile('gender', $event)"
    />

    <DateModal
        modal="account-birthdate"
        isLazy
        :autoClose="false"
        :name="__('Dátum narodenia')"
        :modelValue="user.birthdate"
        :prefer-wheel="true"
        @input="useLazyUpdateProfile('birthdate', $event)"
    />

    <TextModal
        modal="account-about"
        isLazy
        :autoClose="false"
        :name="__('O mne')"
        :modelValue="user.about"
        :auto-grow="true"
        :counter="true"
        :maxlength="500"
        @input="useLazyUpdateProfile('about', $event)"
    />

    <DistanceModal
        modal="account-radiuskm"
        isLazy
        :modelValue="user.search_radius_km || useDefaultFilterRadius()"
        :min="useBackendEnv('DEFAULT_FILTER_RADIUS_KM_MIN')"
        :max="useBackendEnv('DEFAULT_FILTER_RADIUS_KM_MAX')"
        :step="5"
        @input="useLazyUpdateProfile('search_radius_km', $event)"
    />
</template>

<script setup>
import _ from 'lodash';

const authStore = useAuthStore();
const sportStore = useSportStore();

const user = computed(() => authStore.user);

const useLazyUpdateProfile = async (key, event) => {
    const obj = {};
    obj[key] = event.value;

    await useUpdateProfile(obj);

    //Callback after finish
    if (typeof event?.callback == 'function') {
        event.callback();
    }
};
</script>
