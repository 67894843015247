<template>
    <EmptyResults
        v-if="attendeesFiltrated.length == 0"
        :text="emptyResultsText || __('Žiadne nové žiadosti.')"
    />

    <Loading
        v-if="loading"
        :black="true"
        :full="true"
        :text="__('Prebieha ukladanie')"
    />

    <EventParticipant
        v-for="attendee in attendeesFiltrated"
        :attendee="attendee"
        :key="attendee.id"
        @accountClick="emit('accountClick', $event)"
    >
        <template v-if="useIsMe(event?.user_id)">
            <ion-button
                v-if="trashMode && !useIsMe(attendee.id)"
                fill="clear"
                class="trash-btn"
                @click="
                    showDeclineModalOnce(() =>
                        updateAttendee(attendee, 'declined')
                    )
                "
                :class="{
                    '--notgoing': changesBuffer[attendee.id] == 'declined',
                }"
            >
                <ion-icon :icon="trashOutline"></ion-icon>
            </ion-button>

            <span
                v-if="(!trashMode || trashMode == 'declined') && canDecline"
                class="check-icon --request"
                @click="
                    !trashMode ? updateAttendee(attendee, 'declined') : null
                "
                :class="{
                    '--disabled': trashMode,
                    '--notgoing':
                        changesBuffer[attendee.id] == 'declined' || trashMode,
                }"
            >
                <ion-icon :icon="closeOutline"></ion-icon>
            </span>

            <span
                v-if="!trashMode || trashMode == 'accepted'"
                class="check-icon --request"
                @click="
                    !trashMode ? updateAttendee(attendee, 'accepted') : null
                "
                :class="{
                    '--disabled': trashMode,
                    '--going':
                        changesBuffer[attendee.id] == 'accepted' || trashMode,
                }"
            >
                <ion-icon :icon="checkmarkOutline"></ion-icon>
            </span>
        </template>
    </EventParticipant>
</template>

<script setup>
import _ from 'lodash';
import { checkmarkOutline, closeOutline, trashOutline } from 'ionicons/icons';

const props = defineProps({
    modal: {},
    termine: {},
    attendees: {},
    status: {},
    emptyResultsText: {},
    canDecline: {
        default: true,
    },
    trashMode: {
        default: false, //accepted|declined
    },
});

const emit = defineEmits(['accountClick']);

const eventStore = useEventStore();
const attendees = defineModel({ default: [] });

let _saveTimeout;
const loading = ref(false);
const modalConfirmDisabled = ref(false);
const changesBuffer = ref({});

const attendeesFiltrated = computed(() =>
    attendees.value.filter((attendee) =>
        _.castArray(props.status).includes(attendee.status)
    )
);

const updateAttendee = (attendee, type) => {
    useThrowConnectionAlert();

    //On double click reverse
    if (changesBuffer.value[attendee.id] == type) {
        delete changesBuffer.value[attendee.id];
    } else {
        changesBuffer.value[attendee.id] = type;
    }
};

watch(
    changesBuffer,
    (changes) => {
        const updatedIds = Object.keys(changes);
        if (updatedIds.length == 0) {
            return;
        }

        if (updatedIds.length == attendeesFiltrated.value.length) {
            save(true);
        } else {
            if (_saveTimeout) {
                clearTimeout(_saveTimeout);
            }

            _saveTimeout = setTimeout(() => {
                save();
            }, 3000);
        }
    },
    { deep: true }
);

const save = async (close = false) => {
    const changes = _.cloneDeep(changesBuffer.value);
    if (Object.keys(changes) == 0) {
        return;
    }

    loading.value = true;

    try {
        let response = await useAxios().$postOnline(
            // prettier-ignore
            '/api/events/' + props.termine.event_id + '/termines/'+props.termine.id+'/attendees/review',
            {
                attendance: changes,
            }
        );

        useAutoAjaxResponse(response);

        if (close == true && props.modal) {
            useCloseModal(props.modal);
        }

        //Update attendees
        attendees.value = response.data.attendees;
    } catch (e) {
        useAutoAjaxResponse(e);
    }

    //Delete only saved keys from buffer
    for (var key in changes) {
        delete changesBuffer.value[key];
    }

    loading.value = false;
};

const showDeclineModalOnce = async (callback) => {
    if (modalConfirmDisabled.value) {
        return await callback();
    }

    useOpenModal('modal-confirm', {
        title: __('Zamietnúť účasť'),
        // prettier-ignore
        message : __('Naozaj si prajete zamietnúť účasť pre tohto hráča? Informujeme ho, a pre tento termín sa už nebude môcť prihlásiť.'),
        success: async () => {
            modalConfirmDisabled.value = true;

            await callback();
        },
    });
};

const event = computed(() => {
    return eventStore.getEvent(props.termine?.event_id);
});
</script>
