import { defineNuxtPlugin } from '#app';
import { Keyboard } from '@capacitor/keyboard';
import { App } from '@capacitor/app';

import Network from '../utils/Network.js';

const setKeyboardListener = () => {
    useAppStore().keyboard = false;

    if (useIsDesktop() == false) {
        Keyboard.addListener('keyboardWillShow', (info) => {
            useAppStore().keyboard = true;
        });

        Keyboard.addListener('keyboardDidHide', (info) => {
            useAppStore().keyboard = false;
        });
    }
};

const onDeviceReady = async (vueApp) => {
    try {
        new Network().boot();
    } catch (e) {
        console.error('Network error:', e);
    }

    try {
        setKeyboardListener();
    } catch (e) {
        console.error('Keyboard', e);
    }

    try {
        useDebugMode(vueApp);
    } catch (e) {
        console.error('Debug', e);
    }
};

export default defineNuxtPlugin(async (options) => {
    const { vueApp } = options;

    //Global
    window.$appStore = useAppStore();
    window.useLocationStore = useLocationStore();

    //Immediately listens for notifications, before Vue app boot
    useAddNotificationListeners();

    setTimeout(() => {
        // prettier-ignore
        if ( useAppStore().booted == false ){
            useThrowConnectionAlert(__('Pre prvé spustenie aplikácie prosím zapnite internetové pripojenie.'));
        }
    }, 2000);

    //If is web browser, we want boot app immidiatelly
    if (isPlatform('mobileweb') || isPlatform('desktop')) {
        onDeviceReady(vueApp);
    }

    //On device, we want boot services after app is ready
    else {
        document.addEventListener(
            'deviceready',
            () => {
                onDeviceReady(vueApp);
            },
            false
        );
    }
});
