<template>
    <ModalWrapper
        :is-open="useIsModalOpen(modal)"
        class="full-modal"
        :authorized="true"
    >
        <ion-content class="ion-padding">
            <div class="text_wrapper">
                <img src="~/assets/images/icons/report-check.svg" />

                <h3>{{ __('Ďakujeme za nahlásenie') }}</h3>

                <!-- prettier-ignore -->
                <p>{{ __('Vašu správu skontrolujeme a v prípade porušenia našich pokynov pre komunitu podnikneme potrebné kroky.') }}</p>

                <p v-if="user">
                    {{ __('Prajete si zablokovať používateľa?') }}
                </p>
            </div>
        </ion-content>

        <ion-footer class="ion-no-border">
            <ion-toolbar class="--withIndicator">
                <div class="px-2">
                    <div v-if="user">
                        <!-- prettier-ignore -->
                        <small class="disclaimer">{{ __('Používateľ ťa nebude môcť ďalej kontaktovať ani pozývať na partičky') }}</small>

                        <!-- prettier-ignore -->
                        <FormButton full class="mb-1" @click="useLazyClick($event, async () => await useBlockUser(user))">
                            {{ __('Zablokovať') }}
                        </FormButton>
                    </div>

                    <FormButton
                        visual="bordered"
                        full
                        @click="
                            useCloseModal(modal);
                            router.push({ name: 'app-feed' });
                        "
                    >
                        {{ __('Naspäť na domovskú stránku') }}
                    </FormButton>
                </div>
            </ion-toolbar>
        </ion-footer>
    </ModalWrapper>
</template>

<script setup>
const props = defineProps({
    modal: { default: 'report-submitted' },
});

const router = useRouter();
const data = computed(() => useGetModalData(props.modal));

const user = computed(() => data.value?.user);
</script>

<style lang="scss" scoped>
.text_wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 3rem;
    font-size: 1.2rem;
}

small.disclaimer {
    display: block;
    margin-bottom: 1rem;
    color: var(--ion-text-color-lighter);
    font-size: 1rem;
}

ion-footer {
    ion-toolbar {
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

h2 {
    font-size: 1.4rem;
}

:deep(.--radio) {
    border: 1px solid #dddddd;
    padding: 1.2rem 1.5rem 1.2rem 0.5rem;
    border-radius: 0.6rem;
}
</style>
