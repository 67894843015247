import _ from 'lodash';

export default defineNuxtPlugin((nuxtApp) => {
    const router = nuxtApp.$router;

    const original = {
        push: router.push,
        replace: router.replace,
    };

    const uniqueRouteId = () => {
        return _.uniqueId('router');
    };

    const castParams = (params) => {
        const route = params[0];

        // Add unique router identifier
        if (_.isObject(route) && route.name) {
            // Set route query
            route.query = route.query || {};
            route.query._r = uniqueRouteId().replace('router', '');
        }

        return params;
    };

    router.push = async function () {
        let params = castParams(arguments);

        return await original.push.apply(this, params);
    };

    router.replace = async function () {
        let params = castParams(arguments);

        return await original.replace.apply(this, params);
    };
});
