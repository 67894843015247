<template>
    <DynamicModal
        :modal="modal"
        :name="
            termine
                ? __('Zmena najbližšieho termínu')
                : eventStore.typeText(event?.type, 'title_new_termine')
        "
        v-slot="{ data }"
        :full="true"
        :canSave="false"
    >
        <form
            v-autoAjax
            method="post"
            :action="action"
            @success="success"
            @loading="loading = $event"
            @data="formData"
        >
            <EventDateInputs />

            <div class="input-group mt-1">
                <div
                    class="input-group__item"
                    @click="useOpenModal('event-players')"
                    v-if="isSportEvent"
                >
                    <FormLabel>{{ __('Počet hráčov') }}</FormLabel>

                    <div class="form-group">
                        <div
                            @click="useOpenModal('event-players')"
                            class="input-group__value"
                            :class="{ '--placeholder': _.isNil(form.players) }"
                            data-virtual-input="players"
                        >
                            {{
                                _.isNil(form.players)
                                    ? __('Vyberte počet hráčov')
                                    : form.players || __('Bez obmedzenia')
                            }}
                        </div>
                    </div>
                </div>
                <div class="input-group__item">
                    <FormLabel>
                        {{ __('Suma za člena') }}
                    </FormLabel>

                    <FormText
                        type="number"
                        step="any"
                        :min="0"
                        placeholder="0 €"
                        v-model="form.price_per_player"
                        name="price_per_player"
                    />
                </div>
            </div>

            <!-- <ion-radio-group value="custom-checked">
                <ion-radio value="1" label-placement="end">Tréning</ion-radio>
                <ion-radio value="2" label-placement="end">Služba</ion-radio>
            </ion-radio-group> -->

            <!-- prettier-ignore -->
            <FormInfo>
                {{ __('Tieto informácie platia aj pre všetky budúce termíny') }}
            </FormInfo>

            <FormButton type="submit" full class="mt-2" :loading="loading">
                <div class="d-flex flex-column">
                    <span>
                        {{
                            termine
                                ? __('Upraviť udalosť')
                                : __('Vytvoriť udalosť')
                        }}
                    </span>
                    <small>{{ __('a informovať členov') }}</small>
                </div>
            </FormButton>
        </form>
    </DynamicModal>
</template>

<script setup>
import _ from 'lodash';
import moment from 'moment';

const props = defineProps({
    modal: { default: 'event-termine-update' },
});

const eventStore = useEventStore();
const router = useRouter();

const loading = ref(false);
const form = computed(() => eventStore.form);

const data = computed(() => useGetModalData(props.modal));
const event = computed(() => data.value?.event);
const termine = computed(() => data.value?.termine);

const isSportEvent = computed(() => {
    return eventStore.types[form.value.type]?.sport;
});

useOnModalOpen(props.modal, () => {
    const { event, termine } = useGetModalData(props.modal);

    if (termine) {
        eventStore.loadEvent(event, termine);
    } else {
        eventStore.reset();
        eventStore.loadEvent(_.pick(event, ['players', 'price_per_player']));
    }
});

useOnModalClose(props.modal, () => {
    eventStore.reset();
});

const action = computed(() => {
    if (!event.value) {
        return;
    }

    // prettier-ignore
    return '/api/events/' + event.value?.id + '/termines'+(termine.value ? '/'+termine.value.id : '');
});

const formData = () => {
    return {
        players: eventStore.form.players,
        date: eventStore.dateMerged?.format(),
    };
};

const success = async (response) => {
    useAutoAjaxResponse(response);

    //If termine has been created, redirect to termine page.
    const termineId = response.data?.termine_id || termine.value?.id;

    //We want redirect user on correct termine id, if is missing on event page.
    //Because default termine may be switched by closest date.
    //So we want keep user on same termine in this case.
    if (['app-event-slug'].includes(useRoute().name) && termineId) {
        router.replace({
            query: {
                t: termineId,
            },
        });
    }

    useCloseModal('event-termine-update');
};
</script>

<style lang="scss" scoped>
.form-info {
    margin-top: 0.5rem;
}

ion-radio-group {
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
}

ion-radio {
    &::part(container) {
        width: 1.4rem;
        height: 1.4rem;
        border: 1px solid #bebebe;
        border-radius: 50%;
    }

    &::part(label) {
        margin-left: 0.5rem;
        font-size: 1.4rem;
        color: #001f27;
        line-height: 2rem;
    }

    &.radio-checked {
        &::part(container) {
            border-color: var(--ion-color-primary);
        }

        &::part(mark) {
            width: 0.8rem;
            height: 0.8rem;
            background: var(--ion-color-primary);
            border-radius: 50%;
        }
    }
}
</style>
