import * as Sentry from '@sentry/capacitor';
import * as SentryVue from '@sentry/vue';
import * as SentryBrowser from '@sentry/browser';
import Hotjar from '@hotjar/browser';
import { Smartlook } from '@awesome-cordova-plugins/smartlook';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';

export const useDebugMode = async (app) => {
    //Wait till app is booted
    await useWaitTillBoot();

    [
        useTrackingMode,
        useSentryMode,
        // useHotjarMode,
        useSmartlookMode,
        useFBTracking,
        useAnalyticsMode,
    ].forEach((fn) => {
        try {
            fn();
        } catch (e) {
            console.error(e);
        }
    });
};

const onUserChange = (callback) => {
    // Watch user
    watch(
        () => {
            if (useTrackingStore().consent.authorized) {
                return useAuthStore().user;
            }
        },
        (user) => {
            callback(user);
        },
        { immediate: true }
    );
};

const onRouteEnter = (callback) => {
    // Track entered routes
    watch(
        () => useRoute().href,
        (href) => {
            if (href) {
                callback(href);
            }
        },
        { immediate: true }
    );

    // Track entered modals
    onModalOpened((modalName) => {
        callback('modal-' + modalName);
    });
};

const onModalOpened = (callback) => {
    watch(
        () => openedModals.value?.length,
        () => {
            let modals = openedModals.value || [],
                lastModal = modals[modals.length - 1];

            if (lastModal && lastModal.name) {
                callback(lastModal.name);
            }
        },
        { immediate: true }
    );
};

const useSmartlookMode = () => {
    const smartlookConfig = useSettingStore().smartlook || {};

    // Sentry is not enabled anymore
    if (!smartlookConfig.key || smartlookConfig.enabled !== true) {
        console.log('Smartlook disabled.', smartlookConfig.siteId);
        return;
    }

    Smartlook.setProjectKey({ key: smartlookConfig.key });

    Smartlook.start();

    onUserChange((user) => {
        Smartlook.setUserIdentifier({ identifier: user?.id });
        Smartlook.setUserName({ name: user?.username });
        Smartlook.setUserEmail({ email: user?.email });
    });

    onRouteEnter((path) => {
        Smartlook.trackNavigationEnter({ eventName: path });
    });

    console.log('Smartlook initialized.', smartlookConfig);
};

const useHotjarMode = () => {
    const hotjarConfig = useSettingStore().hotjar || {};

    // Sentry is not enabled anymore
    if (!hotjarConfig.siteId || hotjarConfig.enabled !== true) {
        console.log('Hotjar disabled.', hotjarConfig.siteId);
        return;
    }

    Hotjar.init(hotjarConfig.siteId, 6);

    onUserChange((user) => {
        Hotjar.identify(user?.id, {
            username: user?.username,
            email: user?.email,
        });
    });

    console.log('Hotjar initialized.', hotjarConfig);
};

const useSentryMode = async (app) => {
    const sentryConfig = useSettingStore().sentry || {};

    // Sentry is not enabled anymore
    if (!sentryConfig.dsn || sentryConfig.enabled !== true) {
        console.log('Sentry disabled.', sentryConfig.dsn);
        return;
    }

    Sentry.init(
        {
            app,
            dsn: sentryConfig.dsn,
            environment: import.meta.env.VITE_APP_ENVIRONMENT,
            release: await getAppVersion(),
            integrations: [
                Sentry.browserTracingIntegration({ router: useRouter() }),

                // Enable replays
                sentryConfig.replays
                    ? Sentry.replayIntegration({
                          maskAllText: false,
                          blockAllMedia: false,
                      })
                    : null,
            ].filter((item) => item),
            ...(sentryConfig.options || {}),
        },
        // Forward the init method from @sentry/vue
        SentryVue.init
    );

    onUserChange((user) => {
        Sentry.setUser(
            user
                ? {
                      id: user.id,
                      username: user.username,
                      email: user.email,
                  }
                : null
        );
    });

    console.log('Sentry initialized.', sentryConfig);
};

export const useTrackingMode = async () => {
    // Dont run tracking mode if onboarding modal is open
    do {
        await useSleep(1000);
    } while (useIsModalOpen('onboarding') === true);

    console.log('Tracking mode initialized.');

    const trackingStore = useTrackingStore();

    // Set tracking enabled
    if (isPlatform('ios') === false) {
        return trackingStore.setConsent(true);
    }

    const response = await AppTrackingTransparency.getStatus();

    if (['notDetermined'].includes(response.status)) {
        let response = await AppTrackingTransparency.requestPermission();

        trackingStore.setConsent(response.status == 'authorized');
    } else if (['authorized'].includes(response.status)) {
        trackingStore.setConsent(true);
    } else {
        trackingStore.setConsent(false);
    }
};

export const useFBTracking = async () => {
    console.log('FB Tracking enabled.');

    try {
        await FacebookLogin.setAutoLogAppEventsEnabled({ enabled: true });
        await FacebookLogin.setAdvertiserTrackingEnabled({ enabled: true });
        await FacebookLogin.setAdvertiserIDCollectionEnabled({ enabled: true });
    } catch (e) {
        console.error(e);
    }
};

const useAnalyticsMode = () => {
    // Track entered modals
    onModalOpened((modalName) => {
        logEvent('modal_open', {
            name: modalName,
        });
    });
};
