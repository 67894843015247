<template>
    <EventStep :errors="getValidationError">
        <div
            class="form-item mb-2"
            v-if="!eventTypeOnFormOpen && enabledTypesOptions.length > 1"
        >
            <FormLabel>{{ __('Typ udalosti') }}</FormLabel>
            <FormChoose
                v-model="form.type"
                :name="__('Typ udalosti')"
                :options="enabledTypesOptions"
                modal="event-type"
            />

            <FormInfo>{{ __('Typ o akú udalosť sa jedná') }}</FormInfo>
        </div>

        <div
            class="form-item mb-2"
            v-if="eventStore.types[form.type]?.sport && !form.id"
        >
            <FormLabel>{{ __('Vyberte šport') }}</FormLabel>

            <FormChoose
                v-model="form.sport_id"
                :name="__('Vyberte šport')"
                :options="sportStore.sportsSorted"
                modal="event-choose-sport"
            />
            <!-- prettier-ignore -->
            <FormInfo>{{ __('Aký šport budete vykonávať ?') }}</FormInfo>
        </div>

        <div class="form-item mb-2">
            <FormLabel>{{ __('Zadajte názov') }}</FormLabel>
            <!-- autofocus -->
            <FormText v-model="form.name" :placeholder="__('Názov')" />
            <FormInfo>{{ __('Názov udalosti/skupiny') }}</FormInfo>
        </div>

        <div class="form-item" v-if="eventStore.types[form.type]?.service">
            <FormLabel>{{ __('Vyberte typ služby') }}</FormLabel>
            <FormChoose
                v-model="form.service_id"
                :name="__('Vyberte typ')"
                :options="sportStore.services"
                modal="event-choose-sservice"
            />
            <!-- prettier-ignore -->
            <FormInfo>{{ __('Aku službu ponúkate ?') }}</FormInfo>
        </div>

        <div class="mt-2">
            <EventTermineSetup
                :withDate="eventStore.hasFormDates"
                v-model:errors="termineErrors"
            />
        </div>
    </EventStep>
</template>

<script setup>
import _ from 'lodash';
const sportStore = useSportStore();
const eventStore = useEventStore();
const authStore = useAuthStore();
const form = computed(() => eventStore.form);
const eventTypeOnFormOpen = ref();
const termineErrors = ref();

onMounted(() => {
    eventTypeOnFormOpen.value = form.value.type;

    setDefaultEventType();
});

watch(
    () => form.value?.sport_id,
    (id) => {
        useOtherSportModal(id);
    }
);

const getValidationError = computed(() => {
    if (!form.value.type) {
        return __('Vyplnte typ udalosti.');
    }

    //Name is not important anymore, we will fill sport name.
    // if ((form.value.name || '').length <= 2) {
    //     return __('Vyplnte názov.');
    // }

    if (eventStore.types[form.value.type]?.sport && !form.value.sport_id) {
        return __('Vyberte šport.');
    }

    if (eventStore.types[form.value.type]?.service && !form.value.service_id) {
        return __('Vyberte službu.');
    }

    if (termineErrors.value) {
        return termineErrors.value;
    }

    return false;
});

const enabledTypesOptions = computed(() => {
    return _.map(eventStore.enabledEventTypes, (type, key) => ({
        id: key,
        name: type.name,
    }));
});

const setDefaultEventType = () => {
    // 1. if type is not allowed for current account
    // 2. if there is only one type available
    // prettier-ignore
    if (enabledTypesOptions.value.length && (!form.value.type || !(form.value.type in eventStore.enabledEventTypes)) ) {
        form.value.type = eventStore.defaultEventType;
    }
};
</script>
