<template>
    <AppModal
        :full="true"
        :canClose="true"
        :modal="modal"
        :title="article?.name || __('Načitávame...')"
    >
        <template #close>
            <a
                @click="
                    useCloseModal(modal);
                    data?.callback();
                "
                class="--save"
            >
                {{ __('Súhlasim') }}
            </a>
        </template>

        <ArticleContent v-if="data?.slug" :slug="data.slug" v-model="article" />
    </AppModal>
</template>

<script setup>
const props = defineProps({
    modal: { default: 'article-modal' },
});

const data = computed(() => useGetModalData(props.modal));

const article = ref();
</script>

<style lang="scss" scoped>
.--save {
    font-weight: 500;
    font-size: 1.4rem;
    color: var(--ion-color-primary);
}
</style>
