import _ from 'lodash';

const history = [];

export default defineNuxtRouteMiddleware((to, from) => {
    // prettier-ignore
    for ( var i = useNextRouteState.length -1; i >= 0; i-- ) {
        const state = useNextRouteState[i];

        if ( useIsSameStateToRoute(to, state)) {
            //DEV:
            // console.log('State for this route found in history:', state.to, state.state);

            //Bind params into meta
            for (var key in state.state) {
                to.meta[key] = state.state[key];
            }

            //Remove for only once passing params
            if ( state.once === true ){
                useNextRouteState.splice(i, 1);
            }

            break;
        }
    }
});
