<template>
    <p class="empty__results">
        <span v-if="appStore.connected">
            {{ text || __('Nenašli sme žiadne výsledky.') }}
        </span>

        <!-- prettier-ignore -->
        <span v-else>{{ offline || __('Pre zobrazenie záznamov prosím zapnite internetové pripojenie.') }}</span>
    </p>
</template>

<script setup>
const props = defineProps({
    text: {
        required: false,
    },
    offline: {
        default: null,
    },
});

const emit = defineEmits(['online']);

const appStore = useAppStore();

const alreadyOnline = ref(appStore.connected);

watch(
    () => appStore.connected,
    (state) => {
        //Fire online event only when online state has not been present in this results.
        if (alreadyOnline.value == false && state) {
            emit('online');

            alreadyOnline.value = true;
        }
    }
);
</script>

<style lang="scss" scoped>
.empty__results {
    font-size: 1.2rem;
    margin: 0;
}
</style>
