import _ from 'lodash';

// const defaultCredentials = {
//     username: null,
//     email: 'kos@marekgogol.sk',
//     phone: '+421 917 238 696',
//     password: 'heslo123',
//     password_confirmation: 'heslo123',
//     license_terms: true,
// };

const defaultCredentials = {
    account_type: 'player',
    username: null,
    email: '',
    phone: '',
    password: '',
    password_confirmation: '',
    license_terms: false,
    gender: null,
    birthdate: null,
    about: null,
};

export const useRegisterStore = defineStore('register', {
    persist: true,

    state() {
        return {
            // Registration flow
            currentStep: 1,
            steps: [],
            guestsOnly: true,

            // Mandatory registration data
            credentials: _.cloneDeep(defaultCredentials),

            // Optional registration data
            selectedSports: [],
            photos: [],
        };
    },

    actions: {
        initialize(currentStep, steps, user = null) {
            this.currentStep = currentStep;
            this.steps = steps;

            if (user) {
                this.loadUser(user);
            }
        },
        reset() {
            // Reset current registration flow
            this.currentStep = 1;
            this.steps = [];
            this.guestsOnly = true;

            // Reset mandatory registration data
            this.credentials = _.cloneDeep(defaultCredentials);

            // Reset optional registration data
            this.selectedSports = [];
            this.photos = [];

            // Reset OTP store
            useOtpStore().reset();
        },
        loadUser(user) {
            // Allow registration also for logged users
            this.guestsOnly = false;

            for (var key in user) {
                if (key in this.credentials && !this.credentials[key]) {
                    this.credentials[key] = user[key];
                }
            }

            // Load selected sports
            this.selectedSports = user.assignedSportsLevels || [];

            // Load photos
            this.photos = buildUserPhotos(user);
        },
    },

    getters: {
        stepName: (state) => {
            return state.steps[state.currentStep - 1];
        },
        stepConfig: (state) => {
            return (
                _.find(useBackendEnv('ONBOARDING_STEPS'), {
                    name: state.stepName,
                }) || {}
            );
        },
    },
});
