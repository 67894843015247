import moment from 'moment';

import { defineNuxtPlugin } from '#app';

import sk from 'moment/dist/locale/sk';

export default defineNuxtPlugin(async ({ vueApp }) => {
    moment.locale('sk', sk);

    moment.updateLocale('sk', {
        relativeTime: {
            future: 'o %s',
            past: 'pred %s',
        },
    });
});
