import _ from 'lodash';
import moment from 'moment';

const defaultForm = {
    id: null,
    termine_id: null,
    type: null,
    name: null,
    sport_id: null,
    service_id: null,
    accepted: false,
    date: null,
    time: null,
    repeat: false,
    repeat_days: [],
    players: null,
    price_per_player: null,
    description: null,
    is_public: true,
    must_accept: true,
    cover_id: null,
};

const defaultState = {
    steps: null,
    previousStep: 0,
    currentStep: 1,
    form: _.cloneDeep(defaultForm),
    location: null,
    isCustomLocation: false,
    customCoordinates: null,
};

export const useEventStore = defineStore('event', {
    persist: true,

    state() {
        return {
            ..._.cloneDeep(defaultState),
            events: [],
            types: [],
        };
    },

    actions: {
        initialize(steps, currentStep = 1) {
            this.steps = steps;

            this.previousStep = 0;
            this.currentStep = currentStep;
        },
        reset() {
            const ds = _.cloneDeep(defaultState);

            for (var key in ds) {
                this[key] = ds[key];
            }

            this.previousStep = 0;
        },
        setLocation(location) {
            this.isCustomLocation = false;
            this.customCoordinates = null;
            this.location = location;
        },
        loadEvent(event, termine) {
            if (event) {
                for (var key in defaultForm) {
                    if (key in event) {
                        this.form[key] = event[key];
                    }
                }

                this.location = event.location;

                this.isCustomLocation =
                    !event.location_id && event.lat && event.lng ? true : false;
                this.customCoordinates = this.isCustomLocation
                    ? {
                          lat: parseFloat(event.lat),
                          lng: parseFloat(event.lng),
                      }
                    : null;
                this.form.accepted = true;
            }

            if (termine) {
                this.form.termine_id = termine.id;
                this.form.date = termine.date;
                this.form.time = termine.has_time ? termine.date : null;
            }
        },
        addEvent(event) {
            if (this.getEvent(event.id)) {
                this.updateEvent(event);
            } else {
                this.events = [event].concat(this.events);
            }
        },
        addSearchEvent(event) {
            this.addEvent(event);

            const userId = useAuthStore().user.id;

            //Remove old registered search events.
            this.events
                .filter((e) => {
                    return (
                        e.type == 'event-search' &&
                        e.user_id != userId &&
                        moment().diff(moment(e.created_at), 'hours') >= 24
                    );
                })
                .forEach((eventToRemove) => {
                    this.deleteEvent(eventToRemove.id);
                });
        },
        deleteEvent(id) {
            _.remove(this.events, { id: parseInt(id) });
        },
        updateEvent(updatedEvent) {
            let existingEvent = this.getEvent(updatedEvent.id);

            if (existingEvent) {
                //Do not flush old termines.
                updatedEvent.termines = useSortTermines(
                    _.uniqBy(
                        (updatedEvent.termines || []).concat(
                            existingEvent?.termines || []
                        ),
                        'id'
                    )
                );

                Object.assign(existingEvent, updatedEvent);
            }
        },
        addTermine(termine) {
            let event = this.getEvent(termine.event_id);

            if (event && event.termines) {
                event.termines.push(termine);
            }
        },
        updateTermine(updatedTermine) {
            let existingTermine = this.getTermine(updatedTermine);

            if (existingTermine) {
                Object.assign(existingTermine, updatedTermine);
            }
        },
        bindLastMessage(message) {
            let event = this.getEvent(message?.event_id);

            if (event) {
                message.is_seen = true;

                //Set missing thread id
                message.chat_thread_id = useChatStore().threadId;

                event.last_message = message;
            }
        },
        goToChat(event) {
            //Disable go to chat when there is not approved meeting
            if (this.hasEventChatAccess(event) == false) {
                return;
            }

            useRouter().push(
                '/app/event/' +
                    event.slug +
                    '/chat?event_id=' +
                    event.id +
                    '&thread_id=' +
                    (event.last_message?.chat_thread_id || '')
            );
        },
        goToUser(event) {
            if (useIsMe(event.user)) {
                useRouter().push('/app/profile/account');
            } else if (event.user) {
                pushStateRoute(
                    { path: '/app/account/' + event.user.slug },
                    { account: event.user }
                );
            } else {
                useShowErrorToast(__('Tento používateľ už neexistuje.'));
            }
        },
    },
    getters: {
        getEvent(state) {
            return (id) => {
                if (id) {
                    return _.find(state.events, { id: parseInt(id) });
                }
            };
        },
        getTermine(state) {
            return (data) => {
                const { event_id, id } = data || {};

                let event = state.getEvent(event_id);
                if (event) {
                    return _.find(event.termines, { id: parseInt(id) });
                }
            };
        },
        sampleEvent() {
            let event = _.cloneDeep(
                useDashboardStore().eventsToday()[0] ||
                    useDashboardStore().eventsTomorrow[0]
            );

            //Change ownership to be as my event
            if (event) {
                event.user_id = useAuthStore().user?.id || event.user_id;
                event.user = useAuthStore().user || event.user;
            }

            return event;
        },
        getName: (state) => {
            return (event) => {
                let sportName = useSportStore().getById(event.sport_id)?.name,
                    latestTermines = state.latestTermines(event),
                    latestDate = latestTermines[0]
                        ? moment(latestTermines[0].date).format('D.M')
                        : null,
                    eventName = event.name,
                    opponentUsername = null;

                //Create event opponent name
                if (state.canUseOpponent(event)) {
                    opponentUsername = useIsMe(event.user)
                        ? state.latestTermineOpponent(event)?.opponent_username
                        : event.user?.username;
                }

                // Use opponent username
                return [eventName || opponentUsername, sportName, latestDate]
                    .filter((i) => i)
                    .join(' • ');
            };
        },
        getThumbnail: (state) => {
            return (event, withUserPhoto = false) => {
                // use custom thumbnail
                if (event.thumbnail) {
                    return event;
                }

                // Use event player or opponent player (but only for events with 2 players max.)
                if (withUserPhoto === true && state.canUseOpponent(event)) {
                    const thumbnail = useIsMe(event.user)
                        ? state.latestTermineOpponent(event)?.opponent_thumbnail
                        : event.user?.thumbnail;

                    if (thumbnail) {
                        return {
                            thumbnail: thumbnail,
                            portraitThumbnail: thumbnail,
                        };
                    }
                }

                // use choosen cover
                if (event.cover_id) {
                    return _.find(useSportStore().covers, {
                        id: parseInt(event.cover_id),
                    });
                }
            };
        },
        dateMerged() {
            if (!this.form.date || !this.form.time) {
                return;
            }

            return moment(
                moment(this.form.date).format('Y-MM-DD') +
                    ' ' +
                    moment(this.form.time).format('HH:mm')
            );
        },
        myEvents() {
            return _.filter(this.events, { user_id: useAuthStore().user?.id });
        },
        mySearchEvents() {
            let events = _.filter(this.myEvents, { type: 'event-search' });

            return flattenEventTermines(events, (termine, event) => {
                return !termine.canceled_at && isFutureTermine(termine);
            });
        },
        pendingOrDeclinedEvents() {
            return groupEventTerminesByDate(this.events, (termine, event) => {
                //Show my pending requests from other players
                if (useIsMe(event.user)) {
                    if (termine.attendees_pending == 0) {
                        return false;
                    }
                }

                //Show also my requested attendance
                else {
                    // Only pending + declined
                    // prettier-ignore
                    if ( ['pending', 'declined'].includes(termine.attendance_status) === false ) {
                        return false;
                    }
                }

                // Only future termines
                if (moment(termine.date) < moment()) {
                    return false;
                }

                return true;
            });
        },
        hasEventChatAccess(state) {
            return (event) => {
                return (
                    useIsMe(event.user) ||
                    event.termines.filter((termine) => termine.approved)
                        .length > 0
                );
            };
        },
        enabledEventTypes(state) {
            let accountType = useAuthStore().accountType,
                types = _.cloneDeep(state.types);

            for (var key in types) {
                let type = types[key];

                if (!(type.types || []).includes(accountType)) {
                    delete types[key];
                }
            }

            return types;
        },
        defaultEventType(state) {
            let accountType = useAuthStore().accountType;

            if (accountType == 'trainer') {
                return 'training';
            }

            return Object.keys(state.enabledEventTypes)[0] || 'event';
        },
        typeText(state) {
            return (type, key) => {
                if (state.types[type]?.texts) {
                    return state.types[type].texts[key];
                }
            };
        },
        isEventTypeEnabled(state) {
            return (type) => {
                return (
                    state.types[type] && state.types[type]?.disabled !== true
                );
            };
        },
        hasFormDates(state) {
            return !state.form?.id;
        },
        latestTermines(state) {
            return (event, withNullableDate = false) => {
                return _.orderBy(
                    event.termines.filter((t) => withNullableDate || t.date),
                    'date',
                    'desc'
                );
            };
        },
        canUseOpponent(state) {
            return (event) => {
                return event.stats_players <= 2;
            };
        },
        latestTermineOpponent(state) {
            return (event) => {
                return state
                    .latestTermines(event, true)
                    .filter((t) => t.opponent_username)[0];
            };
        },
    },
});
