<template>
    <CheckboxModal
        modal="account-sport"
        isLazy
        :autoClose="false"
        :name="__('Vyberte športy')"
        :options="sportStore.sportsSorted"
        :modelValue="user.assignedSportsLevels"
        :checked="(value, option) => getSportLevel(value, option.id)"
        :toggle="toggleSport"
    >
        <template v-slot:option="{ option, value, change }">
            <div class="sport-option">
                <div>
                    {{ option.name }}

                    <SportLevelBadge
                        class="sport__badge"
                        v-if="getSportLevel(value, option.id)"
                        :level="getSportLevel(value, option.id).level"
                        color="dark"
                    />
                </div>

                <a
                    v-if="getSportLevel(value, option.id)"
                    @click.prevent.stop="
                        openSportLevel(
                            option,
                            getSportLevel(value, option.id).level,
                            value,
                            change
                        )
                    "
                >
                    {{ __('Upraviť úroveň') }}
                </a>
            </div>
        </template>
    </CheckboxModal>
</template>

<script setup>
import _ from 'lodash';

const authStore = useAuthStore();
const sportStore = useSportStore();

const user = computed(() => authStore.user);

const getSportLevel = (levels, id) => {
    return _.find(levels, { sport_id: id });
};

const toggleSport = (change, value, option) => {
    value = _.cloneDeep(value);

    // When sport is selected, unselect it only.
    if (getSportLevel(value, option.id)) {
        removeSelectedSport(value, option);

        change(value);
    }

    // Add with default level, and ask for confirmation modal.
    else {
        value.push({ sport_id: option.id, level: 3 });

        change(value);

        openSportLevel(option, 3, value, change);
    }
};

const openSportLevel = (sport, level, value, change) => {
    useOpenModal('sport-level', {
        sport,
        level,
        // value: sportLevel.value,
        callback: (level) => {
            // Remove previously selected value
            removeSelectedSport(value, sport);

            if (_.isNil(level) === false) {
                value.push({ sport_id: sport.id, level: level });
            } else {
                useShowErrorToast(__('Vyberte úroveň pre zaznačenie športu.'));
            }

            change(value);
        },
    });
};

const removeSelectedSport = (value, sport) => {
    _.remove(value, { sport_id: sport.id });
};
</script>

<style lang="scss" scoped>
.sport-option {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .sport__badge {
        margin-left: 0.5rem;
    }

    a {
        color: var(--ion-color-primary);
        cursor: pointer;
        padding: 1rem;
        margin: -1rem;
    }
}
</style>
