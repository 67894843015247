<template>
    <AppHeading type="h1" class="text-center mb-2">
        {{ __('Zabudnuté heslo') }}
    </AppHeading>

    <FormText
        v-model="form.identifier"
        rounded
        :label="__('Telefónne číslo alebo email')"
    />

    <FormButton @click="useLazyClick($event, logIn)" full>
        {{ __('Odoslať verifikačný kód') }}
    </FormButton>

    <div class="my-2 text-center">
        <span class="divider">
            <i>{{ __('alebo') }}</i>
        </span>
    </div>

    <div class="my-2 text-center">
        <span class="link-prefix">{{ __('Spomenuli ste si?') }}</span>
        <a
            @click="
                modal
                    ? useOpenModalCloseCurrent('login-modal')
                    : router.push({ name: 'login' })
            "
            class="link"
        >
            <strong>{{ __('Prihlásiť sa') }}</strong>
        </a>
    </div>
</template>

<script setup>
const props = defineProps({
    modal: {
        default: false,
    },
});

const router = useRouter();
const otpStore = useOtpStore();

const form = ref({
    identifier: '',
});

onMounted(() => {
    otpStore.reset();
});

const logIn = async () => {
    useThrowConnectionAlert();

    if (!form.value.identifier) {
        return useShowErrorToast(
            __('Pre prihlásenie prosím vyplňte email alebo tel. číslo.')
        );
    }

    try {
        let response = await useAxios().$postOnline('/api/auth/login/otp', {
            identifier: form.value.identifier,
        });

        useAutoAjaxResponse(response);

        useCloseModal('forgot-password-modal');

        otpStore.proceedToRoute({
            route: '/api/auth/login/otp-verify',
            callback: {
                'auth/continue': null,
            },
            // next: { name: 'registration-user' },
        });
    } catch (e) {
        useAutoAjaxResponse(e);
    }
};
</script>

<style lang="scss" scoped>
.link {
    display: inline-block;
    font-size: 1.2rem;
    text-decoration: underline;
    color: #000;

    strong {
        font-weight: 600;
    }

    &:hover {
        text-decoration: none;
    }
}

.link-prefix {
    display: inline-block;
    font-size: 1.2rem;
    color: #000;
    margin-right: 0.4rem;
}

.divider {
    display: inline-block;
    font-size: 1.2rem;
    position: relative;
    padding: 0 5rem;

    i {
        font-style: normal;
        position: relative;
        z-index: 2;
        background: #fff;
        padding: 0 2rem;
    }

    &:before {
        content: '';
        width: 100%;
        left: 0;
        top: 50%;
        height: 1px;
        background: #979797;
        position: absolute;
        z-index: 1;
    }
}
</style>
