<template>
    <ChooseModal
        modal="filter-sports"
        v-model="form.sport_ids"
        :name="__('Vyberte športy')"
        :options="sportsWithDefault"
        :autoSelect="false"
        :multiple="true"
        :autoFocus="false"
        v-bind="useGetModalData('filter-sports') || {}"
    />

    <ChooseModal
        modal="filter-services"
        v-model="form.service_ids"
        :name="__('Vyberte služby')"
        :options="sportStore.services"
        :autoSelect="true"
        :multiple="false"
        :autoFocus="false"
    />

    <DateModal
        modal="filter-date"
        v-model="form.date"
        :autoSelect="true"
        :min="moment().format()"
        :name="__('Dátum')"
        :canClear="true"
        :cancelText="__('Kedykoľvek')"
    />

    <DateModal
        modal="filter-time"
        v-model="form.time"
        :name="__('Čas')"
        :hourValues="useHourValuesForToday(form.date)"
        minuteValues="0,15,30,45"
        presentation="time"
        :canClear="true"
        :cancelText="__('Kedykoľvek')"
    />

    <CheckboxModal
        modal="filter-location"
        :name="__('Vyberte lokalitu')"
        :options="
            [{ id: null, name: __('Kdekoľvek') }].concat(
                filterStore.locationsWithMyLocation
            )
        "
        :dynamicSelect="true"
        v-model="form.location_ids"
        :canClear="true"
    >
        <Loading :text="__('Získavame polohu')" v-if="locationStore.loading" />
    </CheckboxModal>

    <DistanceModal
        modal="filter-distance"
        v-model="form.radius"
        :step="5"
        :min="useBackendEnv('DEFAULT_FILTER_RADIUS_KM_MIN')"
        :max="useBackendEnv('DEFAULT_FILTER_RADIUS_KM_MAX')"
        :canClear="true"
        :default="useDefaultFilterRadius()"
    />
</template>

<script setup>
import _ from 'lodash';
import moment from 'moment';

const authStore = useAuthStore();
const sportStore = useSportStore();
const filterStore = useFilterStore();
const locationStore = useLocationStore();

const form = computed(() => filterStore.form);

watch(
    () => form.value.location_ids,
    async (ids) => {
        //If my location has been pressed
        if (filterStore.hasCurrentLocation) {
            let position = await locationStore.fetchCurrentPosition();

            //If current position has not been sent, then remove my position value
            if (!position) {
                filterStore.flushMyLocationFromFilter();
            }
        }
    }
);

const sportsWithDefault = computed(() => {
    return [
        {
            id: null,
            name: __('Všetky športy'),
            icon: '/images/sport-icons/all-sports.svg',
        },
    ].concat(sportStore.sportsSorted);
});
</script>

<style lang="scss" scoped>
:deep(.sheet-modal__container) {
    position: relative;

    .loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin-bottom: 0;
        background: #ffffff8c;
        z-index: 2;
    }
}
</style>
