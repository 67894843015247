import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { StatusBar, Style } from '@capacitor/status-bar';

import emitter from 'tiny-emitter/instance';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import _ from 'lodash';

export const useCopyToClipboard = (text) => {
    copy(text);
};

const bindStore = (store, key, value) => {
    if (typeof store[key] == 'function') {
        store[key](value);
    } else {
        store[key] = value;
    }
};

export const useBindPiniaStore = (data) => {
    const stores = [
        useAppStore(),
        useLocaleStore(),
        useOnboardingStore(),
        useAuthStore(),
        useSportStore(),
        useRegisterStore(),
        useOtpStore(),
        useSettingStore(),
        useLocationStore(),
        useAjaxStore(),
        useDashboardStore(),
        useFilterStore(),
        useEventStore(),
        useNotificationStore(),
        useAdsStore(),
    ];

    stores.forEach((store) => {
        for (var key in data) {
            //Bind by slash path
            if (key.includes('/')) {
                let parts = key.split('/');

                if (store.$id != parts[0]) {
                    continue;
                }

                const value = data[key];

                bindStore(store, parts[1], value);
            } else if (store.$id == key) {
                for (var k in data[key]) {
                    bindStore(store, k, data[key][k]);
                }
            }
        }
    });
};

export const useGenerateUuid = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );
};

/**
 * Add loading progress on given element
 */
export const useLazyClick = async (e, callback) => {
    let el = e.nodeName ? e : e.target;

    //If clicked element is not button, try to find closest button
    if (['BUTTON'].includes(el.nodeName) == false) {
        el = el.closest('button, .icon-btn') || el;
    }

    if (el.loading) {
        return;
    }

    el.loading = true;

    el.setAttribute('disabled', true);
    el.setAttribute('lazy-click-loading', true);

    try {
        if (callback) {
            await callback(e);
        }
    } catch (e) {
        console.error(e);
    }

    el.removeAttribute('disabled');
    el.removeAttribute('lazy-click-loading');

    el.loading = false;
};

export const useSleep = (delay) =>
    new Promise((resolve) => setTimeout(resolve, delay));

export const useKeyboardDelayMs = 300;

export const useWaitTillKeyboardClose = async () => {
    if (useAppStore().keyboard) {
        await useSleep(useKeyboardDelayMs);
    }
};

export const useWaitTillBoot = async () => {
    while (useAppStore().booted === false) {
        await useSleep(1000);
    }
};

export const useAutoFocusInput = (wrapper, delayMs, selector) => {
    delayMs = _.isNil(delayMs) ? 50 : delayMs;

    setTimeout(() => {
        const el = wrapper?.$el || wrapper;

        let focusInput = el?.querySelector(selector || '[autofocus]');

        if (focusInput) {
            focusInput.focus();
        }
    }, delayMs);
};

export const useBackendEnv = (key, defaultValue = null) => {
    const backendEnv = useAppStore().backendEnv;

    if (key in (backendEnv || {})) {
        return backendEnv[key];
    }

    return defaultValue;
};

export const useCalculateDistanceInMeters = (coord1, coord2) => {
    const R = 6371e3; // Radius of the Earth in meters
    const φ1 = (coord1.lat * Math.PI) / 180; // φ, λ in radians
    const φ2 = (coord2.lat * Math.PI) / 180;
    const Δφ = ((coord2.lat - coord1.lat) * Math.PI) / 180;
    const Δλ = ((coord2.lng - coord1.lng) * Math.PI) / 180;

    const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in meters
};

export const useDateFormatted = (d, time = false, format) => {
    d = moment(d);

    let result = d.format(format || 'DD.MM.Y');

    if (d.isSame(moment(), 'day')) {
        result = __('Dnes');
    } else if (d.isSame(moment().add(1, 'day'), 'day')) {
        result = __('Zajtra');
    } else if (d.isSame(moment().add(-1, 'day'), 'day')) {
        result = __('Včera');
    }

    if (time) {
        return result + ' ' + d.format('HH:mm');
    }

    return result;
};

export const useHourValuesForToday = (date, minutesFromNow = 60) => {
    const from =
        date && moment(date).isSame(moment(), 'day')
            ? moment().add(minutesFromNow, 'minutes').hour()
            : 0;
    let hours = [];

    for (var i = from; i <= 23; i++) {
        hours.push(i);
    }

    return hours.join(',');
};

export const useMultiValuesFormater = (
    items,
    selectedIds,
    max = 1,
    attr,
    text = true
) => {
    // prettier-ignore
    let txt = selectedIds.slice(0, max).map(id => {
        let row = _.find(items, { id: parseInt(id) });

        return row ? row[attr] : null;
    }).filter(i => i).join(', ');

    // prettier-ignore
    if (selectedIds.length > max) {
        const c = selectedIds.length - max;

        txt += ' (+' + (text ? n__('%d ďalší', '%d ďalšie', c, c) : c) + ')';
    }

    return txt;
};

export const useIsMe = (user) => {
    let id = typeof user == 'number' ? user : user?.id;

    return id && id == useAuthStore().user?.id ? true : false;
};

export const useIsUpToDate = (time, oncePerHours) => {
    return time && moment().diff(moment(time), 'hours') < parseInt(oncePerHours)
        ? true
        : false;
};

export const useGoToFeed = async () => {
    await useRouter().push({ name: 'app-feed' });
};

export const useSortTermines = (termines, absoluteDate = false) => {
    termines = termines || [];

    //prettier-ignore
    termines = _.orderBy(termines, [
        //Sort by canceled to the end
        (item) => item.canceled_at ? 1 : 0,

        //Sort by past to the end
        (item) => isFutureTermine(item) === false ? 1 : 0,

        //Sort by date ASC
        (item) => {
            if ( absoluteDate ){
                return moment().diff(moment(item.date), 'seconds');
            }

            return item.date;
        }
    ], [ 'asc', 'asc', 'asc']);

    return termines;
};

export const useGetDefaultTermine = (termines) => {
    return useSortTermines(termines || [])[0];
};

export const useOnConnected = (callback) => {
    let _watch;

    onIonViewWillEnter(() => {
        _watch = watch(
            () => useAppStore().connected,
            (state) => {
                nextTick(() => {
                    if (state) {
                        callback();
                    }
                });
            }
        );
    });

    onIonViewWillLeave(() => {
        if (_watch) {
            _watch();
        }
    });
};

export const useEmitter = () => emitter;

export const useFuseOptions = () => ({ threshold: 0.4 });

export const useActualUser = (_user) => {
    let user = useAuthStore().user;

    if (user?.id == _user?.id && _user?.id) {
        // Replace with local thumbnail
        _user = Object.assign(_user, { thumbnail: user.thumbnail });
    }

    return _user;
};

export const useOtherSportModal = (idOrIds) => {
    const ids = _.castArray(idOrIds);

    if (ids.includes(useBackendEnv('SPORT_OTHERS_ID')) == false) {
        return;
    }

    nextTick(() => {
        setTimeout(() => {
            useOpenModal('inquiry', {
                title: __('Žiadosť o nový šport'),
                message: __('Rád/a by som v aplikácii uvítal/a nový šport:'),
            });
        }, 300);
    });
};

//dark|light|auth
export const useSetAppBackgroundStyle = (type) => {
    if (!isPlatform('android') || useIsDesktop()) {
        return;
    }

    //Set topbar to same color as background of the app
    if (type == 'dark') {
        StatusBar.setBackgroundColor({ color: '#020310' });
        StatusBar.setStyle({ style: Style.Dark });
    } else if (type == 'light') {
        StatusBar.setBackgroundColor({ color: '#ffffff' });
        StatusBar.setStyle({ style: Style.Light });
    } else if (type == 'auth') {
        StatusBar.setBackgroundColor({ color: '#013544' });
        StatusBar.setStyle({ style: Style.Dark });
    }
};

export const useFromJsonToFormData = (data) => {
    var payload = new FormData();

    for (var k in data) {
        if (_.isNil(data[k])) {
            continue;
        }

        payload.append(k, data[k]);
    }

    return payload;
};

export const useFirstName = (name) => {
    return ((name || '') + '').split(' ')[0];
};

//Fix for android phones which have OK on keyboard and are submitting the form.
export const useEnterFieldEvent = (e) => {
    e.preventDefault();

    e.target?.blur();
};

export const useDefaultEventImage = () => {
    return '/images/placeholders/event.png';
};

export const useHasAccountPosition = (user) => {
    if (useBackendEnv('ACCOUNT_POSITIONS') == false) {
        return false;
    }

    return ['player'].includes(user.account_type);
};

export const groupEventTerminesByDate = (events, filter, order = 'asc') => {
    const matches = [];

    events.forEach((event) => {
        event.termines.forEach((termine) => {
            if (filter && filter(termine, event) === false) {
                return;
            }

            matches.push({
                event,
                termine,
            });
        });
    });

    return _.groupBy(
        _.orderBy(matches, (match) => match.termine.date, order),
        (match) =>
            match.termine.date
                ? moment(match.termine.date).format('Y-MM-DD')
                : 0
    );
};

export const flattenEventTermines = (events, filter, order) => {
    return _.flatten(
        Object.values(groupEventTerminesByDate(events, filter, order))
    );
};

export const useWithAttendees = async (termine, callback) => {
    try {
        // prettier-ignore
        let { data } = await useAxios().$getOnline(
            '/api/events/' + termine.event_id + '/termines/'+termine.id+'/attendees'
        );

        nextTick(() => {
            callback(data.attendees);
        });
    } catch (e) {
        useAutoAjaxResponse(e);

        console.error(e);
    }
};

export const useIsEventApproved = (event) => {
    //There is no accepting of players in this event
    return event.must_accept === false || useIsMe(event.user);
};

export const useObjectToFormData = (obj, rootName, ignoreList) => {
    var formData = new FormData();

    function appendFormData(data, root) {
        root = root || '';

        if (data instanceof File) {
            formData.append(root, data);
        } else if (Array.isArray(data)) {
            if (data.length == 0) {
                formData.append(root, '');
            } else {
                for (var i = 0; i < data.length; i++) {
                    appendFormData(data[i], root + '[' + i + ']');
                }
            }
        } else if (typeof data === 'object' && data) {
            for (var key in data) {
                if (data.hasOwnProperty(key)) {
                    if (root === '') {
                        appendFormData(data[key], key);
                    } else {
                        appendFormData(data[key], root + '[' + key + ']');
                    }
                }
            }
        } else {
            formData.append(root, _.isNil(data) ? '' : data);
        }
    }

    appendFormData(obj, rootName);

    return formData;
};

export const scrollToCenterPosition = async (current, wrapper) => {
    //Wait till elements ready
    while ((current()?.offsetLeft || 0) <= 0) {
        await useSleep(15);
    }

    let halfScreen = window.innerWidth / 2,
        width = current().offsetWidth,
        fromLeft = current().offsetLeft - halfScreen + width / 2;

    wrapper(current()).scrollTo(fromLeft, 0);
};

export const isFutureTermine = (termine) => {
    if (!termine) {
        return false;
    }

    return !termine.date || moment(termine.date) > moment();
};

export const eventLocationKey = (event) => {
    return (
        event.location_id || (event.lat || '') + '' + (event.lng || '') || false
    );
};

export const getFilenameFromUrl = (url) => {
    return url.split('/').pop();
};

export const buildUserPhotos = (user) => {
    let images = [];

    if (user?.thumbnail) {
        images.push({
            type: 'main',
            filename: getFilenameFromUrl(user.thumbnail),
            url: user.thumbnail,
        });
    }

    images = images.concat(
        (user?.gallery || []).map((photo) => ({
            type: 'gallery',
            filename: getFilenameFromUrl(photo.thumbnail),
            url: photo.thumbnail,
        }))
    );

    return images;
};

export const chatContent = async (callback) => {
    let wrapper = document.querySelector(
        '.ion-page:not(.ion-page-hidden) > ion-content.chat__content'
    );

    if (wrapper) {
        await callback(wrapper);
    }
};

export const useDefaultFilterRadius = () => {
    return (
        useAuthStore().user?.search_radius_km ||
        useBackendEnv('DEFAULT_FILTER_RADIUS_KM')
    );
};

export const useVibrate = async (type = 'Heavy') => {
    try {
        await Haptics.impact({ style: ImpactStyle[type] });
    } catch (e) {
        console.error(e);
    }
};

export const useOnAppRefresh = () => {
    // prettier-ignore
    let callers = [
        useRegisterNotifications,
        useCheckMatcherNotifications
    ];

    callers.forEach((caller) => {
        try {
            caller();
        } catch (e) {
            console.error(e);
        }
    });
};

export const useBlockUser = async (user) => {
    await new Promise((resolve) => {
        useOpenModal('modal-confirm', {
            title: __('Zablokovať používateľa'),
            // prettier-ignore
            message : __('Naozaj si prajete zablokovať vybraného hráča? Nezistí to, avšak navzajom sa už v aplikácii neuvidíte.'),
            success: async () => {
                try {
                    let response = await useAxios().$post(
                        `/api/accounts/${user.slug}/block`
                    );

                    useCloseModal('report-submitted');

                    useRouter().push({ name: 'app-feed' });

                    useAutoAjaxResponse(response);

                    useAppStore().refreshApp();
                } catch (e) {
                    console.error(e);
                }

                await resolve();
            },
            cancel: async () => {
                useCloseModal('modal-confirm');

                await resolve();
            },
        });
    });
};
