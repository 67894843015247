<template>
    <div class="filter" :class="{ '--noFilter': withFilter == false }">
        <div
            class="filter__header"
            v-if="
                withTypes &&
                enabledEventTypes.length >
                    useBackendEnv('DASHBOARD_FILTER_TABS_MINIMUM')
            "
        >
            <div class="filter__tabs">
                <ion-segment v-model="form.type">
                    <ion-segment-button
                        :value="typeKey"
                        v-for="(type, typeKey) in eventStore.types"
                        :disabled="
                            eventStore.isEventTypeEnabled(typeKey) === false
                        "
                    >
                        <ion-label>{{ type.texts.plural }}</ion-label>
                    </ion-segment-button>
                </ion-segment>
            </div>
        </div>

        <div class="filter__form" v-if="withFilter">
            <div class="filter__form-row">
                <div
                    v-if="eventStore.types[form.type]?.sport"
                    class="filter__form-select"
                    @click="useOpenModal('filter-sports')"
                >
                    <label>
                        {{ __('Aký šport si chcete zahrať') }}
                    </label>
                    <!-- prettier-ignore -->
                    <strong>
                        {{ _.castArray(form.sport_ids||[]).length ? useMultiValuesFormater(sportStore.sportsSorted, _.castArray(form.sport_ids), 3, 'name') : __('Vyberte športy') }}
                        </strong>
                    <ion-icon
                        :icon="chevronDown"
                        class="filter__form-select-arrow"
                    />
                </div>

                <div
                    v-if="eventStore.types[form.type]?.service"
                    class="filter__form-select"
                    @click="useOpenModal('filter-services')"
                >
                    <label>
                        {{ __('Vyberte typ služby') }}
                    </label>
                    <!-- prettier-ignore -->
                    <strong>
                            {{ _.castArray(form.service_ids||[]).length ? useMultiValuesFormater(sportStore.services, _.castArray(form.service_ids), 3, 'name') : __('Vyberte služby') }}
                        </strong>
                    <ion-icon
                        :icon="chevronDown"
                        class="filter__form-select-arrow"
                    />
                </div>
            </div>

            <div class="filter__form-row">
                <div
                    class="filter__form-select"
                    @click="useOpenModal('filter-date')"
                >
                    <label>{{ __('Dátum') }}</label>
                    <strong>
                        {{
                            form.date
                                ? useDateFormatted(form.date)
                                : __('Kedykoľvek')
                        }}
                    </strong>
                    <ion-icon
                        :icon="chevronDown"
                        class="filter__form-select-arrow"
                    />
                </div>

                <div
                    class="filter__form-select"
                    @click="useOpenModal('filter-time')"
                >
                    <label>{{ __('Čas') }}</label>
                    <strong>
                        {{
                            form.time
                                ? moment(form.time).format('HH:mm')
                                : __('Kedykoľvek')
                        }}
                    </strong>
                    <ion-icon
                        :icon="chevronDown"
                        class="filter__form-select-arrow"
                    />
                </div>
            </div>

            <div class="filter__form-row">
                <div
                    class="filter__form-select"
                    @click="useOpenModal('filter-location')"
                >
                    <label>{{ __('Lokalita') }}</label>
                    <!-- prettier-ignore -->
                    <strong>
                            {{ form.location_ids.length ? useMultiValuesFormater(filterStore.locationsWithMyLocation, form.location_ids, 1, 'name', false) : __('Vyberte') }}
                        </strong>
                    <ion-icon
                        :icon="chevronDown"
                        class="filter__form-select-arrow"
                    />
                </div>

                <div
                    class="filter__form-select"
                    @click="
                        form.location_ids.length == 0
                            ? useShowToast(__('Najprv zvoľte lokalitu.'))
                            : useOpenModal('filter-distance')
                    "
                >
                    <label>{{ __('Vzdialenosť') }}</label>

                    <!-- prettier-ignore -->
                    <strong>
                        {{
                            form.location_ids.length
                                ? __('Okolie %dkm', form.radius || useDefaultFilterRadius())
                                : '-'
                        }}
                    </strong>
                    <ion-icon
                        :icon="chevronDown"
                        class="filter__form-select-arrow"
                    />
                </div>
            </div>
        </div>

        <div
            class="filter__actions"
            :class="{ '--noFilter': withFilter == false }"
        >
            <!-- prettier-ignore -->
            <FormButton
                @click="emit('search')"
                visual="primary"
                full
                class="filter__actions-btn"
            >
                <span class="icon --loupe"></span>

                <span v-if="form.type == 'event'">{{ __('Vyhľadať partičky') }}</span>
                <span v-else-if="form.type == 'training'">{{ __('Vyhľadať trénera') }}</span>
                <span v-else-if="form.type == 'service'">{{ __('Vyhľadať služby') }}</span>
            </FormButton>

            <slot name="actions" :form="form" />
        </div>
    </div>
</template>

<script setup>
import moment from 'moment';
import _ from 'lodash';
import { searchOutline, walkOutline, chevronDown } from 'ionicons/icons';

const emit = defineEmits(['search']);

const props = defineProps({
    withTypes: {
        default: false,
    },
    withFilter: {
        default: true,
    },
});

const eventStore = useEventStore();
const sportStore = useSportStore();
const filterStore = useFilterStore();
const locationStore = useLocationStore();

const form = computed(() => filterStore.form);
const user = computed(() => useAuthStore().user);

const timeSelected = computed(() => {
    const t = moment(form.value.time || undefined);

    return moment(
        (form.value.date ? moment(form.value.date) : moment()).format(
            'Y-MM-DD'
        ) +
            ' ' +
            t.format('HH:mm:ss')
    );
});

const enabledEventTypes = computed(() => {
    return Object.keys(eventStore.types).filter((typeKey) =>
        eventStore.isEventTypeEnabled(typeKey)
    );
});

onMounted(() => {
    //If my position is chosen, but
    if (filterStore.hasCurrentLocation && !locationStore.position) {
        filterStore.flushMyLocationFromFilter();
    }
});

watch(
    () => form.value.sport_ids,
    (ids) => {
        useOtherSportModal(ids);
    }
);

watch(
    timeSelected,
    (time) => {
        // When selected time is in past, reset filter dates.
        if (form.value.time && time < moment()) {
            filterStore.resetTime();
        }
    },
    { immediate: true }
);
</script>

<style lang="scss" scoped>
:deep(:not(.--noFilter) .filter__actions-btn) {
    position: relative;
    height: 5rem;

    .icon {
        position: absolute;
        left: 1.8rem;
        width: 3rem;
        height: 3rem;

        &.--loupe {
            background: url($basePath + 'icons/loupe.svg') no-repeat center;
        }

        &.--event {
            background: url($basePath + 'icons/event.svg') no-repeat center;
        }
    }
}
</style>
