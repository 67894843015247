<template>
    <div class="form-group" :class="$attrs.class">
        <ion-textarea
            v-bind="{ ...$attrs, class: '' }"
            :rows="5"
            v-model="model"
            :class="{ '--light': light }"
            class="form-textarea"
        ></ion-textarea>
    </div>
</template>

<script setup>
defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    light: {
        type: Boolean,
        default: false,
    },
    modelValue: String,
});

const model = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});

const emit = defineEmits(['update:modelValue']);
</script>

<style lang="scss" scoped>
.form-group {
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
}

ion-textarea.form-textarea {
    border: 1px solid #dddddd;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    transition: all 0.2s;
    color: #000;
    --padding-start: 1.2rem;
    --padding-end: 1.2rem;
    --placeholder-color: rgba(0, 0, 0, 0.5);
    --placeholder-opacity: 1;
    font-family: var(--ion-font-family);

    &.ion-invalid {
        border-color: var(--ion-color-danger);

        &.label-floating.has-value {
            :deep(.textarea-wrapper) {
                .label-text {
                    color: var(--ion-color-danger);
                }
            }
        }
    }

    &.has-focus {
        border-color: #000000;
    }

    &.--light {
        color: #fff;
        border-color: #2f333a;
        --placeholder-color: rgba(255, 255, 255, 0.5);

        &.has-focus {
            border-color: #fff;
        }
    }

    // counter adjustments
    :deep(.textarea-bottom) {
        padding-right: 0.5rem;
        padding-bottom: 0.5rem;
        border: 0;
    }
}
</style>

<style lang="scss">
.input-wrapper {
    max-height: 100%;
}
</style>
