<template>
    <DynamicModal
        v-slot="{ $cleanAttrs }"
        :name="__('Zmena hesla')"
        :modal="modal"
        :canSave="false"
        :full="true"
    >
        <form
            v-autoAjax
            method="post"
            action="/api/profile/password"
            @success="success"
            @loading="loading = $event"
        >
            <div class="form-item mb-2">
                <FormLabel>
                    {{ __('Nové heslo') }}
                </FormLabel>
                <FormText type="password" name="password" />
            </div>

            <div class="form-item">
                <FormLabel>
                    {{ __('Zopakujte heslo') }}
                </FormLabel>
                <FormText type="password" name="password_confirmation" />
            </div>

            <FormButton type="submit" full class="mt-2" :loading="loading">
                {{ __('Zmeniť heslo') }}
            </FormButton>
        </form>
    </DynamicModal>
</template>

<script setup>
const props = defineProps({
    modal: {
        default: 'auth-password-modal',
    },
});

const loading = ref(false);

const success = async (response) => {
    useAutoAjaxResponse(response);

    useCloseModal(props.modal);
};
</script>
