<template>
    <AppHeading type="h1" class="text-center mb-2">
        {{
            authStore.loggedInIncompletely
                ? __('Dokončiť registráciu')
                : __('Registrácia')
        }}
    </AppHeading>

    <form
        v-autoAjax
        method="post"
        action="/api/auth/register/otp"
        @success="success"
        @error="error"
        @loading="loading = $event"
    >
        <FormText
            v-if="accountTypeVisible"
            rounded
            name="account_type"
            :modelValue="authStore.account_types[form.account_type]?.name"
            :label="__('Typ účtu') + ' *'"
            readonly
            @click="useOpenModal('register-account-type')"
        />

        <FormText
            v-show="authStore.loggedInIncompletely == false || !form.email"
            rounded
            name="email"
            v-model="form.email"
            :label="__('E-mail') + ' *'"
        />

        <FormText
            rounded
            name="phone"
            v-model="form.phone"
            :label="__('Telefónne číslo') + ' *'"
        />

        <template v-if="!hasSocial && canShowPassword">
            <FormText
                rounded
                name="password"
                v-model="form.password"
                type="password"
                :label="__('Heslo') + ' *'"
                autocomplete="new-password"
            />
            <FormText
                rounded
                name="password_confirmation"
                type="password"
                v-model="form.password_confirmation"
                :label="__('Zopakovať heslo') + ' *'"
                autocomplete="new-password"
            />
        </template>

        <FormCheckbox
            class="mt-2"
            name="license_terms"
            v-model="form.license_terms"
            ref="licenseTermsInput"
        >
            <!-- prettier-ignore -->
            {{ __('Súhlasim so všeobecnými') }}
            <span
                @click.stop.prevent="
                    useOpenModal('article-modal', {
                        slug: useBackendEnv('ARTICLE_TERMS_SLUG'),
                        callback: () => {
                            licenseTermsInput.$el.querySelector(
                                'ion-checkbox'
                            ).checked = true;
                        },
                    })
                "
                class="--terms"
            >
                {{ __('obchodnými podmienkami') }}
            </span>
        </FormCheckbox>

        <FormButton type="submit" full :loading="loading" class="mt-2">
            {{
                authStore.loggedInIncompletely
                    ? __('Pokračovať v registrácii')
                    : __('Registrovať sa')
            }}
        </FormButton>
    </form>

    <template v-if="hasSocial">
        <div class="my-2 text-center">
            <span class="divider">
                <i>{{ __('alebo') }}</i>
            </span>
        </div>

        <SocialAuth
            :continueText="__('Registrovať')"
            @logged="onPreRegister()"
        />
    </template>

    <div class="my-2 text-center" v-if="!authStore.loggedInIncompletely">
        <span class="link-prefix">{{ __('Máte účet?') }}</span>
        <a
            @click="
                modal
                    ? useOpenModalCloseCurrent('login-modal')
                    : router.push({ name: 'login' })
            "
            class="link"
        >
            <strong>{{ __('Prihláste sa') }}</strong>
        </a>
    </div>

    <!-- prettier-ignore -->
    <div class="my-2 text-center" v-if="eventStore.isEventTypeEnabled('training')">
        <a
            v-if="!accountTypeVisible"
            class="link"
            @click.prevent="
                form.account_type = 'trainer';
                accountTypeVisible = true;
            "
            v-html="__('Registrovať sa ako <strong>tréner / klub</strong>')"
        >
        </a>
    </div>

    <CheckboxModal
        modal="register-account-type"
        :autoClose="true"
        :name="__('Vyberte typ účtu')"
        type="radio"
        :options="authStore.account_types"
        v-model="form.account_type"
    />
</template>

<script setup>
import _ from 'lodash';
const router = useRouter();

const loading = ref(false);

const props = defineProps({
    modal: {},
});

const registerStore = useRegisterStore(),
    eventStore = useEventStore(),
    otpStore = useOtpStore(),
    authStore = useAuthStore();

const accountTypeVisible = ref(useBackendEnv('ACCOUNT_TYPES'));
const licenseTermsInput = ref();
const form = computed(() => useRegisterStore().credentials);
const hasSocial = computed(() => {
    return (
        !(form.value.email && form.value.phone) &&
        !authStore.loggedInIncompletely
    );
});

const canShowPassword = computed(() => {
    if (useBackendEnv('PASSWORD_AUTH') == false) {
        return false;
    }

    //On social buttons login, we can't ask for password
    //according to apple review policy.
    if (authStore.loggedInIncompletely) {
        return false;
    }

    return true;
});

const success = (response) => {
    useAutoAjaxResponse(response);

    //Close register modal, when contiuing on registration process.
    useCloseModal('register-modal');

    // prettier-ignore
    const steps = _.filter(useBackendEnv('ONBOARDING_STEPS'), { registration: true }).map((step) => step.name);

    registerStore.initialize(1, steps);

    // First registration route
    const route = {
        name:
            'registration-' +
            registerStore.steps[registerStore.currentStep - 1],
    };

    if (otpStore.verified) {
        useRouter().push(route);
    } else {
        otpStore.proceedToRoute({ next: route });
    }

    // For registration of new users
    if (authStore.loggedInIncompletely === false) {
        logEvent('registration_start');
    }
};

const error = (response) => {
    useThrowUnknownError();
};

const onPreRegister = () => {
    //Fill data from social auth provider
    if (authStore.loggedInIncompletely && authStore.user) {
        form.value.email = authStore.user.email;
        form.value.phone = authStore.user.phone;
    }
};
</script>

<style lang="scss" scoped>
.link {
    display: inline-block;
    font-size: 1.2rem;
    text-decoration: underline;
    color: #000;

    strong {
        font-weight: 600;
    }

    &:hover {
        text-decoration: none;
    }
}

.link-prefix {
    display: inline-block;
    font-size: 1.2rem;
    color: #000;
    margin-right: 0.4rem;
}

.divider {
    display: inline-block;
    font-size: 1.2rem;
    position: relative;
    padding: 0 5rem;

    i {
        font-style: normal;
        position: relative;
        z-index: 2;
        background: #fff;
        padding: 0 2rem;
    }

    &:before {
        content: '';
        width: 100%;
        left: 0;
        top: 50%;
        height: 1px;
        background: #979797;
        position: absolute;
        z-index: 1;
    }
}

.--terms {
    font-weight: 600;
}
</style>
