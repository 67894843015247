import _ from 'lodash';

export const openedModals = ref([]);

export const useOpenModal = (name, callback) => {
    return openedModals.value.push({ name, callback });
};

export const useIsModalOpen = (name) => {
    return useGetModal(name) ? true : false;
};

export const useGetModal = (name) => {
    return _.find(openedModals.value, { name });
};

export const useGetModalData = (name) => {
    return useGetModal(name)?.callback;
};

export const useCloseModal = async (name) => {
    await useWaitTillKeyboardClose();

    //Close last opaned modal if no name has been given
    name = name || openedModals.value[openedModals.value.length - 1]?.name;

    if (useIsModalOpen(name)) {
        _.remove(openedModals.value, { name });
    }
};

export const useOnModalChange = (modal, callback) => {
    watch(
        () => useIsModalOpen(modal),
        (state) => {
            callback(state);
        }
    );
};

export const useOnModalOpen = (modal, callback) => {
    useOnModalChange(modal, (state) => {
        state ? callback(useGetModalData(modal)) : null;
    });
};

export const useOnModalClose = (modal, callback) => {
    useOnModalChange(modal, (state) => {
        !state ? callback(useGetModalData(modal)) : null;
    });
};

export const useOpenModalCloseCurrent = function () {
    useCloseModal();

    const args = arguments;

    nextTick(() => {
        useOpenModal(...args);
    });
};
