<template>
    <TextModal
        :modal="modal"
        type="text"
        isLazy
        :canClear="true"
        :name="__('API URL')"
        :modelValue="apiUrl"
        @input="changeApiUrl"
    />
</template>

<script setup>
import _ from 'lodash';

const props = defineProps({
    modal: {
        default: 'app-environment',
    },
});

const appStore = useAppStore();
const authStore = useAuthStore();

const apiUrl = ref();

useOnModalOpen(props.modal, () => {
    prefillApiUrl();
});

const prefillApiUrl = () => {
    let url = appStore.api_url,
        envUrl = import.meta.env.VITE_APP_SERVER_URL;

    // Toggle between dev and prod
    if (appStore.isProdApi) {
        url = 'https://dev.scorentino.com';
    } else {
        url = envUrl;
    }

    apiUrl.value = url;
};

const showDebugMessage = async () => {
    // prettier-ignore
    useShowSuccessToast(useAppBaseUrl() + ' - '+JSON.stringify(usePlatformHeaders()));
};

const changeApiUrl = async ({ value, callback }) => {
    // No value has been set
    if (!value) {
        showDebugMessage();

        return;
    }

    value = _.trim(value, '/');

    // Logout user if is logged in
    if (authStore.loggedIn) {
        await authStore.logout();
    }

    try {
        await useAxios().get(value + '/api/bootstrap');

        appStore.api_url = value;

        await appStore.refreshApp();

        useShowSuccessToast(__('API URL bolo úspešne zmenené na %s', value));
    } catch (error) {
        console.log(error);

        useShowErrorToast(
            __('API URL nebolo možné zmeniť, keďže nie je funkčná.')
        );
    }

    callback?.();
};
</script>
