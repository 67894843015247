<template>
    <FormButton
        v-if="isPlatform('ios') && isPlatform('hybrid')"
        visual="apple"
        full
        @click="useLazyClick($event, signInWithApple)"
        class="mb-1"
    >
        {{ __('%s cez Apple', continueText || __('Pokračovať')) }}
    </FormButton>

    <FormButton
        visual="google"
        full
        @click="useLazyClick($event, signInWithGoogle)"
    >
        {{ __('%s cez Google', continueText || __('Pokračovať')) }}
    </FormButton>
</template>

<script setup>
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

defineProps({
    continueText: {},
});

const emit = defineEmits(['logged']);

const logIn = async (driver, params) => {
    try {
        var response = await useAxios().$postOnline(
            'api/auth/login/socialite/' + driver,
            params
        );

        useAutoAjaxResponse(response);

        useContinueAfterOnLogIn();

        emit('logged');
    } catch (e) {
        throw Error('Could not verify access token');
    }
};

const signInWithPlatform = async (platform, callerName, args, tokenHelper) => {
    try {
        const result = await FirebaseAuthentication[callerName](args);

        await logIn(platform, tokenHelper(result));
    } catch (e) {
        console.error('Login error:', e);

        // prettier-ignore
        useShowErrorToast(
            __('Prihlásenie cez %s sa nepodarilo. Skúste neskôr prosím.', platform)
        );
    }
};

const signInWithGoogle = async () => {
    logEvent('sign_in_with_google');

    await signInWithPlatform('google', 'signInWithGoogle', {}, (result) => ({
        token: result.credential.accessToken,
    }));
};

const signInWithApple = async () => {
    logEvent('sign_in_with_apple');

    await signInWithPlatform(
        'apple',
        'signInWithApple',
        {
            scopes: ['FULL_NAME', 'EMAIL'],
        },
        (result) => {
            const usernameData = Object.values(
                result.user?.providerData || {}
            ).filter((d) => d.displayName)[0];

            return {
                token: result.credential.idToken,
                username: usernameData?.displayName,
            };
        }
    );
};
</script>
