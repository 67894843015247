<template>
    <ModalWrapper
        :is-open="useIsModalOpen(modal)"
        class="full-modal"
        :step="eventStore.currentStep"
        :authorized="true"
    >
        <GameFormStep
            v-if="eventStore.currentStep == 1"
            :name="
                eventStore.typeText(
                    eventStore.form.type,
                    eventStore.form?.id ? 'title_update' : 'title_create'
                )
            "
        />
        <GameLocationStep v-if="eventStore.currentStep == 2" />
        <GameAcceptLocationStep
            v-if="eventStore.currentStep == 3"
            :name="__('Potvrdenie športoviska')"
        />
        <!-- <GameTermineStep
            v-if="eventStore.currentStep == 4"
            :name="__('Vyberte dátum a čas')"
        /> -->
        <GameInfoStep
            v-if="eventStore.currentStep == 4"
            :name="__('Doplňujúce informácie')"
        />
    </ModalWrapper>
</template>

<script setup>
const props = defineProps({
    modal: { default: 'create-event' },
});

const eventStore = useEventStore();

watch(
    () => eventStore.currentStep,
    (currentStep, previousStep) => {
        eventStore.previousStep = previousStep || 0;
    }
);

onMounted(() => {
    //Open create event modal, when step is already initialized, but has been closed
    if (eventStore.currentStep > 1) {
        useOpenModal(props.modal, { type: eventStore.form.type });
    }

    useOnModalOpen(props.modal, () => {
        const { event, termine, step } = useGetModalData(props.modal) || {};

        eventStore.initialize(4, step || 1);

        // If exact event has been added
        if (event) {
            eventStore.loadEvent(event, termine);
        }

        // Open event type
        else {
            //Set event type from modal param
            eventStore.form.type = useGetModalData(props.modal)?.type;
        }
    });
});
</script>
