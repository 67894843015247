<template>
    <EventStep
        class="p-0 h-100"
        :errors="getValidationError"
        :name="
            locationStore.loading
                ? __('Vyhľadávame športoviska...')
                : eventStore.isCustomLocation
                  ? __('Posúňte špendlikom')
                  : __('Vyberte športovisko')
        "
    >
        <div class="map-container">
            <div class="map-container__search">
                <!-- Do not use autofocus, because map is on halt causing keybord is on :autofocus="!!getValidationError" -->
                <FormText
                    v-model="query"
                    :placeholder="searchPlaceholder"
                    class="mb-0"
                    :clear-input="!loading && !eventStore.location"
                    :class="{
                        '--selected': eventStore.location ? true : false,
                    }"
                >
                    <ion-icon
                        slot="start"
                        :icon="locationOutline || pinOutline"
                        aria-hidden="true"
                        size="large"
                    ></ion-icon>

                    <div
                        slot="end"
                        class="end"
                        v-if="loading || eventStore.location"
                    >
                        <ion-spinner name="circular" v-if="loading" />

                        <strong
                            v-else-if="eventStore.location"
                            class="cursor-pointer"
                            @click="resetLocation()"
                        >
                            {{ __('Zrušiť') }}
                        </strong>
                    </div>
                </FormText>
                <div class="results" v-if="results.length">
                    <div
                        class="result"
                        v-for="result in results"
                        @click="choose(result)"
                    >
                        {{ result.name }}
                    </div>
                </div>
            </div>

            <MapBox :currentPosition="true" ref="map" />

            <img
                src="/images/pin.png"
                v-if="eventStore.isCustomLocation"
                class="pin"
            />

            <span
                class="map-container__custom-btn"
                @click="
                    eventStore.isCustomLocation
                        ? disableCustomLocation()
                        : enableCustomLocation()
                "
            >
                <ion-icon :icon="mapOutline"></ion-icon>
                {{
                    eventStore.isCustomLocation
                        ? __('Športoviska')
                        : __('Vlastná lokalita')
                }}
            </span>
        </div>

        <template v-slot:footer="{ goNext }" v-if="eventStore.isCustomLocation">
            <div class="d-flex gap-1">
                <FormButton
                    visual="secondary"
                    full
                    @click="disableCustomLocation()"
                >
                    {{ __('Zrušiť') }}
                </FormButton>
                <FormButton full @click="chooseCustomLocation(goNext)">
                    {{ __('Vybrať') }}
                </FormButton>
            </div>
        </template>
    </EventStep>
</template>

<script setup>
import _ from 'lodash';
import { mapOutline, locationOutline, pinOutline } from 'ionicons/icons';
import { toLonLat } from 'ol/proj';

const autoSelectOnSearch = false; //We can decide if this should work like this
const sportStore = useSportStore();
const locationStore = useLocationStore();
const eventStore = useEventStore();
const map = ref();
const query = ref('');
const loading = ref(false);
const results = ref([]);

const nearbyLocations = ref([]);
const nearbyPosition = ref();
const nearbyRadiusInMeters = ref();

onMounted(async () => {
    onMapBoot();
});

let searchDebounced;
const fetchResultsDebounced = () => {
    if (
        //Do not search same as currently selected
        eventStore.location?.name == query.value ||
        //Immediately reset results when needed
        (query.value || '').length <= 2
    ) {
        results.value = [];
        return;
    }

    //Immediatelly start loading
    loading.value = true;

    if (!searchDebounced) {
        searchDebounced = _.debounce(() => fetchResults(), 750);
    }

    searchDebounced();
};

watch(
    query,
    (location) => {
        fetchResultsDebounced();
    },
    { immediate: true }
);

watch(
    () => eventStore.location,
    (location) => {
        // query.value = location ? location.name : null;
    },
    { immediate: true }
);

const onMapBoot = async () => {
    // When custom location is selected
    if (eventStore.isCustomLocation && eventStore.customCoordinates) {
        map.value.zoomToMarker(eventStore.customCoordinates);
    }

    // When location is selected already
    else if (eventStore.location) {
        displayOnMap(eventStore.location, true);

        map.value.zoomToMarker(eventStore.location);
    }

    //Display also when data changes
    watch(
        () => {
            let persistent = locationStore.isNearbyUpToDate
                ? locationStore.nearbyLocations
                : [];

            return _.uniqBy(nearbyLocations.value.concat(persistent), 'id');
        },
        (locations) => {
            displayNearLocations(locations);
        },
        { immediate: true }
    );

    // Load locations
    loadMoreLocationsOnMapMove();

    // Fetch nearby positions if needed
    locationStore.fetchNearbyIfMissing();
};

const fetchResults = async () => {
    loading.value = true;

    try {
        useThrowConnectionAlert();

        const response = await useAxios().$get(
            '/api/locations/search?query=' + query.value
        );
        results.value = response.data.results;
    } catch (e) {
        console.error(e);
    }

    loading.value = false;
};

const choose = (location) => {
    results.value = [];

    //We can toggle this, code will adjust
    if (autoSelectOnSearch) {
        eventStore.location = location;
    }

    const isActive = eventStore.location?.id == location.id;

    query.value = null;

    displayOnMap(location, isActive);
    map.value.zoomToMarker(location);
};

const displayOnMap = async (location, active = false) => {
    map.value.createMarker({
        ..._.pick(location, ['id', 'name', 'lat', 'lng']),
        active,
        image: location.thumbnail,
        class: '--location',
        click: (marker) => {
            //On marker click, deselecte accepted location checkbox
            eventStore.form.accepted = false;

            //Toggle click
            eventStore.location =
                eventStore.location?.id == location.id ? null : location;

            if (!eventStore.location) {
                map.value.removeActiveMarkers();
            }
        },
    });
};

const displayNearLocations = async (locations) => {
    Object.values(
        _.omitBy(locations, {
            id: eventStore.location?.id,
        })
    ).forEach((location) => {
        displayOnMap(location);
    });
};

const resetLocation = () => {
    eventStore.location = null;

    map.value.removeActiveMarkers();
};

const enableCustomLocation = () => {
    eventStore.location = null;
    query.value = null;
    results.value = [];

    eventStore.isCustomLocation = true;

    map.value.removeActiveMarkers();
};

const disableCustomLocation = () => {
    eventStore.isCustomLocation = false;
    eventStore.customCoordinates = null;
};

const chooseCustomLocation = (goNext) => {
    const position = toLonLat(map.value.mapInstance.getView().getCenter());

    eventStore.customCoordinates = {
        lat: position[1],
        lng: position[0],
    };

    goNext(2);
};

const loadMoreLocationsOnMapMove = () => {
    map.value.mapInstance.on('moveend', async (e) => {
        const nbp = nearbyPosition.value || locationStore.position;
        const nbr =
            nearbyRadiusInMeters.value || locationStore.nearbyRadiusInMeters;

        //If no nearby position has been set yet.
        if (!nbp || !nbr) {
            return;
        }

        let currentPosition = toLonLat(e.map.getView().getCenter());
        currentPosition = { lat: currentPosition[1], lng: currentPosition[0] };

        const distance = useCalculateDistanceInMeters(currentPosition, nbp);

        // When moved distance is bigger then minimal limit
        if (distance > nbr) {
            try {
                let response = await locationStore.fetchNearbyLocations(
                    currentPosition,
                    false
                );

                nearbyLocations.value = response.nearbyLocations;
                nearbyPosition.value = response.nearbyPosition;
                nearbyRadiusInMeters.value = response.nearbyRadiusInMeters;
            } catch (e) {
                console.error(e);
            }
        }
    });
};

const getValidationError = computed(() => {
    if (!eventStore.location && !eventStore.customCoordinates) {
        return __('Vyberte športovisko alebo vlastnú lokalitu.');
    }

    return false;
});

const searchPlaceholder = computed(() => {
    if (eventStore.location?.name) {
        return eventStore.location?.name;
    }

    return eventStore.isCustomLocation
        ? __('Nájdite polohu')
        : __('Vyhľadať športovisko');
});
</script>

<style scoped>
.map-container {
    height: 100%;
    background: #eee;
    position: relative;
}

.map-container__search {
    position: absolute;
    top: 2rem;
    left: 2rem;
    right: 2rem;
    z-index: 2;

    strong {
        font-size: 1.2rem;
        font-weight: 600;
    }

    &:deep(.--selected ion-input) .input-wrapper {
        --placeholder-color: black;
        font-weight: 500;
    }

    &:deep(ion-input.form-text) .input-wrapper {
        background: #fff;
        border-color: #000000;
    }
}

.map-container__custom-btn {
    position: absolute;
    bottom: 2.5rem;
    left: 2rem;
    display: inline-flex;
    align-items: center;
    gap: 0.7rem;
    font-weight: 600;
    font-size: 1.2rem;
    color: #011e26;
    line-height: 1;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 5rem;
    padding: 1rem 1.5rem 1rem 1rem;
    cursor: pointer;

    ion-icon {
        font-size: 1.6rem;
    }
}

.results {
    background: white;
    padding: calc(0.5rem + 0.5rem) 0 0.5rem;
    position: relative;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    border: 1px solid #000000;
    border-top: none;
    margin-top: -0.5rem;
    max-height: 41rem;
    overflow: auto;

    .result {
        padding: 1rem 2rem;
        font-size: 1.2rem;
        cursor: pointer;
    }
}

.pin {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    height: 6rem;
    pointer-events: none;
    transform: translateY(-50%);
}
</style>
