<template>
    <aside class="desktop-right-sidebar"></aside>
</template>

<script setup></script>

<style lang="scss" scoped>
.desktop-right-sidebar {
    position: fixed;
    top: 8rem;
    bottom: 0;
    right: 0;
    width: calc((100vw - 70rem) / 2);
    border-left: 1px solid var(--desktop-border-color);
    z-index: 100;
    display: none;

    @include more-than(md) {
        display: block;

        body:has(.--auth-layout) & {
            display: none !important;
        }
    }
}
</style>
