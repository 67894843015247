import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

export const useAuthStore = defineStore('auth', {
    persist: true,

    state() {
        return {
            token: null,
            driver: null,
            user: null,
            device_tokens: [],

            //Other options
            genders: [],
            account_types: [],
        };
    },

    actions: {
        /*
         * Logout user
         */
        async logout() {
            // Logout from backend
            try {
                await useAxios().$post('/api/auth/logout');
            } catch (e){
                console.error(e);
            }

            await this.flushData();

            useRouter().push('/');

            useUnregisterNotifications();

            try {
                await FirebaseAuthentication.signOut();
            } catch (e) {
                console.error(e);
            }
        },
        async flushData() {
            this.token = null;

            this.user = null;

            useEventStore().events = [];
        },
        /*
         * On successfull login
         */
        continue() {
            useContinueAfterOnLogIn();
        },
        async setDeviceId(token) {
            const tokens = this.device_tokens || [];

            if (tokens.includes(token) === false) {
                console.log('Saving device id:', token);

                this.device_tokens = tokens.concat([token]);

                await useAxios().$postAsync('/api/profile', {
                    _silent: true,
                    device_id: token,
                });
            }
        },
    },

    getters: {
        loggedIn() {
            return this.user && this.user.registration_completed == true
                ? true
                : false;
        },
        loggedInIncompletely() {
            return this.user && !this.user.registration_completed
                ? true
                : false;
        },
        accountType() {
            return this.user?.account_type;
        },
        isType: (state) => {
            return (type) => {
                return state.user.account_type == type;
            };
        },
        userProfile() {
            return {
                ...this.user,
                events_count: useEventStore().myEvents.length,
            };
        },
    },
});
