<template>
    <Loading v-if="loading" :black="true" />

    <div v-else class="article__wrapper">
        <div v-if="article">
            <h1 v-if="title && article.name">{{ article.name }}</h1>

            <div v-html="article.content"></div>
        </div>
        <EmptyResults
            v-else
            :text="__('Tento článok sa nam nepodarilo nájsť.')"
        />
    </div>
</template>

<script setup>
const props = defineProps({
    slug: {},
    title: { default: false },
});

const article = defineModel();
const loading = ref(false);

onMounted(() => {
    if (props.slug) {
        fetchArticle(props.slug);
    }
});

const fetchArticle = async (slug) => {
    try {
        let response = await useAxios()
            .loading(loading)
            .$get('/api/articles/' + slug);

        article.value = response.model.article;
    } catch (e) {
        useShowErrorToast(__('Tento článok sa nam nepodarilo nájsť.'));
    }
};
</script>

<style lang="scss" scoped>
.article__wrapper {
    &:deep() {
        font-size: 1.4rem;
        font-weight: 400;

        h1 {
            font-size: 2.4rem;
            margin-bottom: 3rem;
        }

        h1:first-child,
        h2:first-child {
            margin-top: 0;
            padding-top: 0;
        }
    }
}
</style>
