import { default as _91slug_93xRgg15z0CtMeta } from "/var/www/scorentino.com/data/sub/app/pages/app/article/[slug].vue?macro=true";
import { default as index6WzoAJzUbGMeta } from "/var/www/scorentino.com/data/sub/app/pages/app/chat/index.vue?macro=true";
import { default as _91slug_939MsoZjKh1uMeta } from "/var/www/scorentino.com/data/sub/app/pages/app/event/[slug].vue?macro=true";
import { default as index01NwFvFdfRMeta } from "/var/www/scorentino.com/data/sub/app/pages/app/feed/index.vue?macro=true";
import { default as searchNAj4EscbCyMeta } from "/var/www/scorentino.com/data/sub/app/pages/app/feed/search.vue?macro=true";
import { default as listingN0EwW2MjlVMeta } from "/var/www/scorentino.com/data/sub/app/pages/app/manager/listing.vue?macro=true";
import { default as trainer_45listinglxCeT0Y9c1Meta } from "/var/www/scorentino.com/data/sub/app/pages/app/manager/trainer-listing.vue?macro=true";
import { default as indexCgr6xqDETyMeta } from "/var/www/scorentino.com/data/sub/app/pages/app/matches/index.vue?macro=true";
import { default as indexXG6TfhUi18Meta } from "/var/www/scorentino.com/data/sub/app/pages/app/notifications/index.vue?macro=true";
import { default as _91slug_93LzqpRY3OgWMeta } from "/var/www/scorentino.com/data/sub/app/pages/app/places/[slug].vue?macro=true";
import { default as indexOjYLFsyHdmMeta } from "/var/www/scorentino.com/data/sub/app/pages/app/players/index.vue?macro=true";
import { default as accounteeOawEBXPFMeta } from "/var/www/scorentino.com/data/sub/app/pages/app/profile/account.vue?macro=true";
import { default as editSM9HzzEqw2Meta } from "/var/www/scorentino.com/data/sub/app/pages/app/profile/edit.vue?macro=true";
import { default as settingsVgBDgfzLy8Meta } from "/var/www/scorentino.com/data/sub/app/pages/app/profile/settings.vue?macro=true";
import { default as trainerULzcfWtPIQMeta } from "/var/www/scorentino.com/data/sub/app/pages/app/profile/trainer.vue?macro=true";
import { default as indexAX9UqzvnH5Meta } from "/var/www/scorentino.com/data/sub/app/pages/app/search/index.vue?macro=true";
import { default as appzNRFA7BqCAMeta } from "/var/www/scorentino.com/data/sub/app/pages/app.vue?macro=true";
import { default as codeoHlkxdZPx0Meta } from "/var/www/scorentino.com/data/sub/app/pages/code.vue?macro=true";
import { default as forgot_45passwordC6jqOJ27UwMeta } from "/var/www/scorentino.com/data/sub/app/pages/forgot-password.vue?macro=true";
import { default as login52FGsDz5HtMeta } from "/var/www/scorentino.com/data/sub/app/pages/login.vue?macro=true";
import { default as aboutRdEEJ0Gl06Meta } from "/var/www/scorentino.com/data/sub/app/pages/registration/about.vue?macro=true";
import { default as birthdate6MxurV7qygMeta } from "/var/www/scorentino.com/data/sub/app/pages/registration/birthdate.vue?macro=true";
import { default as genderNyBB3uubyJMeta } from "/var/www/scorentino.com/data/sub/app/pages/registration/gender.vue?macro=true";
import { default as photos0GYI9iOeOPMeta } from "/var/www/scorentino.com/data/sub/app/pages/registration/photos.vue?macro=true";
import { default as registrationOzsMVYNNafMeta } from "/var/www/scorentino.com/data/sub/app/pages/registration/registration.vue?macro=true";
import { default as sportscuaZBN1PGhMeta } from "/var/www/scorentino.com/data/sub/app/pages/registration/sports.vue?macro=true";
import { default as userw7AkybbLJdMeta } from "/var/www/scorentino.com/data/sub/app/pages/registration/user.vue?macro=true";
export default [
  {
    name: "app",
    path: "/app",
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: "app-article-slug",
    path: "article/:slug()",
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: "app-chat",
    path: "chat",
    meta: index6WzoAJzUbGMeta || {},
    alias: ["/app/event/:slug/chat"],
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/chat/index.vue").then(m => m.default || m)
  },
  {
    name: "app-event-slug",
    path: "event/:slug()",
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/event/[slug].vue").then(m => m.default || m)
  },
  {
    name: "app-feed",
    path: "feed",
    meta: index01NwFvFdfRMeta || {},
    alias: index01NwFvFdfRMeta?.alias || [],
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/feed/index.vue").then(m => m.default || m)
  },
  {
    name: "app-feed-search",
    path: "feed/search",
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/feed/search.vue").then(m => m.default || m)
  },
  {
    name: "app-manager-listing",
    path: "manager/listing",
    meta: listingN0EwW2MjlVMeta || {},
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/manager/listing.vue").then(m => m.default || m)
  },
  {
    name: "app-manager-trainer-listing",
    path: "manager/trainer-listing",
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/manager/trainer-listing.vue").then(m => m.default || m)
  },
  {
    name: "app-matches",
    path: "matches",
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/matches/index.vue").then(m => m.default || m)
  },
  {
    name: "app-notifications",
    path: "notifications",
    meta: indexXG6TfhUi18Meta || {},
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "app-places-slug",
    path: "places/:slug()",
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/places/[slug].vue").then(m => m.default || m)
  },
  {
    name: "app-players",
    path: "players",
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/players/index.vue").then(m => m.default || m)
  },
  {
    name: "app-profile-account",
    path: "profile/account",
    meta: accounteeOawEBXPFMeta || {},
    alias: ["/app/account/:slug"],
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/profile/account.vue").then(m => m.default || m)
  },
  {
    name: "app-profile-edit",
    path: "profile/edit",
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-profile-settings",
    path: "profile/settings",
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "app-profile-trainer",
    path: "profile/trainer",
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/profile/trainer.vue").then(m => m.default || m)
  },
  {
    name: "app-search",
    path: "search",
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/app/search/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "code",
    path: "/code",
    meta: codeoHlkxdZPx0Meta || {},
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/code.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordC6jqOJ27UwMeta || {},
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login52FGsDz5HtMeta || {},
    alias: login52FGsDz5HtMeta?.alias || [],
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "registration-about",
    path: "/registration/about",
    meta: aboutRdEEJ0Gl06Meta || {},
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/registration/about.vue").then(m => m.default || m)
  },
  {
    name: "registration-birthdate",
    path: "/registration/birthdate",
    meta: birthdate6MxurV7qygMeta || {},
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/registration/birthdate.vue").then(m => m.default || m)
  },
  {
    name: "registration-gender",
    path: "/registration/gender",
    meta: genderNyBB3uubyJMeta || {},
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/registration/gender.vue").then(m => m.default || m)
  },
  {
    name: "registration-photos",
    path: "/registration/photos",
    meta: photos0GYI9iOeOPMeta || {},
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/registration/photos.vue").then(m => m.default || m)
  },
  {
    name: "registration-registration",
    path: "/registration/registration",
    meta: registrationOzsMVYNNafMeta || {},
    alias: ["/registration"],
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/registration/registration.vue").then(m => m.default || m)
  },
  {
    name: "registration-sports",
    path: "/registration/sports",
    meta: sportscuaZBN1PGhMeta || {},
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/registration/sports.vue").then(m => m.default || m)
  },
  {
    name: "registration-user",
    path: "/registration/user",
    meta: userw7AkybbLJdMeta || {},
    component: () => import("/var/www/scorentino.com/data/sub/app/pages/registration/user.vue").then(m => m.default || m)
  }
]