import moment from 'moment';
import _ from 'lodash';

export const useTrackingStore = defineStore('tracking', {
    persist: true,

    state() {
        return {
            events: {},

            // Ios tracking
            consent: {
                asked: false,
                authorized: false,
            },
        };
    },

    actions: {
        setConsent(state) {
            this.consent.asked = true;
            this.consent.authorized = state;
        },
        snapEvent(eventName) {
            //Set event data.
            if (!this.events[eventName]) {
                this.events[eventName] = { t: null, c: 0 };
            }

            this.events[eventName].t = moment().toISOString();
            this.events[eventName].c++;
        },
        getEventDuration(eventName, durationType = 'days') {
            if (this.events[eventName]) {
                return moment().diff(
                    moment(this.events[eventName].t),
                    durationType
                );
            }
        },
        getEventCount(eventName) {
            if (this.events[eventName]) {
                return this.events[eventName].c;
            }

            return 0;
        },
        saveEventDuration(eventName, paramName, durationType = 'days') {
            if (this.events[eventName]) {
                const obj = {};
                obj[paramName] = this.getEventDuration(eventName, durationType);

                logEvent(eventName, obj);
            }

            this.snapEvent(eventName);
        },
    },
    getters: {},
});
