<template>
    <div class="form-group">
        <label class="form-file">
            <slot name="start" :preview="preview" />

            <input
                v-if="!disabled"
                ref="input"
                type="file"
                class="form-file__input"
                v-bind="{ ...$attrs, class: '' }"
                @change="onChange"
            />

            <div
                class="form-custom__wrapper"
                :class="{ '--light': light, '--disabled': disabled }"
            >
                <ion-icon :icon="addOutline" />

                <span class="input-placeholder">
                    {{ filenameShort || placeholder }}
                </span>

                <span class="end" @click.prevent.stop="reset" v-if="filename">
                    {{ __('Zmazať') }}
                </span>
            </div>
        </label>
    </div>
</template>

<script setup>
import _ from 'lodash';

defineOptions({
    inheritAttrs: false,
});

import { addOutline } from 'ionicons/icons';

const props = defineProps({
    placeholder: {
        type: String,
        required: false,
    },
    light: {
        type: Boolean,
        default: false,
    },
    modelValue: String,
    disabled: {
        default: false,
    },
});

const filename = ref();
const preview = ref();
const input = ref();

const onChange = (e) => {
    const file = e.target.files[0];
    filename.value = file?.name;

    setFilePreview(file);
};

const setFilePreview = (file) => {
    if (!file) {
        preview.value = null;
        return;
    }

    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
        // you can keep blob or save blob to another position
        const blob = new Blob([reader.result]);

        // url for download
        const url = URL.createObjectURL(blob, { type: file.type });

        preview.value = url;
    };
};

const reset = () => {
    filename.value = null;
    preview.value = null;
    input.value.value = null;
};

const filenameShort = computed(() => {
    if (!filename.value) {
        return;
    }

    let parts = (filename.value + '').split('.'),
        firstPart = parts.slice(0, -1).join('.'),
        max = 25;

    return (
        _.trim(
            firstPart.length > max
                ? firstPart.substr(0, max) + '... '
                : firstPart
        ) +
        '.' +
        parts[parts.length - 1]
    );
});
</script>

<style lang="scss" scoped>
.form-file {
    position: relative;
}

.form-file__input {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
}

.--disabled {
    opacity: 0.5;
    cursor: no-drop;
}
</style>
