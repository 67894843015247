<template>
    <AppModal :full="true" :modal="modal">
        <template v-slot:title>
            <div>
                <strong class="d-block">{{ __('Účasť na partičke') }}</strong>
                <span class="d-block mt-0" v-if="termine && termine.date">
                    {{ moment(termine.date).format('DD MMMM Y') }}
                </span>
            </div>
        </template>

        <EventThumb
            v-if="event && termine"
            :event="event"
            :termine="termine"
            :chat="false"
            :actions="false"
            :redirect="false"
        />

        <div class="app__tabs --light">
            <ion-segment v-model="tab">
                <ion-segment-button value="accepted">
                    <ion-label>
                        {{ __('Kto ide') }} ({{ acceptedAttendees.length }})
                    </ion-label>
                </ion-segment-button>
                <ion-segment-button value="canceled">
                    <ion-label>
                        {{ __('Kto nejde') }} ({{ canceledAttendees.length }})
                    </ion-label>
                </ion-segment-button>
                <ion-segment-button
                    value="declined"
                    v-if="declinedAttendees.length || tab == 'declined'"
                >
                    <ion-label>
                        {{ __('Zamietnutí') }} ({{ declinedAttendees.length }})
                    </ion-label>
                </ion-segment-button>
            </ion-segment>
        </div>

        <template v-if="useIsModalOpen(modal)">
            <div class="app__tabs-content pt-0" v-if="tab === 'accepted'">
                <div class="d-flex flex-column">
                    <div class="participants-wrapper pt-2">
                        <EventAttendees
                            v-model="attendees"
                            status="accepted"
                            trashMode="accepted"
                            :termine="termine"
                            :emptyResultsText="
                                __('Žiadní účastnici v tejto sekcii.')
                            "
                            @accountClick="onAccountClick"
                        />
                    </div>
                </div>
            </div>

            <div class="app__tabs-content pt-0" v-if="tab === 'canceled'">
                <div class="d-flex flex-column">
                    <div class="participants-wrapper pt-2">
                        <EventAttendees
                            v-model="attendees"
                            status="canceled"
                            trashMode="declined"
                            :termine="termine"
                            :emptyResultsText="
                                __('Žiadní účastnici v tejto sekcii.')
                            "
                            @accountClick="onAccountClick"
                        />
                    </div>
                </div>
            </div>

            <div class="app__tabs-content pt-0" v-if="tab === 'declined'">
                <div class="d-flex flex-column">
                    <div class="participants-wrapper pt-2">
                        <EventAttendees
                            v-model="attendees"
                            status="declined"
                            :termine="termine"
                            :canDecline="false"
                            :emptyResultsText="
                                __('Žiadní účastnici v tejto sekcii.')
                            "
                            @accountClick="onAccountClick"
                        />
                    </div>
                </div>
            </div>
        </template>

        <!-- TODO -->
        <!-- <div class="app__tabs-content pt-0" v-if="tab === 'not-respond'">
            <div class="d-flex flex-column">
                <div class="participants-wrapper pt-2"></div>
            </div>
        </div> -->
    </AppModal>
</template>

<script setup>
import _ from 'lodash';
import moment from 'moment';

const props = defineProps({
    modal: { default: 'event-attendance' },
});

const eventStore = useEventStore();

const tab = ref('accepted');
const attendees = ref([]);

useOnModalOpen(props.modal, (data) => {
    attendees.value = data.attendees;

    tab.value = 'accepted';
});

//Get termine from local events, to keep updates also when this modal is opened
const data = computed(() => useGetModalData(props.modal));
const event = computed(() => eventStore.getEvent(data.value?.event?.id));
const termine = computed(() => eventStore.getTermine(data.value?.termine));

const acceptedAttendees = computed(() =>
    _.filter(attendees.value, { status: 'accepted' })
);

const canceledAttendees = computed(() =>
    _.filter(attendees.value, { status: 'canceled' })
);

const declinedAttendees = computed(() =>
    _.filter(attendees.value, { status: 'declined' })
);

const onAccountClick = (account) => {
    useCloseModal(props.modal);

    pushStateRoute(
        { path: '/app/account/' + account.slug },
        { account: account }
    );
};
</script>

<style lang="scss" scoped>
:deep(ion-title) span {
    text-transform: capitalize;
}
</style>
