<template>
    <AppModal
        :title="__('Žiadosti')"
        :full="true"
        :modal="modal"
        v-slot="{ data }"
    >
        <div class="participants-wrapper">
            <!-- prettier-ignore -->
            <EventAttendees
                :status="['pending', useBackendEnv('EVENT_APPROVE_MATCHES') ? 'pending-match' : ''].filter(i => i)"
                :modal="modal"
                :termine="termine"
                v-model="attendees"
                v-if="useIsModalOpen(modal)"
                @accountClick="onAccountClick"
            />
        </div>
    </AppModal>
</template>

<script setup>
import _ from 'lodash';

const props = defineProps({
    modal: { default: 'event-requests' },
});

const eventStore = useEventStore();

const data = computed(() => useGetModalData(props.modal));

const termine = computed(() => data.value?.termine);

const attendees = ref([]);

useOnModalOpen(props.modal, (data) => {
    attendees.value = data.attendees;
});

const onAccountClick = (account) => {
    useCloseModal(props.modal);

    pushStateRoute(
        { path: '/app/account/' + account.slug },
        { account: account }
    );
};
</script>

<style lang="scss" scoped></style>
