import _ from 'lodash';
import moment from 'moment';
import { Geolocation } from '@capacitor/geolocation';

export const useLocationStore = defineStore('location', {
    persist: true,

    state() {
        return {
            loading: false,
            position: null,
            nearbyLocations: [],
            nearbySportId: null, //Filter key of fetched positions
            nearbyFetchedAt: null, //When data has been downloaded
            nearbyRadiusInMeters: null,
        };
    },

    actions: {
        async fetchCurrentPosition() {
            // Location is less older than, do nothing, we don't need new position
            const isUpToDate = useIsUpToDate(
                this.position?.time,
                useBackendEnv('FETCH_LOCATION_HOURS')
            );

            // prettier-ignore
            console.log('Fetching location: ', isUpToDate ? 'UP TO DATE' : 'MISSING');

            if (isUpToDate == false) {
                return await useLocationPermissionsModal(async () => {
                    this.loading = true;

                    try {
                        const position = await Geolocation.getCurrentPosition();

                        if (position.coords && position.timestamp) {
                            this.position = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                                accuracy: position.coords.accuracy,
                                time: moment
                                    .unix(position.timestamp / 1000)
                                    .format(),
                            };

                            // Save user location
                            if (useAuthStore().loggedIn === true) {
                                await useAxios().$postAsync('/api/profile', {
                                    _silent: true,
                                    lat: this.position.lat,
                                    lng: this.position.lng,
                                });
                            }
                        }
                    } catch (e) {
                        console.error(e);

                        showLocationErrorToast(true);
                    }

                    this.loading = false;

                    return this.position;
                });
            }

            return this.position;
        },
        async fetchNearbyIfMissing() {
            // prettier-ignore
            if ( this.isNearbyUpToDate ) {
                return this.nearbyLocations;
            }
            let position = await this.fetchCurrentPosition();

            let positions = await this.fetchNearbyLocations(position, true);

            this.nearbyFetchedAt =
                positions?.error === false ? moment().format() : null;
        },
        async fetchNearbyLocations(position, isPersistent = false) {
            if (!position) {
                return;
            }

            return await useAxios()
                .loading((state) => {
                    this.loading = state;
                })
                [isPersistent ? '$getAsync' : '$getOnline'](
                    '/api/locations/nearby',
                    {
                        params: {
                            ..._.pick(position, ['lat', 'lng']),
                            sport_id: this.filterBySportId,
                            persistent: isPersistent ? 1 : 0,
                        },
                    },
                    'nearby-location'
                );
        },
        resetPosition() {
            this.position = null;
        },
    },
    getters: {
        isNearbyUpToDate() {
            return (
                this.nearbySportId == this.filterBySportId &&
                useIsUpToDate(
                    this.nearbyFetchedAt,
                    useBackendEnv('FETCH_NEARBY_HOURS')
                )
            );
        },
        filterBySportId() {
            return useEventStore().form.sport_id;
        },
    },
});
