export const useSettingStore = defineStore('setting', {
    persist: true,

    state() {
        return {
            map: {},
            sentry: {
                enabled: true,
                replays: false,
                options: {},
            },
            hotjar: {},
            smartlook: {},
            reportReasons: [],
        };
    },

    actions: {},
    getters: {},
});
