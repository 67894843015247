import { toastController } from '@ionic/vue';

/*
 * Show toast message
 */
export const useShowToast = async (options) => {
    await useWaitTillKeyboardClose();

    options = typeof options == 'object' ? options : { message: options };

    let { message, duration, cssClass } = options;

    duration = duration || 2500;

    const toast = await toastController.create({
        message,
        duration,
        cssClass,
        swipeGesture: 'vertical',
    });

    toast.present();

    if (toast.shadowRoot) {
        toast.shadowRoot.addEventListener('click', () => {
            if (options.click) {
                options.click();
            }

            if (toast) {
                toast.dismiss();
            }
        });
    }
};

export const useShowErrorToast = (options = {}) => {
    options = typeof options == 'object' ? options : { message: options };

    useShowToast({ ...options, cssClass: '--error' });
};

export const useShowSuccessToast = (options) => {
    options = typeof options == 'object' ? options : { message: options };

    useShowToast({ ...options, cssClass: '--success' });
};

export const useThrowUnknownError = (message) => {
    useShowErrorToast({
        // prettier-ignore
        message: message||__('Ospravedlňujeme sa, no nastala nečakaná chyba. Skúste neskôr prosím.'),
    });
};

/*
 * Throw connection alert if is not connected
 */
export const useThrowConnectionAlert = (message) => {
    if (useAppStore().connected == false) {
        // prettier-ignore
        var message =
            typeof message == 'string'
                ? message
                : __('Vaše internetové pripojenie nie je zapnuté. Pre pokračovanie prosím zapnite internet.');

        useShowErrorToast({ message });

        throw 'Connection for this action is required.';
    }
};

export const useAutoAjaxResponse = (response) => {
    var isError = response instanceof Error;

    //If is error response
    if (isError && response.response) {
        response = response.response;
    }

    //Axios request data
    if (response) {
        const data = response.data || {},
            status = response.status,
            store = response.store || data.store;

        //Set store from request data
        if (store) {
            useBindPiniaStore(store);
        }

        //Validation error
        if ([401, 403].includes(status)) {
            useShowErrorToast({
                // prettier-ignore
                message: data?.error && data?.message ? data.message : __('Ľutujeme, do tejto sekcie nemáte právomoc.'),
            });
        } else if (status == 404) {
            useShowErrorToast({
                // prettier-ignore
                message: __('Ľutujeme, dany záznam nebol nájdeny. Pravdepodobne už neexistuje.'),
            });
        } else if (status == 422 && data.errors) {
            useShowErrorToast({
                message: Object.values(data.errors).join(' '),
                duration: 5000,
            });
        }

        //If valid request with error data has been given
        else if (response.message || data.message) {
            let isErrorCode = [4, 5].includes(parseInt((status + '')[0])), //All 400 + 500 error codes
                obj = {
                    message: response.message || data.message,
                    duration: isErrorCode ? 5000 : null,
                };

            if (isErrorCode) {
                useShowErrorToast(obj);
            } else {
                useShowToast(obj);
            }
        }

        //If valid request without error data has been given
        else if (isError) {
            console.error(response);

            useThrowUnknownError();
        }
    }

    //If invalid error request has been given. For example on backend crash etc...
    else if (isError) {
        console.error(response);

        useThrowUnknownError();
    }
};
