<template>
    <ModalWrapper class="full-modal" :is-open="useIsModalOpen(modal)">
        <ion-content class="ion-padding" :scrollY="false">
            <div class="full-modal__content p-0">
                <div class="onboarding">
                    <div class="onboarding__item" v-if="images.length == 0">
                        <ImageResource
                            src=""
                            :title="
                                appStore.connected
                                    ? __('Načítavame...')
                                    : __('Zapnite si internetové pripojenie')
                            "
                        />
                    </div>
                    <div
                        v-else
                        v-for="(image, index) in images"
                        class="onboarding__item"
                        v-show="index + 1 === currentStep"
                    >
                        <ImageResource :src="image" />
                    </div>
                </div>
            </div>
        </ion-content>

        <ion-footer class="ion-no-border">
            <ion-toolbar class="--withIndicator">
                <div class="indicator" v-if="appStore.booted">
                    <div
                        class="current"
                        :style="`width: ${(currentStep * 100) / images.length}%;`"
                    ></div>
                </div>
                <div class="px-1 d-flex gap-1">
                    <template v-if="currentStep < images.length">
                        <FormButton
                            v-if="currentStep == 1"
                            visual="silver"
                            full
                            type="button"
                            @click="useOpenModal('localization')"
                            class="--language"
                        >
                            <ion-icon :icon="languageOutline" />
                            {{ __('change language') }}
                        </FormButton>
                        <FormButton
                            v-else
                            visual="silver"
                            full
                            type="button"
                            @click="endOnboarding()"
                        >
                            {{ __('Zavrieť') }}
                        </FormButton>
                        <FormButton full type="button" @click="goNext()">
                            {{ __('Ďalej') }}
                        </FormButton>
                    </template>

                    <FormButton full type="button" @click="goNext()" v-else>
                        {{ __('Pokračovať do aplikácie') }}
                    </FormButton>
                </div>
            </ion-toolbar>
        </ion-footer>
    </ModalWrapper>
</template>

<script setup>
import { languageOutline } from 'ionicons/icons';
const props = defineProps({
    modal: { default: 'onboarding' },
});

const appStore = useAppStore();
const onboardingStore = useOnboardingStore();

const currentStep = ref(1);
const images = computed(() =>
    onboardingStore.images.map((image) => image.image)
);

const goNext = () => {
    if (currentStep.value < images.value.length) {
        currentStep.value++;
    } else {
        endOnboarding();
    }
};

const endOnboarding = () => {
    useCloseModal(props.modal);

    onboardingStore.boarded = true;
};

onMounted(() => {
    if (onboardingStore.boarded == false) {
        useOpenModal(props.modal);
    }
});

useOnModalOpen(props.modal, () => {
    currentStep.value = 1;
});
</script>

<style lang="scss" scoped>
ion-footer ion-toolbar {
    padding-top: 0 !important;
}

.indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0.5rem;
    background: #ebebeb;
    z-index: 10;

    .current {
        height: 100%;
        background: #000000;
        transition: all 0.2s;
    }
}

.onboarding {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
}

.onboarding__item {
    width: 100%;
    height: 100%;

    :deep(img) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;

        /* temp fix */
        width: calc(100% + 0.2rem);
        height: calc(100% + 0.2rem);
        margin: -0.1rem;

        @include more-than(md) {
            object-fit: contain;
        }
    }

    :deep(.placeholder-image):before {
        background: white;
    }
}

.--language {
    font-size: 1.2rem;

    ion-icon {
        font-size: 1.6rem;
    }
}
</style>
