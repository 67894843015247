<template>
    <DynamicModal
        :full="true"
        v-slot="{ loading, value, change, $cleanAttrs }"
        @save="input.$el.confirm()"
        :saveDelay="preferWheel ? 400 : 0"
    >
        <ion-datetime
            ref="input"
            :disabled="loading"
            v-bind="$cleanAttrs"
            :preferWheel="preferWheel"
            :value="value ? moment(value).format() : null"
            @ionChange="update(change, $event)"
            :presentation="presentation"
            hourCycle="h24"
            size="cover"
        ></ion-datetime>
    </DynamicModal>
</template>

<script setup>
import moment from 'moment';
import _ from 'lodash';

const props = defineProps({
    presentation: {
        default: 'date',
    },
    preferWheel: {
        default: false,
    },
});

const input = ref();

const preferWheel = computed(() => {
    return props.preferWheel || props.presentation == 'time';
});

const update = (change, e) => {
    const value = e.detail.value;

    change(value ? moment(value).format() : null);
};
</script>

<style lang="scss" scoped>
:deep(.sheet-modal__container) {
    padding: 1rem 1rem calc(var(--ion-safe-area-bottom) + 1rem) 1rem;
}

ion-datetime {
    --title-color: #000;

    ion-label {
        color: #000;
    }
}

ion-datetime::part(calendar-day) {
    color: #000;
}

ion-datetime::part(calendar-day today) {
    color: var(--ion-color-primary);
}

ion-datetime::part(calendar-day active) {
    color: #fff;
    background: var(--ion-color-primary);
}

ion-datetime::part(calendar-day active):focus {
    outline: none;
}

ion-datetime::part(month-year-button) {
    color: #000;
    font-size: 1.8rem;
}
</style>
