<template>
    <OptionsModal :modal="modal">
        <template v-if="event">
            <a
                @click="
                    useOpenModalCloseCurrent('create-event', {
                        event: event,
                        termine: termine,
                    })
                "
            >
                {{ eventStore.typeText(event.type, 'title_update') }}
            </a>

            <a
                @click="
                    useOpenModalCloseCurrent('event-termine-update', {
                        event: event,
                        termine: null,
                    })
                "
            >
                {{ __('Vytvoriť nový termín') }}
            </a>

            <!-- prettier-ignore -->
            <a @click="useLazyClick($event, async () => await eventVisbility(event))">
                {{ event.is_public ? __('Zmeniť na súkromnú partičku') : __('Zmeniť na verejnú partičku') }}
            </a>

            <!-- prettier-ignore -->
            <a v-if="termine && !termine.canceled_at && isFutureTermine" @click="useLazyClick($event, async () => await cancelTermine(termine))">
                {{ __('Zrušiť termín') }}

                <small class="--date">({{ __('%s o %s', moment(termine.date).format('DD. MMMM'), moment(termine.date).format('HH:mm')) }})</small>
            </a>

            <!-- prettier-ignore -->
            <a @click="useLazyClick($event, async () => await deleteEvent(event))" class="--red">
                {{ eventStore.typeText(event.type, 'title_delete') }}
            </a>
        </template>
    </OptionsModal>
</template>

<script setup>
import moment from 'moment';
const props = defineProps({
    modal: { default: 'event-actions' },
});

const router = useIonRouter();
const eventStore = useEventStore();

//Get termine from local events, to keep updates also when this modal is opened
const data = computed(() => useGetModalData(props.modal));
const event = computed(() => eventStore.getEvent(data.value?.event?.id));
const termine = computed(() => eventStore.getTermine(data.value?.termine));

const isFutureTermine = computed(() => {
    return moment(termine.value?.date) > moment();
});

const cancelTermine = async (termine) => {
    useOpenModalCloseCurrent('modal-confirm', {
        title: __('Zrušiť termín'),
        // prettier-ignore
        message : __('Naozaj si prajete zrušiť tento termín? Všetci pripojení členovia budú informovaní.'),
        cancelText: __('Zatvoriť'),
        success: async () => {
            try {
                // prettier-ignore
                let response = await useAxios().$postOnline(
                    '/api/events/' + termine.event_id + '/termines/'+termine.id+'/cancel'
                );

                useAutoAjaxResponse(response);
            } catch (e) {
                useAutoAjaxResponse(e);

                //Do not close modal on error
                return false;
            }
        },
    });
};

const eventVisbility = async (event) => {
    useOpenModalCloseCurrent('modal-confirm', {
        title: __('Viditeľnosť partičky'),
        // prettier-ignore
        message : __('Do verejnej partičky sa môže pripojiť ktokoľvek bez nutnosti zdieľania partičky.'),
        cancelText: __('Skryť verejnosti'),
        confirmText: __('Verejná'),
        success: async () => {
            await updateEvent(event, { is_public: true });
        },
        cancel: async () => {
            await updateEvent(event, { is_public: false });

            useCloseModal('modal-confirm');
        },
    });
};

const deleteEvent = async (event) => {
    useOpenModalCloseCurrent('modal-confirm', {
        title: eventStore.typeText(event.type, 'title_delete'),
        // prettier-ignore
        message : __('Naozaj si prajete pokračovať? Všetky budúce a minulé udalosti budú vymazané a členovia informovaní.'),
        success: async () => {
            try {
                // prettier-ignore
                let response = await useAxios().$deleteOnline(
                    '/api/events/' + event.id
                );

                useAutoAjaxResponse(response);

                if (['app-event-slug'].includes(useRoute().name)) {
                    if (router.canGoBack()) {
                        router.back();
                    } else {
                        useGoToFeed();
                    }
                }
            } catch (e) {
                useAutoAjaxResponse(e);

                //Do not close modal on error
                return false;
            }
        },
    });
};

const updateEvent = async (event, data) => {
    try {
        let response = await useAxios().$postOnline(
            '/api/events/' + event.id + '/partial',
            data
        );

        useAutoAjaxResponse(response);
    } catch (e) {
        useAutoAjaxResponse(e);

        //Do not close modal on error
        return false;
    }
};
</script>

<style lang="scss" scoped></style>
