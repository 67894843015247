<template>
    <EventStep
        :errors="getValidationError"
        :buttonName="
            loading
                ? __('Vytvárame...')
                : eventStore.typeText(
                      form.type,
                      form.id ? 'title_update' : 'title_create'
                  )
        "
        :loading="loading"
    >
        <form
            id="event_form"
            v-autoAjax
            method="post"
            :action="form.id ? '/api/events/' + form.id : '/api/events'"
            @success="success"
            @error="onError"
            @loading="loading = $event"
            @data="formData"
        >
            <div class="input-group__item mb-2" v-if="isSportEvent">
                <FormLabel>{{ __('Maximálny počet hráčov') }}</FormLabel>

                <div class="form-group">
                    <div
                        @click="useOpenModal('event-players')"
                        class="input-group__value"
                        :class="{ '--placeholder': _.isNil(form.players) }"
                        data-virtual-input="players"
                    >
                        {{
                            _.isNil(form.players)
                                ? __('Vyberte počet hráčov')
                                : form.players || __('Bez obmedzenia')
                        }}
                    </div>
                </div>

                <!-- prettier-ignore -->
                <FormInfo>{{ __('Vrátane mňa') }}</FormInfo>
            </div>

            <div class="form-item">
                <FormLabel>
                    {{ __('Suma za člena') }}
                    <span class="--optional">{{ __('(nepovinné)') }}</span>
                </FormLabel>
                <FormText
                    type="number"
                    step="any"
                    :min="0"
                    placeholder="0 €"
                    v-model="form.price_per_player"
                    name="price_per_player"
                />
                <FormInfo v-if="isSportEvent">
                    {{ __('Suma za každého člena (€)') }}
                </FormInfo>
            </div>

            <div class="form-item mt-2">
                <div class="d-flex align-items-center justify-content-between">
                    <FormLabel>{{ __('Titulná fotka') }}</FormLabel>
                    <a
                        class="choose-random cursor-pointer"
                        @click="setRandomCover()"
                        v-if="sampleCovers.length > 1"
                    >
                        {{ __('Vybrať náhodnú') }}
                    </a>
                </div>

                <div @click="checkIfCanUploadCustomPhoto()">
                    <FormFile
                        name="image"
                        :placeholder="__('Vybrať fotku')"
                        accept="image/png, image/gif, image/jpeg"
                        :disabled="!canUploadCustomPhoto"
                    >
                        <template v-slot:start="{ preview }">
                            <EventThumb
                                :isSample="true"
                                :redirect="false"
                                :event="sampleEvent(preview)"
                                :termine="sampleTermine"
                                class="my-1"
                            />
                        </template>
                    </FormFile>
                </div>
            </div>

            <div class="form-item mt-2">
                <FormLabel>
                    {{ __('Informácie a pravidlá') }}
                    <span class="--optional">{{ __('(nepovinné)') }}</span>
                </FormLabel>

                <FormTextarea
                    v-model="form.description"
                    name="description"
                    :auto-grow="true"
                />
            </div>

            <FormLabel class="mt-2">
                {{ __('Ostatné nastavenia') }}
                <span class="--optional">{{ __('(nepovinné)') }}</span>
            </FormLabel>

            <div class="form-item mt-2">
                <FormCheckbox
                    v-model="form.is_public"
                    name="is_public"
                    value="1"
                >
                    {{ __('Verejná partička') }}
                </FormCheckbox>
            </div>

            <div class="form-item mt-2">
                <FormCheckbox
                    v-model="form.must_accept"
                    name="must_accept"
                    value="1"
                >
                    {{ __('Schvaľovať nových členov') }}
                </FormCheckbox>
            </div>
        </form>
    </EventStep>
</template>

<script setup>
import _ from 'lodash';
import moment from 'moment';

const eventStore = useEventStore();
const sportStore = useSportStore();
const authStore = useAuthStore();
const loading = ref(false);
const form = computed(() => eventStore.form);

const getValidationError = computed(() => {
    if (isSportEvent.value && _.isNil(form.value.players)) {
        return __('Vyplnte počet hráčov.');
    }

    return false;
});

const locationObject = computed(() => {
    //Custom coordinates
    if (eventStore.isCustomLocation) {
        return eventStore.customCoordinates || {};
    } else {
        let id = eventStore.location?.id;

        // Google place
        if (_.isString(id)) {
            return {
                lat: eventStore.location.lat,
                lng: eventStore.location.lng,
                address: eventStore.location.address,
                place: eventStore.location,
            };
        }

        //Our scorentino saved location
        else {
            return { location_id: id };
        }
    }
});

const formData = () => {
    const obj = {
        ...form.value,
        ...locationObject.value,
        name: form.value.name,
        date: eventStore.dateMerged ? eventStore.dateMerged.format() : null,
        players: isSportEvent.value ? form.value.players : 0,
    };

    delete obj.time;

    return obj;
};

const isSportEvent = computed(() => {
    return eventStore.types[form.value.type]?.sport;
});

const sportName = computed(() => {
    return sportStore.getById(form.value.sport_id)?.name;
});

const sampleEvent = computed(() => {
    return (thumbnail) => {
        return {
            thumbnail,
            user: authStore.user,
            ...formData(),
            location: eventStore.location,
        };
    };
});

const sampleTermine = computed(() => {
    //event/Termine edit
    if (form.value.id && form.value.termine_id) {
        return eventStore.getTermine({
            id: form.value.termine_id,
            event_id: form.value.id,
        });
    }

    return {
        date: eventStore.dateMerged,
        has_time: true,
    };
});

const sampleCovers = computed(() => {
    const sportCovers = sportStore.covers.filter((cover) =>
        (cover.assignedSportIds || []).includes(form.value.sport_id)
    );

    const defaultCovers = sportStore.covers.filter(
        (cover) => (cover.assignedSportIds || []).length == 0
    );

    // first assigned sport covers
    if (sportCovers.length) {
        return sportCovers;
    }

    // covers without assigned sport
    else if (defaultCovers.length) {
        return defaultCovers;
    }

    //Any cover...
    return sportStore.covers;
});

const setRandomCover = () => {
    form.value.cover_id = _.sample(
        //Do not use already used one.
        sampleCovers.value.filter((cover) => cover.id != form.value.cover_id)
    )?.id;
};

//Set automatically random cover, do not use default. If cover is empty.
if (!form.value.cover_id) {
    setRandomCover();
}

const myPastAcceptedTerminesCount = computed(() => {
    return _.flatten(eventStore.events.map((event) => event.termines)).filter(
        (termine) => {
            //Only past termines
            return (
                moment(termine?.date) < moment() &&
                ['accepted'].includes(termine.attendance_status)
            );
        }
    ).length;
});

const canUploadCustomPhoto = computed(() => {
    return (
        myPastAcceptedTerminesCount.value >=
        useBackendEnv('CUSTOM_PHOTO_FROM_EVENTS_COUNT')
    );
});

const checkIfCanUploadCustomPhoto = (e) => {
    if (canUploadCustomPhoto.value == false) {
        // prettier-ignore
        useShowErrorToast({
            message : __('Pre nahranie vlastnej fotografie je potrebné mať absolovaných aspoň 5 partičiek. Váš aktuálny počet je %d.', myPastAcceptedTerminesCount.value),
            duration : 4000,
        });
    }
};

const success = (response) => {
    useAutoAjaxResponse(response);

    const event = response.data.event;

    eventStore.addEvent(event);

    //Redirect only on new event
    if (!form.value.id) {
        pushStateRoute(
            {
                name: 'app-event-slug',
                params: { slug: event.slug },
            },
            {
                event,
            }
        );
    }

    useCloseModal();

    eventStore.reset();
};

const onError = (response, res) => {
    if ([401].includes(res?.status)) {
        useOpenModal('login-modal');
    }

    useAutoAjaxResponse(response);
};
</script>

<style lang="scss" scoped>
.choose-random {
    color: #000;
    text-decoration: underline;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}
</style>
