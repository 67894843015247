import { App } from '@capacitor/app';

let _platformHeaders = {};

export const useIsDesktop = () => {
    return (isPlatform('mobileweb') || isPlatform('desktop')) === true;
};

export const useGetFinalPlatformName = () => {
    if (isPlatform('hybrid')) {
        if (isPlatform('android')) {
            return 'android';
        } else if (isPlatform('ios')) {
            return 'ios';
        }

        return 'unknown';
    }

    return 'web';
};

//Get App version
export const getAppVersion = async () => {
    try {
        return useIsDesktop() ? null : (await App.getInfo()).version;
    } catch (e) {
        console.error(e);
    }
};

//Boot async headers
const bootPlatformHeaders = async () => {
    _platformHeaders = {
        'app-platform': useGetFinalPlatformName(),
        'app-version': await getAppVersion(),
    };
};
bootPlatformHeaders();

export const usePlatformHeaders = () => {
    return _platformHeaders;
};
