<template>
    <DynamicModal :full="true" :header="false" v-slot="{ data }">
        <slot :data="data" />
    </DynamicModal>
</template>

<style lang="scss" scoped>
ion-modal {
    --border-radius: 4rem;
    /*    --backdrop-opacity: 0;*/

    :deep(.sheet-modal__container.--full) {
        padding: 3rem;
        margin-bottom: 0;
        /*        min-height: calc(100vh - 15rem);*/
    }
}
</style>
