<template>
    <ion-app>
        <!-- We cannot load twice this component, because APP filter may be booted only once -->
        <template v-if="isPlatform('desktop')">
            <DesktopHeader />
            <DesktopMenu />
            <DesktopRightSidebar />
        </template>

        <ion-router-outlet />

        <OnboardingModal />

        <template v-if="appStore.booted">
            <ConfirmModal />
            <EventModals />
            <AppShareModal />
            <LocaleModal />
            <AppEnvironmentModal />

            <!-- Feed -->
            <AppFilterModals />

            <!-- Auth -->
            <LoginModal />
            <RegisterModal />
            <PasswordChangeModal />
            <ForgotPasswordModal />

            <!-- Account -->
            <AccountModals v-if="authStore.user" />
            <AccountActions />
            <SetSportLevelModal />

            <!-- Reports -->
            <ReportContent />
            <ReportSubmitted />

            <!-- Matcher -->
            <PartnerFoundModal />

            <!-- Permissions -->
            <AllowLocationModal />
            <AllowNotificationModal />

            <!-- Content -->
            <ArticleModal />
            <InquiryModal />
            <OnboardingTutorial />

            <!-- Event -->
            <AppInviteModal />

            <!-- Chat -->
            <MessageActions />
        </template>
    </ion-app>
</template>

<script setup>
import { App } from '@capacitor/app';
import _ from 'lodash';

const appStore = useAppStore();
const authStore = useAuthStore();

const route = useRoute();

onMounted(() => {
    useSetAppBackgroundStyle('light');

    //Only for desktop
    if (isPlatform('desktop')) {
        Ionic.config.set('animated', false);
    }

    if ('inquiry' in (route.query || {})) {
        useOpenModal('inquiry');
    }

    useDeepLinks();
});

const useDeepLinks = () => {
    App.addListener('appUrlOpen', (data) => {
        if (
            (data.url &&
                _.isString(data.url) &&
                data.url.includes('scorentino.com/app/') === true) === false
        ) {
            return;
        }

        let slug = data.url.split('/app/').pop(),
            path = '/app/' + slug;

        // We only push to the route if there is a slug present
        if (path) {
            setTimeout(async () => {
                const router = useRouter();

                // If is not app page, then reload to feed first, to fix routing. (when going eg. from login)
                if (_.startsWith(useRoute().path, '/app') === false) {
                    await router.push({ name: 'app-feed' });

                    await useSleep(500);
                }

                useRouter().push({ path });
            }, 500);
        }
    });
};
</script>
