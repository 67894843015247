<template>
    <DynamicModal
        :modal="modal"
        :header="false"
        :backdrop="false"
        :full="true"
        :handle="false"
    >
        <div class="text__wrapper">
            <div class="thumbnail__wrapper">
                <ImageResource :src="thumbnail" v-if="thumbnail" />
                <img v-else src="~/assets/images/placeholders/user.png" />
            </div>

            <!-- prettier-ignore -->
            <h2>{{ __('Našli sme ti parťáka na') }} <span>[{{ sportName||__('šport') }}]</span>!</h2>

            <!-- prettier-ignore -->
            <small>{{ __('Môžeš pokračovať vo vyhľadávani a následne prijať alebo odmietnuť parťáka.') }}</small>
        </div>

        <FormButton
            type="submit"
            full
            class="mt-2"
            @click="useLazyClick($event, onActionClick)"
        >
            {{
                isMyEvent
                    ? __('Pokračovať vo swipovaní')
                    : __('Zobraziť partičku')
            }}
        </FormButton>
    </DynamicModal>
</template>

<script setup>
import _ from 'lodash';

const props = defineProps({
    modal: {
        default: 'match-partner-found',
    },
});

const eventStore = useEventStore();
const sportStore = useSportStore();
const notificationStore = useNotificationStore();

const data = ref({});

useOnModalOpen(props.modal, (response) => {
    data.value = { ...(response.data || {}), id: response.id };

    setNotificationAsClicked();
});

const event = computed(() => {
    return eventStore.getEvent(parseInt(data.value?.eid));
});

const isMyEvent = computed(() => {
    return useIsMe(event.value?.user_id);
});

const thumbnail = computed(() => {
    // If thumbnail is not set in notification
    if (!data.value?.thumbnail) {
        // Use the thumbnail of the event owner.
        if (event.value && isMyEvent.value === false) {
            return event.value?.user?.thumbnail;
        }

        return null;
    }

    return useBackendEnv('USER_THUMBNAIL_PATH', '').replace(
        '{filename}',
        data.value?.thumbnail
    );
});

const sportName = computed(() => {
    return (
        data.value?.sport_name ||
        sportStore.getById(event.value?.sport_id)?.name
    );
});

const onActionClick = async () => {
    // Mark this notification as clicked
    setNotificationAsClicked();

    await useCloseModal(props.modal);

    // Go to matcher or to the exact notification route.
    if (isMyEvent.value) {
        await goToMatcher();
    } else {
        useTryGoToNotificationPage(data.value);
    }
};

const goToMatcher = async () => {
    const { eid, tid } = data.value || {};

    if (!eid || !tid) {
        return;
    }

    pushStateRoute(
        {
            name: 'app-matches',
            query: {
                eid,
                tid,
            },
        },
        { forceSearch: true },
        true
    );
};

const setNotificationAsClicked = () => {
    if (!data.value?.id) {
        return;
    }

    let notification = _.find(notificationStore.notifications, {
        id: parseInt(data.value.id),
    });

    if (notification) {
        notificationStore.setClicked(notification);
    }
};
</script>

<style scoped lang="scss">
.modal-sheet::part(content) {
    overflow: visible;
}

.text__wrapper {
    padding: 1rem 2rem;
    text-align: center;

    h2 {
        margin: 1rem 0;
        font-size: 1.9rem;
        font-weight: 600;

        span {
            color: var(--ion-color-primary);
        }
    }

    .thumbnail__wrapper {
        width: 13rem;
        height: 15rem;
        border-radius: 1rem;
        border: 3px solid var(--ion-color-primary);
        overflow: hidden;
        margin: 0 auto;
        position: relative;
        margin-top: -7rem;
        margin-bottom: 2rem;
        transform: rotate(12deg);

        > :deep(img) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    small {
        font-size: 1.2rem;
        color: #021e26;
        opacity: 0.5;
    }
}
</style>
