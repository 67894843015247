import _ from 'lodash';
import moment from 'moment';

const eventsInTermine = (events, callback) => {
    events = _.cloneDeep(events)
        .map((event) => {
            event.termines = (event.termines || []).filter(callback);

            return event;
        })
        .filter((event) => event.termines.length);

    //TODO: periodic shuffle
    return events.slice(0, 10);
};

export const useDashboardStore = defineStore('dashboard', {
    persist: true,

    state() {
        return {
            sports: [],
            events: [],
            serviceTypes: [],
        };
    },

    actions: {},

    getters: {
        //Get future dashboard events, mixed with my local events to see fresh data
        futureEventsByType() {
            return (type) => {
                let events = _.cloneDeep(
                    this.events
                        //If we are joined in event, take local event data
                        .map((event) => {
                            return useEventStore().getEvent(event.id) || event;
                        })

                        //Add also events from my local storage, to see them in dashboard
                        .concat(useEventStore().myEvents || [])
                );

                //Filter only future termines
                events = events.map((event) => {
                    //Filter only future events
                    event.termines = event.termines.filter((termine) => {
                        return moment(termine.date) > moment();
                    });

                    return event;
                });

                events = _.uniqBy(events, 'id');

                return type ? _.filter(events, { type }) : events;
            };
        },
        eventsToday() {
            return (type) => {
                return eventsInTermine(
                    this.futureEventsByType(type),
                    (termine) => {
                        return moment(termine.date).isSame(moment(), 'day');
                    }
                );
            };
        },
        eventsTomorrow() {
            return (type) => {
                return eventsInTermine(
                    this.futureEventsByType(type),
                    (termine) => {
                        return moment(termine.date).isSame(
                            moment().add(1, 'day'),
                            'day'
                        );
                    }
                );
            };
        },
    },
});
