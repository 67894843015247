<template>
    <template v-if="isPastTermine || termine.canceled_at">
        <ion-button
            v-if="termine.canceled_at"
            fill="clear"
            class="dropdown-btn --disabled"
        >
            <ion-icon class="icon-before" :icon="closeCircle" />
            {{ __('Termín bol zrušený') }}
            <ion-icon class="icon-after me-1" :icon="chevronDown" />
        </ion-button>

        <ion-button
            v-else-if="isPastTermine && canShowPastTermine"
            fill="clear"
            class="dropdown-btn --disabled"
        >
            <ion-icon class="icon-before" :icon="closeCircle" />
            {{ __('Termín sa už odohral') }}
            <ion-icon class="icon-after me-1" :icon="chevronDown" />
        </ion-button>

        <ion-button
            class="dropdown-btn --primary"
            v-if="nextTermine"
            @click="router.replace({ query: { t: nextTermine.id } })"
        >
            {{ __('Zobraziť nasledujúcu partičku') }}
        </ion-button>
    </template>

    <template v-else-if="canManageAttendance">
        <!-- User has already access to this event. So is owner, or has been accepted by owner. -->
        <template v-if="attendee && canJoinAndIsApproved">
            <ion-button
                fill="clear"
                class="dropdown-btn"
                v-if="attendee && ['accepted'].includes(attendee.status)"
                @click="
                    eventAction(() =>
                        useOpenModal('event-going', goingCallback)
                    )
                "
            >
                <ion-icon class="icon-before --small" :icon="chevronDown" />

                {{ __('Áno, zúčastním sa') }}

                <CircleCounter
                    v-if="counter"
                    class="counter-after"
                    :members="event.players"
                    :current="termine.attendees_accepted"
                />

                <ion-icon
                    v-else
                    class="icon-after --big"
                    :icon="checkmarkCircle"
                />
            </ion-button>

            <ion-button
                v-else
                fill="clear"
                class="dropdown-btn --primary"
                @click="
                    eventAction(() =>
                        useOpenModal('event-going', goingCallback)
                    )
                "
            >
                <ion-icon class="icon-before --small" :icon="chevronDown" />
                {{ __('Nezúčastním sa') }}
                <ion-icon class="icon-after --big" :icon="closeCircle" />
            </ion-button>
        </template>

        <template v-else>
            <ion-button
                fill="clear"
                class="dropdown-btn --primary"
                @click="useLazyClick($event, () => addToEvent())"
                v-if="!attendee"
            >
                {{
                    useIsEventApproved(event)
                        ? __('Zúčasníť sa termínu')
                        : __('Požiadať o účasť')
                }}
                <CircleCounter
                    v-if="counter"
                    class="counter-after"
                    :members="event.players"
                    :current="termine.attendees_accepted"
                />
            </ion-button>

            <template v-else-if="attendee">
                <!-- prettier-ignore -->
                <ion-button
                    v-if="attendee.status == 'declined'"
                    fill="clear"
                    class="dropdown-btn --disabled"
                >
                    <ion-icon class="icon-before" :icon="closeCircle" />
                    {{ __('Účasť bola zamietnutá') }}
                </ion-button>

                <!-- prettier-ignore -->
                <ion-button
                    v-else
                    fill="clear"
                    class="dropdown-btn --disabled"
                    @click="
                        eventAction(() =>
                            useOpenModal('event-going', goingCallback)
                        )
                    "
                >
                    <ion-icon class="icon-before" :icon="timeOutline" />

                    {{ __('Čaká sa na schválenie') }}

                    <CircleCounter
                        v-if="counter"
                        class="counter-after"
                        :members="event.players"
                        :current="termine.attendees_accepted"
                    />
                </ion-button>

                <ion-button
                    v-if="nextTermine && attendee.status == 'declined'"
                    class="dropdown-btn --primary"
                    @click="router.replace({ query: { t: nextTermine.id } })"
                >
                    {{ __('Zobraziť nasledujúcu partičku') }}
                </ion-button>
            </template>
        </template>
    </template>

    <!-- <AppParticipationModal :isOpen="true" /> -->

    <!-- <AppRequestsModal :isOpen="true" /> -->

    <!-- <AppShareModal :isOpen="true" /> -->
</template>

<script setup>
import {
    checkmarkCircle,
    chevronDown,
    closeCircle,
    timeOutline,
} from 'ionicons/icons';
import _ from 'lodash';

const props = defineProps({
    event: {},
    termine: {},
    counter: {
        default: false,
    },
    canShowNextTermine: {
        default: true,
    },
    canShowPastTermine: {
        default: true,
    },
});

const router = useRouter();
const authStore = useAuthStore();
const eventStore = useEventStore();

const termine = ref(props.termine);

watch(
    () => props.termine,
    (t) => {
        termine.value = t;
    }
);

const attendee = computed(() => {
    //If is not full response
    //But termine has loaded status in it.
    if (
        authStore.user &&
        (termine.value.attendees || []).length == 0 &&
        termine.value.attendance_status
    ) {
        return {
            ...authStore.user,
            status: termine.value.attendance_status,
        };
    }

    return authStore.user
        ? _.find(termine.value.attendees || [], { id: authStore.user.id })
        : null;
});

const nextTermine = computed(() => {
    //Show next termine only on event page.
    if (props.canShowNextTermine == false) {
        return;
    }

    return useGetDefaultTermine(
        (props.event?.termines || []).filter((t) => {
            //Filter same one
            if (t.id == termine.value?.id) {
                return false;
            }

            //Is past or canceled
            if (isFutureTermine(t) === false || t.canceled_at) {
                return false;
            }

            return true;
        })
    );
});

const isPastTermine = computed(() => {
    return isFutureTermine(termine.value) === false;
});

const canManageAttendance = computed(() => {
    //You cannot change your attendance for trainings etc.
    if (
        useIsMe(props.event.user) &&
        ['training', 'service'].includes(props.event.type)
    ) {
        return false;
    }

    return true;
});

const canJoinAndIsApproved = computed(() => {
    if (useIsEventApproved(props.event)) {
        return true;
    }

    //Is accepted or canceled attendance already
    if (['accepted', 'canceled'].includes(attendee.value?.status)) {
        return true;
    }

    return false;
});

const addToEvent = async (action = 'add') => {
    await eventAction(async () => {
        //When user is doing actions with event, we need register this event
        //Into his local events memory.
        eventStore.addEvent(props.event);

        try {
            // prettier-ignore
            let response = await useAxios().$postOnline(
                '/api/events/' + props.event.id + '/termines/'+termine.value.id+'/attendees/'+action,
                {}
            );

            useAutoAjaxResponse(response);
        } catch (e) {
            useAutoAjaxResponse(e);
        }
    });
};

const eventAction = async (callback) => {
    //User is not logged yet
    if (!authStore.user) {
        return useOpenModal('login-modal');
    }

    await callback();
};

const goingCallback = computed(() => {
    return {
        approved: canJoinAndIsApproved.value,
        callback: (action) => {
            return async (e) => {
                await addToEvent(action);

                useCloseModal('event-going');
            };
        },
    };
});
</script>

<style lang="scss" scoped>
.dropdown-btn {
    --padding-start: 1.5rem;
    --padding-end: 1.5rem;
    margin-top: 1.5rem;
    background: #80cad2;
    border-radius: 3rem;
    font-weight: 600;
    font-size: 1.3rem;
    color: #020310;
    letter-spacing: -0.42px;
    text-align: center;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 0.5rem;
    position: relative;
    appearance: none;
    border: 0;
    width: 100%;
    min-height: 4.5rem;
    padding: 0;

    &.--primary {
        color: #fff;
        background: var(--ion-color-primary);

        .icon-before,
        .icon-after {
            color: #fff;
        }
    }

    &.--disabled {
        color: #8e9191;
        background: transparent;
        box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.3);
        border: 1px solid var(--border-dark-color);

        .icon-before,
        .icon-after {
            color: #8e9191;
        }
    }

    .icon-before,
    .icon-after {
        &.--small {
            margin-top: -0.8rem;
            font-size: 1.6rem;
        }

        &.--big {
            margin-top: -1.2rem;
            font-size: 2.4rem;
        }
    }

    .icon-before {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1.2rem;
        font-size: 2.4rem;
        color: #568991;
    }

    .icon-after {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -0.8rem;
        font-size: 1.6rem;
        color: #568991;
    }

    .counter-after {
        position: absolute;
        right: -0.75rem;
        top: 50%;
        transform: translate(0, -50%);

        .event.--waiting & {
            filter: grayscale(1);
        }
    }
}
</style>
