<template>
    <DynamicModal
        v-slot="{ $cleanAttrs }"
        :name="data?.title || __('Kontaktujte nás')"
        :modal="modal"
        :canSave="false"
        :full="true"
    >
        <form
            v-autoAjax
            method="post"
            action="/api/inquiry"
            @success="success"
            @loading="loading = $event"
        >
            <div class="text__wrapper" v-show="useIsDesktop()">
                <div class="row">
                    <div class="text__wrapper__section col-md-6">
                        <strong>{{ __('Prevádzkovateľ') }}</strong>
                        <p>Scorentino Group SE</p>
                        <p>IČO: 53544781</p>
                        <p>Strojárenská 8800/1B 917 02 Trnava</p>
                    </div>

                    <div class="text__wrapper__section col-md-6">
                        <strong>{{ __('Kontaktné údaje') }}</strong>
                        <p>
                            <a href="mailto:info@scorentino.com">
                                info@scorentino.com
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div class="form-item">
                <FormLabel>
                    {{ __('E-mail') }}
                </FormLabel>
                <FormText type="email" name="email" :value="user?.email" />
                <!-- prettier-ignore -->
                <FormInfo>
                    {{ __('Uveďte prosím e-mailovú adresu pre spätný kontakt.') }}
                </FormInfo>
            </div>

            <div class="form-item mt-2">
                <FormLabel>
                    {{ __('Správa') }}
                </FormLabel>
                <FormTextarea
                    name="message"
                    :value="data?.message"
                    class="mt-0"
                    :auto-grow="true"
                    v-bind="$cleanAttrs"
                />
            </div>

            <FormButton type="submit" full class="mt-2" :loading="loading">
                {{ __('Odoslať správu') }}
            </FormButton>
        </form>
    </DynamicModal>
</template>

<script setup>
const props = defineProps({
    modal: {
        default: 'inquiry',
    },
});

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const loading = ref(false);

const data = computed(() => useGetModalData(props.modal));

const success = async (response) => {
    useAutoAjaxResponse(response);

    useCloseModal(props.modal);
};
</script>

<style scoped lang="scss">
.text__wrapper {
    background: #f1f1f1;
    font-size: 1.4rem;
    margin-bottom: 2rem;
    padding: 2rem;
    border-radius: 0.25rem;

    p {
        margin-top: 0;
    }

    .text__wrapper__section {
        p {
            margin-bottom: 0;
        }

        a {
            color: var(--ion-color-primary);
        }

        @include less-than(md) {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }
}
</style>
