import moment from 'moment';
import { isPlatform } from '@ionic/vue';

export const useAppStore = defineStore('app', {
    persist: true,

    state() {
        return {
            api_url: import.meta.env.VITE_APP_SERVER_URL,
            booted: false,
            backendEnv: {},
            deviceModel: null,
            appVersion: null,
            latestVersions: null,
            lastUpdateTime: null,
            connected: false,
            keyboard: false,
            loading: false,
        };
    },

    actions: {
        setDeviceModel(model) {
            this.deviceModel = model;
        },
        setAppVersion(version) {
            this.appVersion = version;
        },
        setLatestVersion(latestCode) {
            this.latestVersions = latestCode;
        },
        setConnection(status) {
            this.connected = status;
        },
        setLastUpdate(state) {
            this.lastUpdateTime = Date.now();
        },
        resetLastUpdate(state) {
            this.lastUpdateTime = null;
        },
        async refreshApp(only = []) {
            //Get app data
            try {
                console.log('APP refreshed at: ' + moment().format('HH:mm:ss'));

                var response = await useAxios().$get('api/bootstrap', {
                    params: {
                        only: (only || []).join(','),
                    },
                });

                useAutoAjaxResponse(response);

                this.setLastUpdate();

                useOnAppRefresh();

                return true;
            } catch (e) {
                //No need for message
                console.error(e);

                return false;
            }
        },
    },
    getters: {
        isBooted() {
            return this.lastUpdateTime ? true : false;
        },
        isProdApi() {
            return this.api_url == import.meta.env.VITE_APP_SERVER_URL;
        },
    },
});
