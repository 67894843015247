<template>
    <DynamicModal
        v-slot="{ $cleanAttrs, data }"
        :modal="modal"
        :header="false"
        :backdrop="false"
        :full="true"
    >
        <div class="text__wrapper">
            <img :src="'/images/permissions/' + image" />
            <h2>{{ title }}</h2>

            <small>{{ description }}</small>
        </div>

        <FormButton
            v-if="hasSettingsButton"
            visual="silver"
            full
            class="mt-2"
            @click="useLazyClick($event, openAppSettings)"
        >
            {{ __('Otvoriť nastavenia aplikácie') }}
        </FormButton>
        <FormButton
            v-else
            type="submit"
            full
            class="mt-2"
            @click="
                useLazyClick(
                    $event,
                    async () => await callPermissionsCallback(data.callback)
                )
            "
        >
            {{ button }}
        </FormButton>
    </DynamicModal>
</template>

<script setup>
const props = defineProps({
    image: {},
    title: {},
    description: {},
    button: {},
    modal: {},
});

const hasSettingsButton = ref(false);
let _showDelayedButtonTimeout;
useOnModalOpen(props.modal, () => {
    hasSettingsButton.value = false;
});

const openAppSettings = async () => {
    if (_showDelayedButtonTimeout) {
        clearTimeout(_showDelayedButtonTimeout);
    }

    try {
        useOpenPermissions();
    } catch (error) {
        console.error('Failed to open settings:', error);
    }

    await useSleep(500);

    hasSettingsButton.value = false;
};

const callPermissionsCallback = async (callback) => {
    //After long loading, show settings button
    _showDelayedButtonTimeout = setTimeout(() => {
        hasSettingsButton.value = true;
    }, 3000);

    let response;
    try {
        response = await callback();
    } catch (e) {
        //.
    }

    hasSettingsButton.value = true;

    return response;
};
</script>

<style scoped lang="scss">
.text__wrapper {
    padding: 1rem 2rem;
    text-align: center;

    h2 {
        margin: 1rem 0;
        font-size: 1.9rem;
        font-weight: 600;
    }

    small {
        font-size: 1.2rem;
        color: #021e26;
        opacity: 0.5;
    }
}
</style>
