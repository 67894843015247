import { Geolocation } from '@capacitor/geolocation';

import {
    NativeSettings,
    AndroidSettings,
    IOSSettings,
} from 'capacitor-native-settings';

export const useOpenPermissions = () => {
    try {
        NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App,
        });
    } catch (error) {
        console.error('Failed to open settings:', error);
    }
};

export const showLocationErrorToast = (shouldBeGranted = false) => {
    // prettier-ignore
    const message = shouldBeGranted
        ? __('Nebolo možné získať aktuálnu polohu. Skontroluje prístup k presnej polohe ziariadenia v nastaveniach aplikácie.')
        : __('Prístup k polohe nebol udelený. Skontrolujte povolenia v nastaveniach aplikácie a skúste znova.');

    useShowErrorToast({
        // prettier-ignore
        message,
        duration: 5000,
        click() {
            useOpenPermissions();
        },
    });
};

export const useLocationPermissionsModal = async (callback) => {
    let hasPermissions;

    try {
        hasPermissions = await Geolocation.checkPermissions();
    } catch (e) {
        // When location is disabled on whole device
        console.error(e);
    }

    // If location is not granted yet.
    if (hasPermissions?.coarseLocation == 'granted') {
        console.log('Location granted already.', hasPermissions);

        return await callback();
    } else {
        const locationEnabledCallback = async () => {
            console.log('Location granted from modal.');

            useCloseModal('allow-location');

            return await callback();
        };

        return await new Promise((resolve, reject) => {
            useOpenModal('allow-location', {
                callback: async () => {
                    try {
                        // Don't ask on desktop.
                        if (useIsDesktop()) {
                            return resolve(await locationEnabledCallback());
                        }

                        let request = await Geolocation.requestPermissions();

                        if (request.coarseLocation == 'granted') {
                            return resolve(await locationEnabledCallback());
                        } else {
                            showLocationErrorToast();
                        }
                    } catch (e) {
                        showLocationErrorToast();

                        console.error('Location request error: ' + e);

                        resolve();
                    }
                },
            });
        });
    }
};
