<template>
    <div class="event__chat" @click="eventStore.goToChat(event)">
        <div class="event__chat-photo">
            <CircleAvatar
                :src="lastMessage?.user_thumbnail || thumbnail"
                :size="30"
                :avatar="true"
            />
        </div>
        <div class="event__chat-message">
            <template v-if="lastMessage">
                <template v-if="lastMessage.user_username">
                    {{ useFirstName(lastMessage.user_username) }}:
                </template>

                <template v-if="lastMessage.thumbnail">
                    {{ __('Fotografia') }}
                </template>
                <template v-else>{{ lastMessage.message }}</template>

                <i>
                    {{ useDateFormatted(lastMessage.created_at, true) }}
                </i>
            </template>
            <template v-else>
                {{ __('Napíšte správu medzi prvými') }}
            </template>

            <span
                v-if="thread && thread.is_unseen && thread.unseen_count"
                class="--unseen"
            >
                {{ thread.unseen_count }}
            </span>
        </div>
    </div>
</template>

<script setup>
import _ from 'lodash';
const router = useRouter();
const props = defineProps({
    event: {},
});

const chatStore = useChatStore();
const eventStore = useEventStore();
const authStore = useAuthStore();

const thumbnail = computed(() => {
    return authStore.user?.thumbnail;
});

const lastMessage = computed(() => {
    return props.event?.last_message;
});

const thread = computed(() => {
    return _.find(chatStore.getThreads, { event_id: props.event.id });
});
</script>

<style lang="scss">
.event__chat {
    display: flex;
    gap: 0.6rem;
    cursor: pointer;
}

.event__chat-message {
    display: flex;
    padding: 0.8rem 1rem;
    background: var(--background-gray);
    border-radius: 1.6rem 1.6rem 1.6rem 0.3rem;
    flex: 1;
    gap: 1rem;
    font-weight: normal;
    font-size: 1rem;
    align-items: center;
    position: relative;

    i {
        margin-left: auto;
        font-style: normal;
        opacity: 0.6;
        font-size: 10px;
        color: #a3afb2;
    }

    .--unseen {
        font-weight: 700;
        font-size: 1.2rem;
        color: #ffffff;
        text-align: center;
        height: 2rem;
        min-width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--ion-color-primary);
        padding: 0 0.5rem;
        border-radius: 2rem;
        position: absolute;
        top: -1rem;
        right: -1rem;
    }
}
</style>
