<template>
    <button
        class="form-button"
        :class="computedClass"
        :type="type"
        :disabled="disabled || loading"
    >
        <slot name="start" />

        <span class="form-button__text">
            <slot />
        </span>

        <slot name="end" />
    </button>
</template>

<script setup>
const props = defineProps({
    type: {
        default: 'button',
    },
    visual: {
        type: String,
        default: 'primary',
    },
    full: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'md',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    rounded: {
        type: Boolean,
        default: true,
    },
    iconBefore: {
        type: String,
    },
    iconAfter: {
        type: String,
    },
    loading: {
        default: false,
    },
});

const computedClass = computed(() => {
    const loadingClass = props.loading ? '--loading' : '';
    const visualClass = '--' + props.visual;
    const sizeClass = '--' + props.size;
    const iconBeforeClass = props.iconBefore
        ? '--icon-before --icon-before-' + props.iconBefore
        : '';
    const iconAfterClass = props.iconAfter
        ? '--icon-after --icon-after-' + props.iconAfter
        : '';
    const roundedClass = props.rounded ? '--rounded' : '';
    const fullClass = props.full ? '--full' : '';
    return `${loadingClass} ${visualClass} ${sizeClass} ${iconBeforeClass} ${iconAfterClass} ${roundedClass} ${fullClass}`;
});
</script>

<style lang="scss" scoped>
.form-button {
    border: 0;
    padding: 0 2rem;
    height: 4.6rem;
    margin: 0;
    display: inline-flex;
    gap: 1.2rem;
    align-items: center;
    justify-content: center;
    appearance: none;
    text-align: center;
    font-size: 1.2rem;
    letter-spacing: -0.038rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: all 0.2s;
    text-decoration: none;
    font-family: var(--ion-font-family);

    &.--rounded {
        border-radius: 5rem;
    }

    &.--sm {
        height: 4rem;
    }

    &.--xs {
        height: 3rem;
        font-size: 1rem;
    }

    &.--full {
        display: flex;
        width: 100%;
    }

    &.--default {
        color: var(--ion-text-color);
        background: var(--background-gray);
        font-weight: 600;
        font-size: 1.2rem;

        &:focus {
            background: #000;
            color: white;
        }
    }

    &.--primary {
        color: #fff;
        background: var(--ion-color-primary);

        &:hover {
        }
    }

    &.--secondary {
        color: #020310;
        background: #80cad2;

        &:focus {
        }
    }

    &.--silver {
        color: #020310;
        background: #ccd2d4;

        &:focus {
        }
    }

    &.--white {
        color: #020310;
        background: #fff;
    }

    &.--bordered {
        color: var(--ion-text-color);
        background: transparent;
        border: 1px solid rgba(2, 30, 38, 0.2);

        &.--sm {
            font-size: 1.2rem;
        }
    }

    &.--google {
        color: #000;
        background: transparent;
        border: 1px solid #c8c8c8;
        font-weight: 500;

        &:before {
            content: '';
            width: 2.6rem;
            height: 2.6rem;
            background: url($basePath + 'icons/logo-google.svg') no-repeat
                center;
        }
    }

    &.--apple {
        color: #ffffff;
        background: #000;
        font-weight: 500;

        &:before {
            content: '';
            width: 2.6rem;
            height: 2.6rem;
            background: url($basePath + 'icons/logo-apple.svg') no-repeat center;
        }
    }

    &:focus {
        outline: none;
    }

    &[disabled],
    &.--disabled {
        opacity: 0.5;
    }

    :deep(small) {
        font-size: 1rem;
        font-weight: 400;
    }
}

.form-button__text {
    display: flex;
    align-items: center;
    gap: 0.8rem;
}
</style>
