<template>
    <ModalWrapper
        :is-open="useIsModalOpen(modal)"
        class="full-modal"
        :authorized="true"
    >
        <AppHeader :title="__('Nahlásiť používateľa')" :borderless="true">
            <template v-slot:back>
                <ion-back-button
                    text=""
                    :defaultHref="''"
                    :icon="arrowBack"
                    @click="useCloseModal(modal)"
                ></ion-back-button>
            </template>
        </AppHeader>

        <ion-content class="ion-padding">
            <form
                id="report_form"
                v-autoAjax
                method="post"
                action="/api/report"
                @success="success"
                @loading="loading = $event"
            >
                <div class="full-modal__content">
                    <h2>{{ __('Čo nám chceš nahlásiť?') }}</h2>

                    <input type="hidden" name="type" :value="reportType" />

                    <input
                        type="hidden"
                        name="reported_user_id"
                        :value="data?.user?.id"
                        v-if="data?.user"
                    />

                    <FormCheckbox
                        v-for="(
                            option, optionKey
                        ) in settingStore.reportReasons"
                        :class="{ '--radio': true }"
                        :key="optionKey"
                        labelPlacement="start"
                        :checked="reportType === optionKey"
                        @click="reportType = optionKey"
                    >
                        <div class="level-option">
                            <div class="title">
                                {{ option.name }}
                            </div>
                        </div>
                    </FormCheckbox>

                    <div v-if="reportType" class="mt-4">
                        <h2>{{ __('Napíšte nám o vašej skúsenosti') }}</h2>

                        <FormTextarea
                            ref="textarea"
                            :placeholder="__('Mám problém s...')"
                            rows="1"
                            :autoGrow="true"
                            name="message"
                        />
                    </div>
                </div>
            </form>
        </ion-content>

        <ion-footer class="ion-no-border">
            <ion-toolbar class="--withIndicator">
                <div class="px-2">
                    <!-- prettier-ignore -->
                    <small class="disclaimer">{{ __('Používateľ sa nedozvie, že si ho/ju nahlásil/a') }}</small>

                    <FormButton
                        form="report_form"
                        type="submit"
                        full
                        :disabled="!reportType"
                    >
                        {{ __('Nahlásiť') }}
                    </FormButton>
                </div>
            </ion-toolbar>
        </ion-footer>
    </ModalWrapper>
</template>

<script setup>
import { arrowBack } from 'ionicons/icons';

const settingStore = useSettingStore();

const props = defineProps({
    modal: { default: 'report-account' },
});

const reportType = ref();

const data = computed(() => useGetModalData(props.modal));

useOnModalOpen(props.modal, (data) => {
    reportType.value = null;
});

const success = async (response) => {
    useAutoAjaxResponse(response);

    useCloseModal(props.modal);
    useOpenModal('report-submitted', data.value);
};

// Testing
// useOpenModal(props.modal, { user: useAuthStore().user });
</script>

<style lang="scss" scoped>
small.disclaimer {
    display: block;
    margin-bottom: 1rem;
    color: var(--ion-text-color-lighter);
    font-size: 1rem;
}

ion-footer {
    ion-toolbar {
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

h2 {
    font-size: 1.4rem;
}

:deep(.--radio) {
    border: 1px solid #dddddd;
    padding: 1.2rem 1.5rem 1.2rem 0.5rem;
    border-radius: 0.6rem;
}
</style>
