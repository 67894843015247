<template>
    <AppHeader :title="name" :borderless="true">
        <template v-slot:back>
            <ion-back-button
                @click="goBack()"
                text=""
                :defaultHref="''"
                :icon="arrowBack"
            ></ion-back-button>
        </template>

        <ion-buttons>
            <ion-button
                @click="
                    useCloseModal('create-event');
                    eventStore.reset();
                "
                class="--close"
            >
                <ion-icon :icon="closeOutline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </AppHeader>

    <ion-content class="ion-padding" ref="wrapper">
        <div class="full-modal__content" :class="[props.class || 'py-2']">
            <slot />
        </div>
    </ion-content>

    <ion-footer class="ion-no-border">
        <ion-toolbar class="--withIndicator">
            <div class="indicator">
                <div
                    class="current"
                    :style="`
                        --fromWidth: ${(eventStore.previousStep * 100) / eventStore.steps}%;
                        --toWidth: ${(eventStore.currentStep * 100) / eventStore.steps}%;
                    `"
                ></div>
            </div>
            <div class="px-1">
                <slot name="footer" :goNext="goNext">
                    <FormButton
                        form="event_form"
                        :type="canSubmit ? 'button' : 'submit'"
                        full
                        :class="{ '--disabled': canSubmit }"
                        :loading="loading"
                        @click="goNext()"
                    >
                        {{ buttonName || __('Pokračovať') }}
                    </FormButton>
                </slot>
            </div>
        </ion-toolbar>
    </ion-footer>
</template>

<script setup>
import { arrowBack, closeOutline } from 'ionicons/icons';

const emit = defineEmits(['continue']);
const eventStore = useEventStore();
const wrapper = ref();

const props = defineProps({
    name: {
        default: '',
    },
    disabled: {
        default: false,
    },
    class: {},
    errors: {},
    buttonName: {},
    loading: { default: false },
});

const canSubmit = computed(() => props.disabled || props.errors);

const goNext = (add = 1) => {
    emit('continue');

    //Next tick due to potentional parent disable changed
    nextTick(() => {
        if (props.disabled) {
            return;
        }

        if (props.errors) {
            return useShowErrorToast(props.errors);
        }

        const nextStep = eventStore.currentStep + add;

        //Go to next
        if (nextStep <= eventStore.steps) {
            eventStore.currentStep = nextStep;
        }
    });
};

const goBack = () => {
    if (eventStore.currentStep == 1) {
        useCloseModal();
    } else {
        eventStore.currentStep--;
    }
};
</script>

<style scoped>
ion-footer {
    ion-toolbar {
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0.5rem;
    background: #ebebeb;
    z-index: 10;

    .current {
        height: 100%;
        background: #000000;
        animation-name: slideTo;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;

        width: var(--fromWidth);
    }
}

@keyframes slideTo {
    0% {
        width: var(--fromWidth);
    }
    100% {
        width: var(--toWidth);
    }
}
</style>
