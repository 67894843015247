import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

export default defineNuxtPlugin(({}) => {
    const apiKey = import.meta.env.VITE_API_GOOGLE_BROWSER_KEY;

    // Don't fire firebase in local environment
    if (!apiKey) {
        return {};
    }

    const firebaseConfig = {
        apiKey,
        authDomain: 'scorentino-app.firebaseapp.com',
        projectId: 'scorentino-app',
        storageBucket: 'scorentino-app.appspot.com',
        messagingSenderId: '785299604296',
        appId: '1:785299604296:web:0ccf6401bad6f7dcc2a97d',
        measurementId: 'G-HJ0ERB055G',
    };

    const isEnabled = isTrackingEnabled();

    const app = initializeApp(isEnabled ? firebaseConfig : {});
    const analytics = isEnabled ? getAnalytics(app) : null;

    console.log(
        'Firebase initialized. With analytics: ' +
            (isTrackingEnabled() ? 'ON' : 'OFF')
    );

    return {
        provide: {
            firebaseApp: app,
            firebaseAnalytics: analytics,
        },
    };
});
