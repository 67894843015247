<template>
    <DynamicModal v-slot="{ loading, value, change }">
        <div>
            <FormCheckbox
                v-for="option in options"
                :disabled="loading"
                :class="{ '--radio': isSingle }"
                :key="option[valueProp]"
                :checked="isChecked(value, option)"
                :labelPlacement="labelPlacement"
                @click="
                    props.toggle
                        ? props.toggle(change, value, option)
                        : toggle(change, value, option)
                "
            >
                <slot
                    name="option"
                    :option="option"
                    :value="value"
                    :change="change"
                >
                    {{ option.name }}
                </slot>
            </FormCheckbox>
        </div>

        <slot />
    </DynamicModal>
</template>

<script setup>
import _ from 'lodash';

const props = defineProps({
    type: {
        default: 'checkbox',
    },
    options: {
        default: [],
    },
    valueProp: {
        default: 'id',
    },
    toggle: {},
    checked: {},
    labelPlacement: {
        default: 'end',
    },
});

const options = computed(() => {
    const o = props.options;

    if (_.isObject(o) && !_.isArray(o)) {
        return _.map(props.options, (value, key) => {
            const obj = {};
            obj[props.valueProp] = key;

            //Add support for objects
            if (typeof value == 'object' && value.name) {
                value = value.name;
            }

            return { ...obj, name: value };
        });
    }

    return o;
});

const isSingle = computed(() => props.type == 'radio');

const isChecked = (value, option) => {
    // Custo, checked feature
    if (props.checked) {
        return props.checked(value, option) ? true : false;
    }

    let iv = option[props.valueProp];

    if (isSingle.value) {
        return value === iv || (_.isNil(value) && _.isNil(iv));
    } else {
        let val = _.castArray(value);

        return val?.includes(iv) || (_.isNil(iv) && val.length == 0);
    }
};

const toggle = (change, value, option) => {
    const id = option[props.valueProp],
        reset = _.isNil(id);

    if (isSingle.value) {
        change(reset ? null : value == id ? null : id);
    } else {
        change(reset ? [] : Object.values(_.xor(value || [], [id])));
    }
};
</script>
