<template>
    <DynamicModal v-slot="{ loading, value, change, $cleanAttrs }" :full="true">
        <component
            v-if="type"
            :is="comps[type]"
            :modelValue="value"
            @change="change($event.target.value)"
            class="mt-0"
            v-bind="$cleanAttrs"
        />
    </DynamicModal>
</template>

<script setup>
import FormTextarea from '~/components/form/FormTextarea.vue';
import FormText from '~/components/form/FormText.vue';

const props = defineProps({
    type: {
        type: String,
        default: 'textarea',
    },
});

const comps = {
    textarea: FormTextarea,
    text: FormText,
};
</script>

<style lang="scss" scoped></style>
