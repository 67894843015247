<template>
    <div
        class="event__cta"
        :class="{
            '--smallPadding': !buttons.share,
            '--smallWide': !hasValidTermine,
        }"
    >
        <div
            class="icon-btn"
            v-if="hasValidTermine && buttons.set_date"
            @click="updateTermine()"
        >
            <span><ion-icon :icon="calendarClearOutline" /></span>
            {{ termine.has_time ? __('Zmena') : __('Nastaviť čas') }}
        </div>

        <div
            class="icon-btn"
            v-if="!hasValidTermine && buttons.duplicate"
            @click="duplicateTermine()"
        >
            <span><ion-icon :icon="calendarClearOutline" /></span>
            {{ __('Duplikovať') }}
        </div>

        <div
            class="icon-btn"
            v-if="hasValidTermine && buttons.location"
            @click="updateEvent()"
        >
            <span><ion-icon :icon="locationOutline" /></span>
            {{ __('Poloha') }}
        </div>

        <div v-if="buttons.share" class="icon-btn" @click="setShareOpen(true)">
            <span><ion-icon :icon="shareOutline" /></span>
            {{ __('Zdielať') }}
        </div>

        <div
            class="icon-btn"
            @click="useLazyClick($event, eventAttendance)"
            v-if="hasValidTermine && buttons.attendance"
        >
            <span class="position-relative">
                <ion-icon :icon="peopleOutline" />

                <ion-badge v-if="termine.attendees_accepted" color="secondary">
                    {{ termine.attendees_accepted }}
                </ion-badge>
            </span>
            {{ __('Účasť') }}
        </div>

        <div
            class="icon-btn"
            ref="requestButton"
            @click="useLazyClick($event, eventRequests)"
            v-if="hasValidTermine && buttons.requests"
        >
            <span class="position-relative">
                <ion-icon :icon="personAddOutline" />

                <ion-badge v-if="termine.attendees_pending">
                    {{ termine.attendees_pending }}
                </ion-badge>
            </span>

            {{ __('Žiadosti') }}
        </div>

        <div v-if="buttons.other" class="icon-btn" @click="eventMode">
            <span><ion-icon :icon="ellipsisHorizontal" /></span>
            {{ __('Viac') }}
        </div>
    </div>
</template>

<script setup>
import {
    calendarClearOutline,
    shareOutline,
    peopleOutline,
    personAddOutline,
    ellipsisHorizontal,
    locationOutline,
} from 'ionicons/icons';

import _ from 'lodash';
const route = useRoute();

const props = defineProps({
    event: {},
    termine: {},
    buttons: {},
});

const eventStore = useEventStore();

const requestButton = ref(null);

//Get fresh event & termine if has been updated
const event = computed(() => eventStore.getEvent(props.event) || props.event);
const termine = computed(
    () => eventStore.getTermine(props.termine) || props.termine
);

const buttons = computed(() => {
    return {
        set_date: true,
        duplicate: true,
        location: eventLocationKey(event.value) ? false : true, //display by default only when no location is set
        attendance: true,
        requests: true,
        share: true,
        other: true,
        ...props.buttons,
    };
});

onMounted(() => {
    autoFireRequests();
});

const hasValidTermine = computed(
    () =>
        termine.value &&
        !termine.value.canceled_at &&
        isFutureTermine(termine.value)
);

const updateEvent = () => {
    useOpenModal('create-event', {
        step: 2,
        event: event.value,
        termine: termine.value,
    });
};

const updateTermine = () => {
    useOpenModal('event-termine-update', {
        event: event.value,
        termine: termine.value,
    });
};

const duplicateTermine = () => {
    useOpenModalCloseCurrent('event-termine-update', {
        event: event.value,
        termine: null,
    });
};

const eventRequests = async () => {
    await useWithAttendees(termine.value, (attendees) => {
        useOpenModal('event-requests', {
            event: event.value,
            termine: termine.value,
            attendees,
        });
    });
};

const eventAttendance = async () => {
    await useWithAttendees(termine.value, (attendees) => {
        useOpenModal('event-attendance', {
            event: event.value,
            termine: termine.value,
            attendees,
        });
    });
};

const eventMode = () => {
    useOpenModal('event-actions', {
        event: event.value,
        termine: termine.value,
    });
};

const autoFireRequests = () => {
    if (
        route.name == 'app-event-slug' &&
        route?.query?.n == 'event-request' &&
        hasValidTermine.value
    ) {
        //To show button loading
        requestButton.value?.click();
    }
};

//Testing
// eventRequests();
// updateTermine();
// eventAttendance();
</script>

<style lang="scss" scoped>
.event__cta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 1.5rem;
    padding: 0 0.5rem;

    &.--smallPadding {
        padding: 0 1.5rem;
    }

    &.--smallWide {
        justify-content: flex-start;
    }
}

.icon-btn {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 0.9rem;
    position: relative;
    cursor: pointer;
    flex: 1;

    span {
        width: 4rem;
        height: 4rem;
        border: 1px solid var(--border-dark-color);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        margin-bottom: 0.3rem;
        align-self: center;
    }

    ion-badge {
        position: absolute;
        top: -0.75rem;
        right: -0.75rem;

        --padding-start: 6px;
        --padding-end: 6px;
    }
}
</style>
