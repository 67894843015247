<template>
    <div class="placeholder-image" v-if="!loaded">
        <ion-spinner name="circular"></ion-spinner>

        <h2 v-if="title" class="mt-2">{{ title }}</h2>
    </div>

    <img :src="src" alt="" @load="loaded = true" v-bind="$attrs" v-if="src" />
</template>

<script setup>
defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    src: {},
    title: {
        default: null,
    },
});

const loaded = ref(false);

watch(
    () => props.src,
    () => {
        loaded.value = false;
    }
);
</script>

<style lang="scss">
.placeholder-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: absolute;
    color: var(--ion-text-color);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: silver;
        filter: blur(5rem);
    }

    h2 {
        z-index: 2;
        font-weight: 600;
    }
}
</style>
