<template>
    <div class="input-group">
        <div
            class="input-group__item"
            @click="useOpenModal('event_date')"
            :key="form.date"
        >
            <label>{{ __('Dátum') }}</label>
            <div
                class="input-group__value"
                :class="{ '--placeholder': !form.date }"
                data-virtual-input="date"
            >
                {{ form.date ? useDateFormatted(form.date) : __('Vybrať') }}
            </div>
        </div>
        <div
            class="input-group__item"
            @click="useOpenModal('event_time')"
            :key="form.time"
        >
            <label>{{ __('Čas') }}</label>
            <div
                class="input-group__value"
                :class="{ '--placeholder': !form.time }"
                data-virtual-input="date"
            >
                <!-- prettier-ignore -->
                {{ form.time ? moment(form.time).format('HH:mm') : __('Vybrať') }}
            </div>
        </div>
    </div>
</template>

<script setup>
import moment from 'moment';
const eventStore = useEventStore();

const form = computed(() => eventStore.form);
</script>
