<template>
    <div class="participant">
        <div class="participant__header">
            <CircleAvatar
                :src="attendee.thumbnail"
                :size="40"
                :avatar="true"
                @click="emit('accountClick', attendee)"
            />

            <div
                class="participant__user"
                @click="emit('accountClick', attendee)"
            >
                <h3 class="participant__user-name">{{ attendee.username }}</h3>

                <!-- TODO -->
                <!-- <div class="participant__stars" v-if="attendee.rating">
                    <strong>
                        <ion-icon :icon="star" />
                    </strong>
                    <div>{{ attendee.rating }}</div>
                </div> -->
            </div>

            <div class="participant__cta">
                <slot />

                <!-- <template v-if="!isRequest">
                    <ion-button fill="clear" class="trash-btn">
                        <ion-icon :icon="trashOutline"></ion-icon>
                    </ion-button>

                    <span class="check-icon --going" v-if="going && responde">
                        <ion-icon :icon="checkmarkOutline"></ion-icon>
                    </span>

                    <span
                        class="check-icon --notgoing"
                        v-if="!going && responde"
                    >
                        <ion-icon :icon="closeOutline"></ion-icon>
                    </span>
                </template> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    star,
    trashOutline,
    checkmarkOutline,
    closeOutline,
} from 'ionicons/icons';

defineProps({
    going: {
        type: Boolean,
        default: false,
    },
    responde: {
        type: Boolean,
        default: true,
    },
    attendee: {},
});

const emit = defineEmits(['cancel', 'accept', 'accountClick']);
</script>

<style lang="scss" scoped>
.participant__header {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.participant__user {
    font-weight: normal;
    font-size: 1rem;
    color: #020310;
    flex: 1;
    margin-right: 2rem;
}

.participant__user-name {
    font-weight: 600;
    font-size: 1.3rem;
    margin: 0;
    padding: 1rem;
}

.participant__stars {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    margin-top: 0.3rem;

    strong,
    span {
        display: flex;
        align-items: center;
        gap: 0.2rem;
        color: rgba(255, 255, 255, 0.4);
    }

    strong {
        color: var(--ion-color-primary);
    }
}

.participant__cta {
    margin-left: auto;
    display: flex;
    gap: 1rem;
    align-items: center;
}

:deep(.trash-btn) {
    width: 4rem;
    height: 4rem;
    border: 1px solid #dddddd;
    border-radius: 50%;

    ion-icon {
        color: #7e8e93;
    }
}

:deep(.check-icon) {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    &.--disabled {
        pointer-events: none;
    }

    &.--request {
        border: 1px solid #dddddd;

        ion-icon {
            color: #7e8e93;
            font-size: 1.8rem;
        }
    }

    &.--going ion-icon {
        font-size: 1.8rem;
    }

    &.--notgoing ion-icon {
        font-size: 1.8rem;
    }
}

:deep(.check-icon),
:deep(.trash-btn) {
    &.--going {
        border: none;
        background: rgba(#21c17c, 0.2);

        ion-icon {
            color: #21c17c;
        }
    }

    &.--notgoing {
        border: none;
        background: rgba(#eb4e27, 0.2);

        ion-icon {
            color: #eb4e27;
        }
    }
}
</style>
