<template>
    <CheckboxModal
        modal="localization"
        :name="__('Your language')"
        type="radio"
        :options="localeStore.languages"
        v-model="localeStore.locale"
        valueProp="slug"
        :chooseText="__('Choose')"
        isLazy
        @input="onLocaleChange"
    />
</template>

<script setup>
const localeStore = useLocaleStore();

const onLocaleChange = async (event) => {
    localeStore.locale = event.value;

    await useAppStore().refreshApp();

    event.callback();

    window.location.reload();
};
</script>
