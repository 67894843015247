import _ from 'lodash';

export const useContinueAfterOnLogIn = () => {
    const userStore = useAuthStore();

    const isLoginModalOpened =
        useIsModalOpen('login-modal') || useIsModalOpen('register-modal');

    //Close authorization modals, if user is logging through them
    if (isLoginModalOpened) {
        useCloseModal('login-modal');
        useCloseModal('register-modal');
    }

    if (userStore.loggedInIncompletely) {
        logEvent('registration_start');
        logEvent('sign_up_with_social');

        useRouter().push({ name: 'registration-registration' });
    } else {
        //If modal is opened, do not redirect to other route. Just show current.
        if (isLoginModalOpened == false) {
            useGoToFeed();
        }
    }

    // Reset position on new login, to submit position to the user.
    useLocationStore().resetPosition();

    setTimeout(async () => {
        // Wait till onboarding is closed
        do {
            await useSleep(2000);
        } while (useOnboardingStore().tutorialOpened === true);

        useRegisterNotifications();
    }, 5000);
};

export const useUpdateProfile = async (data, wrapper, options = {}) => {
    const progress = options?.progress;

    const callback = async () => {
        const payload = useObjectToFormData(data);

        try {
            let response = await useAxios().$postOnline(
                options?.path || '/api/profile',
                payload,
                {
                    onUploadProgress: (e) => {
                        if (progress) {
                            progress(Math.round(e.progress * 100));
                        }
                    },
                }
            );

            useAutoAjaxResponse(response);

            //Close modal on save
            useCloseModal();

            if (options.success) {
                options.success(response);
            }

            // Send events
            Object.keys(data).forEach((key) => {
                // Log event
                logEvent('user_update', {
                    name: key,
                });
            });
        } catch (e) {
            console.error(e);

            useAutoAjaxResponse(e);
        }

        //Reset progressbar
        if (progress) {
            progress(null);
        }
    };

    if (wrapper) {
        await useLazyClick(wrapper, callback);
    } else {
        await callback();
    }
};

export const toggleOnboardingSwipeBack = () => {
    const registerStore = useRegisterStore(),
        authStore = useAuthStore();

    const canSwipeBack =
        registerStore.currentStep == 1 && authStore.loggedIn === false
            ? false
            : true;

    Ionic.config.set('swipeBackEnabled', canSwipeBack);
};
