<template>
    <OptionsModal :modal="modal">
        <template v-if="user">
            <a @click="useLazyClick($event, blockAccount)">
                {{ __('Zablokovať používateľa') }}
            </a>

            <a
                @click="
                    useLazyClick($event, async () => await reportAccount(user))
                "
                class="--red"
            >
                {{ __('Nahlásiť používateľa') }}
            </a>
        </template>
    </OptionsModal>
</template>

<script setup>
const props = defineProps({
    modal: { default: 'account-actions' },
});

//Get termine from local events, to keep updates also when this modal is opened
const user = computed(() => useGetModalData(props.modal)?.user);

const blockAccount = async () => {
    useCloseModal(props.modal);

    await useBlockUser(user.value);
};

const reportAccount = async () => {
    useOpenModalCloseCurrent('report-account', { user: user.value });
};
</script>
