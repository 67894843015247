<template>
    <div class="form-group">
        <div class="form-custom__wrapper" @click="useOpenModal(modal)">
            <span
                class="text_wrapper"
                :class="{ '--placeholder': _.isNil(selectedLabel) }"
            >
                {{ selectedLabel || placeholder || __('Vybrať') }}
            </span>
            <span class="end" v-if="placeholder">
                {{ !selectedLabel ? __('Vybrať') : __('Zmeniť') }}
            </span>
        </div>
    </div>

    <ChooseModal
        v-model="value"
        :modal="modal"
        :name="name"
        :options="options"
        :autoSelect="autoSelect"
        :searchFromItemsCount="searchFromItemsCount"
    >
        <template v-slot:filter>
            <slot name="filter" />
        </template>
    </ChooseModal>
</template>

<script setup>
defineOptions({
    inheritAttrs: false,
});

import _ from 'lodash';

const props = defineProps({
    options: {
        default: [],
    },
    name: {},
    autoSelect: {
        default: true,
    },
    valueProp: {
        default: 'id',
    },
    rounded: {
        type: Boolean,
        default: false,
    },
    placeholder: {},
    modal: {
        default: 'form-choose',
    },
    searchFromItemsCount: {
        default: 10,
    },
});

const value = defineModel();

const selectedLabel = computed(() => {
    const obj = {};
    obj[props.valueProp] = value.value;

    return _.find(props.options, obj)?.name;
});
</script>

<style lang="scss" scoped>
.--placeholder {
    opacity: 0.5;
}

.text_wrapper {
    max-width: calc(100% - 7rem);
}

.choose {
    position: absolute;
    z-index: 5;
    margin: 0;
    padding: 0;
    right: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.2;
    display: block;
}
</style>
