import { getAnalytics, logEvent as _logEvent } from 'firebase/analytics';

let analytics = null;

const bootAnalytics = () => {
    if (analytics) {
        return;
    }

    analytics = getAnalytics();
};

export const isTrackingEnabled = () => {
    if (import.meta.env.VITE_APP_ENVIRONMENT == 'local') {
        return false;
    }

    return true;
};

export const logEvent = (eventName, params = {}) => {
    if (isTrackingEnabled() == false) {
        return;
    }

    params = params || {};

    const authStore = useAuthStore();
    const trackingStore = useTrackingStore();
    const hasCounter = params._counter ? true : false;

    bootAnalytics();

    // Add user id only if user is logged in and has tracking authorized
    if (authStore.user) {
        params.user_id = trackingStore.consent.authorized
            ? authStore.user?.id
            : '-';
    }

    if (hasCounter) {
        delete params._counter;

        // prettier-ignore
        params.seconds_between = trackingStore.getEventDuration(eventName, 'seconds');
        params.counter = trackingStore.getEventCount(eventName);
    }

    console.log('[event]', eventName, params);

    _logEvent(analytics, eventName, params);

    if (hasCounter) {
        trackingStore.snapEvent(eventName);
    }
};
