import autoAjax from 'autoajax';

export default defineNuxtPlugin(({ vueApp, $__ }) => {
    vueApp.use(autoAjax);

    /*
     * AutoAjax options
     */
    autoAjax.setOptions({
        async process(type, url, data) {
            return useAxios()[type](url, data);
        },
        selectors: {
            inputWrapperErrorClass: 'ion-invalid ion-touched',
        },
        messages: {
            // prettier-ignore
            error: $__('Ospravedlňujeme sa, no nastala nečakaná chyba, skúste neskôr prosím.'),
            // prettier-ignore
            validation: $__('Prosíme, prekontroluje správnosť vyplnených údajov.'),
        },
        error(e, response) {
            useThrowConnectionAlert();

            useAutoAjaxResponse(response);
        },
        validation() {
            useShowErrorToast(autoAjax.options.messages.validation);
        },

        getErrorMessageElement(message) {
            return (
                '<div class="input-bottom sc-ion-input-ios"><span class="error-text sc-ion-input-ios">' +
                message +
                '</span></div>'
            );
        },
        getInputParentWrapper(input) {
            return input.closest(
                'ion-input, ion-textarea, ion-checkbox, .form-file, .input-group__item'
            );
        },
        addErrorMessageAfterElement(input) {
            //Checkbox
            if (input.classList.contains('aux-input')) {
                return input;
            }

            if (input.classList.contains('form-file__input')) {
                return input.nextSibling;
            }

            if (input.classList.contains('input-group__value')) {
                return input;
            }

            return input.closest('.input-wrapper, .textarea-wrapper');
        },
    });
});
