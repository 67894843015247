import revive_payload_client_4sVQNw7RlN from "/var/www/scorentino.com/data/sub/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/scorentino.com/data/sub/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_h7T2ZmMnVl from "/var/www/scorentino.com/data/sub/app/node_modules/@nuxtjs/ionic/dist/runtime/router.mjs";
import payload_client_yVLowv6hDl from "/var/www/scorentino.com/data/sub/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/scorentino.com/data/sub/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/scorentino.com/data/sub/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/scorentino.com/data/sub/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/scorentino.com/data/sub/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/scorentino.com/data/sub/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/scorentino.com/data/sub/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/var/www/scorentino.com/data/sub/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import ionic_JZxaXwxCQa from "/var/www/scorentino.com/data/sub/app/node_modules/@nuxtjs/ionic/dist/runtime/ionic.mjs";
import plugin_AUP22rrBAc from "/var/www/scorentino.com/data/sub/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _01_app_client_RdKL24Z2gS from "/var/www/scorentino.com/data/sub/app/plugins/01.app.client.js";
import _02_translator_oAbxOL16Jx from "/var/www/scorentino.com/data/sub/app/plugins/02.translator.js";
import _03_autoajax_ST7S72y7Jk from "/var/www/scorentino.com/data/sub/app/plugins/03.autoajax.js";
import _04_firebase_A29RLndE27 from "/var/www/scorentino.com/data/sub/app/plugins/04.firebase.js";
import _05_setup_client_s6OP4A70Kz from "/var/www/scorentino.com/data/sub/app/plugins/05.setup.client.js";
import _06_router_a6WnGnVV12 from "/var/www/scorentino.com/data/sub/app/plugins/06.router.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_h7T2ZmMnVl,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  ionic_JZxaXwxCQa,
  plugin_AUP22rrBAc,
  _01_app_client_RdKL24Z2gS,
  _02_translator_oAbxOL16Jx,
  _03_autoajax_ST7S72y7Jk,
  _04_firebase_A29RLndE27,
  _05_setup_client_s6OP4A70Kz,
  _06_router_a6WnGnVV12
]