export const useAdsStore = defineStore('ads', {
    persist: true,

    state() {
        return {
            initialized: false,
            loading: false,
            isAdCloseDisabled: false,
            ads: {},
        };
    },

    actions: {
        reset() {
            this.loading = false;
            this.isAdCloseDisabled = false;
        },
    },
    getters: {
        getAdKey(state) {
            return (key) => {
                if (state.ads[key]) {
                    return state.ads[key][useGetFinalPlatformName()];
                }

                return null;
            };
        },
    },
});
