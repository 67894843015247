<template>
    <AppModal :modal="modal" :title="__('Pozvať do hry')">
        <div>
            <FormLabel>{{ __('Vyberte partičku') }}</FormLabel>
            <FormChoose
                v-model="eventId"
                :placeholder="__('Vyberte partičku')"
                :name="__('Vyberte partičku')"
                :options="events"
                :modal="modal + '-choose'"
                :searchFromItemsCount="0"
            >
                <template v-slot:filter>
                    <div class="app__tabs">
                        <ion-segment v-model="filterTab">
                            <ion-segment-button value="my">
                                <ion-label>
                                    {{ __('Moje partičky') }}
                                </ion-label>
                            </ion-segment-button>

                            <ion-segment-button value="attended">
                                <ion-label>
                                    {{ __('Zúčastnil som sa') }}
                                </ion-label>
                            </ion-segment-button>
                        </ion-segment>
                    </div>

                    <!-- prettier-ignore -->
                    <EmptyResults
                        :text="__('Pre pozvanie do hry vytvor aspoň jednu partičku.')"
                        v-if="events.length == 0"
                        class="mt-2"
                    />
                </template>
            </FormChoose>
        </div>

        <div class="mt-2">
            <FormButton
                full
                @click="useLazyClick($event, invitePlayer)"
                :disabled="!eventId"
            >
                {{ __('Odoslať pozvánku') }}
            </FormButton>
        </div>
    </AppModal>
</template>

<script setup>
import { shareOutline, copyOutline } from 'ionicons/icons';
import _ from 'lodash';
import moment from 'moment';

const props = defineProps({
    modal: {
        default: 'user-invite',
    },
});

const eventStore = useEventStore();
const eventId = ref();
const filterTab = ref('my');

const emit = defineEmits(['close']);

const events = computed(() => {
    let events = eventStore.events.filter(
        (event) => event.type?.includes('-search') == false
    );

    //Filter by selected tab
    events = events.filter((event) => {
        // Only my events
        if (filterTab.value == 'my') {
            return useIsMe(event.user_id);
        }

        // External events
        return !useIsMe(event.user_id);
    });

    // Sort by closest termine to current date
    events = _.orderBy(
        events,
        (event) => {
            return (event.termines || [])
                .map((t) => {
                    return t.date && isFutureTermine(t)
                        ? moment().diff(moment(t.date), 'seconds')
                        : 0;
                })
                .filter((n) => n <= 0)
                .sort()[0];
        },
        'asc'
    );

    return events.map((event) => {
        return { id: event.id, name: eventStore.getName(event) };
    });
});

useOnModalOpen(props.modal, () => {
    eventId.value = null;
});

const invitePlayer = async () => {
    try {
        let response = await useAxios().$postOnline(
            '/api/events/' + eventId.value + '/invite',
            {
                user_id: useGetModalData(props.modal).user_id,
            }
        );

        useAutoAjaxResponse(response);

        useCloseModal(props.modal);
    } catch (e) {
        useAutoAjaxResponse(e);
    }
};
</script>

<style lang="scss" scoped>
.copy__icon {
    color: #000;
    font-size: 1.6rem;
}

.copy__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    margin-top: 1.5rem;
}

.icon-btn {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 0.9rem;

    span {
        width: 4rem;
        height: 4rem;
        border: 1px solid #dddddd;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        margin-bottom: 0.3rem;
    }
}
</style>
