export const useOnboardingStore = defineStore('onboarding', {
    persist: true,

    state() {
        return {
            boarded: false,
            tutorial: false,
            tutorialOpened: false,
            images: [],
        };
    },

    actions: {},

    getters: {},
});
