<template>
    <EventDateInputs v-if="withDate" />

    <FormCheckbox v-model="form.repeat" class="mt-2">
        {{ __('Opakovať týždenne') }}
    </FormCheckbox>

    <div v-if="form.repeat">
        <h3 class="small-title mt-2">
            {{ __('Opakovať každý deň v týždni') }}
        </h3>
        <div class="days mt-1">
            <label class="day" v-for="day in 7" :key="day">
                <input
                    type="checkbox"
                    name="day"
                    class="day__input"
                    :checked="isDaySelected(day)"
                    @click="enableDay(day)"
                />
                <span class="day__name">
                    {{ moment().set('day', day).format('dd') }}
                </span>
            </label>
        </div>
    </div>
</template>

<script setup>
import _ from 'lodash';
import moment from 'moment';
const eventStore = useEventStore();

const props = defineProps({
    withDate: {
        default: true,
    },
});

const form = computed(() => eventStore.form);

const model = defineModel('errors');

onMounted(() => {
    // form.value.date = null;
    // form.value.time = null;
});

const enableDay = (day) => {
    form.value.repeat_days = _.xor(form.value.repeat_days, [day]).sort();
};

const isDaySelected = (d) => {
    return form.value.repeat_days.includes(d);
};

const getValidationError = computed(() => {
    if (form.value.repeat) {
        if (form.value.repeat_days.length == 0) {
            // prettier-ignore
            return __('Pre opakovanie udalosti vyberte aspoň jeden deň v týžni pre opakovanie.');
        }
    }

    if (props.withDate) {
        if (!form.value.date || !form.value.time) {
            return __('Vyberte dátum a čas aktivity.');
        }

        if (eventStore.dateMerged < moment()) {
            return __('Vyberte čas v budúcnosti.');
        }
    }

    return false;
});

watch(
    () => getValidationError.value,
    (state) => {
        model.value = state;
    },
    {
        immediate: true,
    }
);
</script>

<style lang="scss" scoped>
.days {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.day {
    position: relative;
}

.day__input {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;

    &:checked ~ .day__name {
        border-color: transparent;
        background: #001f27;
        color: #fff;
    }
}

.day__name {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 1.4rem;
    color: #001f27;
    text-align: center;
    border: 1px solid #dddddd;
    border-radius: 50%;
    text-transform: capitalize;
}

.small-title {
    font-weight: 600;
    font-size: 1.2rem;
    color: #000000;
    margin: 0;
}
</style>
