<template>
    <AppModal :modal="modal" :title="title">
        <div>
            <FormLabel>{{ __('Odkaz') }}</FormLabel>
            <div class="position-relative">
                <FormText v-model="link" class="share__input" />
                <ion-button
                    fill="clear"
                    class="copy__btn"
                    @click="copyToClipBoard()"
                >
                    <ion-icon :icon="copyOutline" class="copy__icon"></ion-icon>
                </ion-button>
            </div>
        </div>
        <div class="copy__cta" v-if="canShare" @click="initializeShare()">
            <div class="icon-btn">
                <span><ion-icon :icon="shareOutline" /></span>
                {{ __('E-mail') }}
            </div>
            <div class="icon-btn">
                <span><ion-icon :icon="shareOutline" /></span>
                {{ __('SMS') }}
            </div>
            <div class="icon-btn">
                <span><ion-icon :icon="shareOutline" /></span>
                {{ __('Instagram') }}
            </div>
            <div class="icon-btn">
                <span><ion-icon :icon="shareOutline" /></span>
                {{ __('Facebook') }}
            </div>
        </div>

        <div class="mt-2">
            <FormButton full @click="initializeShare()">
                {{ canShare ? __('Zdieľať') : __('Skopírovať') }}
            </FormButton>
        </div>
    </AppModal>
</template>

<script setup>
import { shareOutline, copyOutline } from 'ionicons/icons';
import { Share } from '@capacitor/share';

const props = defineProps({
    modal: { default: 'share' },
});

const canShare = ref(false);
const emit = defineEmits(['close']);

const title = computed(() => {
    return data.value?.title || __('Zdieľajte odkaz');
});

const data = computed(() => {
    return useGetModalData(props.modal) || {};
});

onMounted(async () => {
    canShare.value = (await Share.canShare())?.value || false;
});

useOnModalOpen(props.modal, () => {
    if (canShare.value) {
        initializeShare();
    }
});

const link = computed(() => {
    const host = useBackendEnv('APP_NUXT_URL');

    return data.value?.url ? host + data.value.url : host;
});

const copyToClipBoard = () => {
    useCopyToClipboard(link.value);

    useShowSuccessToast(__('Odkaz bol skopirovaný.'));
};

const initializeShare = async () => {
    if (canShare.value) {
        useCloseModal(props.modal);

        await Share.share({
            title: data.value.shareTitle,
            url: link.value,
        });
    } else {
        copyToClipBoard();

        useCloseModal(props.modal);
    }
};
</script>

<style lang="scss" scoped>
:deep(.share__input) ion-input .input-wrapper {
    --padding-end: 4.5rem;

    input {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.copy__btn {
    position: absolute;
    z-index: 5;
    margin: 0;
    padding: 0;
    right: 0.6rem;
    top: 50%;
    transform: translate(0, -50%);
}

.copy__icon {
    color: #000;
    font-size: 1.6rem;
}

.copy__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    margin-top: 1.5rem;
}

.icon-btn {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 0.9rem;

    span {
        width: 4rem;
        height: 4rem;
        border: 1px solid #dddddd;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        margin-bottom: 0.3rem;
    }
}
</style>
