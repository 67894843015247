<template>
    <i class="form-info">
        <slot />
    </i>
</template>

<style lang="scss" scoped>
.form-info {
    opacity: 0.7;
    font-weight: 400;
    font-size: 0.9rem;
    color: #001f27;
    display: block;
    font-style: normal;
    margin-top: -0.5rem;
}
</style>
