import _ from 'lodash';

export const useSportStore = defineStore('sport', {
    persist: true,

    state() {
        return {
            sports: [],
            positions: [],
            locations: [],
            covers: [],
            services: [],
            levels: [],
        };
    },

    actions: {},

    getters: {
        getById(state) {
            return (id) => {
                return _.find(state.sports, { id: parseInt(id) });
            };
        },
        getIcon() {
            return (sport) => {
                return (
                    sport.iconThumbnail ||
                    '/images/sport-icons/' + _.random(1, 7) + '.svg'
                );
            };
        },
        sportsSorted() {
            const otherId = useBackendEnv('SPORT_OTHERS_ID');

            const userSportsIds = (useAuthStore().user?.assignedSportsLevels || []).map(
                (item) => item.sport_id
            );

            let sports = _.orderBy(this.sports, 'name', 'asc')
                .filter((s) => s.id != otherId)
                .concat([_.find(this.sports, { id: otherId })].filter((i) => i))
                .map((s) => ({
                    ...s,
                    icon: this.getIcon(s),
                }));

            return _.orderBy(
                sports,
                (sport) => {
                    return userSportsIds.includes(sport.id) ? 1 : 0;
                },
                'desc'
            );
        },
        getPositionByUser(state) {
            return (user) => {
                return _.find(state.positions, {
                    id: parseInt(user?.assigned_position_id),
                })?.name;
            };
        },
    },
});
