import _ from 'lodash';

const changedViewsRouterHistory = [];
export var useNextRouteState = [];

const isRouteParamsSame = (params1, params2) => {
    return _.isEqual(params1 || {}, params2 || {});
};

export const useIsSameStateToRoute = (to, state) => {
    return (
        (state.to.path && state.to.path == to.path) ||
        (state.to.name && state.to.name == to.name)
    );
    isRouteParamsSame(state.to.params, to.params);
};

export const pushStateRoute = (
    to,
    state,
    once = false,
    redirectType = 'push'
) => {
    //Save only last X routes to not overload history
    useNextRouteState = useNextRouteState.slice(-30);

    for (let i in useNextRouteState) {
        const prevState = useNextRouteState[i];

        //Remove old uncompleted state for this route.
        //Those data will be passed into new state.
        if (useIsSameStateToRoute(to, prevState)) {
            useNextRouteState.splice(i, 1);

            state = { ...prevState.state, ...state };
        }
    }

    useNextRouteState.push({ to, state, once });

    if (redirectType) {
        useRouter()[redirectType || 'push'](to);
    }
};

export const useRouteData = async (
    key,
    onDataMissingCallback,
    callOnDataExists = false,
    cacheMinutage = 5
) => {
    onDataMissingCallback = onDataMissingCallback || (() => ({}));

    //Fallback
    cacheMinutage = cacheMinutage || 5;

    let data,
        route = useRoute();

    if (!_.isNil(route.meta[key])) {
        data = route.meta[key];

        if (callOnDataExists) {
            data = await onDataMissingCallback(data);
        }
    }

    //If data are missing in request, we will save them for some period.
    //To not load them again in a short distance of time.
    else {
        data = await onDataMissingCallback();

        //TODO:
        //cacheMinutage

        //Push state for future backward states.
        pushStateRoute(
            { name: route.name, path: route.path, params: route.params },
            _.fromPairs([[key, data]]),
            true,
            false
        );
    }

    return data;
};

export const useFlushRouteData = (key) => {
    const route = useRoute();

    //Delete from current route
    if (key in route.meta || {}) {
        delete route.meta[key];
    }

    for (let i in useNextRouteState) {
        const prevState = useNextRouteState[i];

        //Remove old uncompleted state for this route.
        //Those data will be passed into new state.
        if (
            useIsSameStateToRoute(
                _.pick(route, ['name', 'path', 'params']),
                prevState
            ) &&
            key in prevState.state
        ) {
            delete prevState.state[key];
        }
    }
};

/**
 * Initialize view enter only when route params has been changed, so other row should be accessed.
 * We will skip back route event.
 *
 * @param  callable  callback
 */
export const onIonChangedViewWillEnter = (callback) => {
    const route = useRoute();

    const isSame = (a, b) => {
        return (a.path && a.path == b.path) || (a.name && a.name == b.name);
    };

    //When this page is reinitialized, then flush all previous history for this route.
    //We want force reinitialize in this case.
    changedViewsRouterHistory.forEach((r, i) => {
        if (isSame(route, r)) {
            changedViewsRouterHistory.splice(i, 1);
        }
    });

    onIonViewWillEnter(() => {
        //Check if previous same page has been loaded with same route params.
        //If yes, then does not fire initialization callback.
        for (var i = changedViewsRouterHistory.length - 1; i >= 0; i--) {
            let prevRoute = changedViewsRouterHistory[i];

            if (isSame(prevRoute, route)) {
                //If last route of same page is with same params as previously hitted one. Disable reloading of content.
                if (
                    isRouteParamsSame(prevRoute.params, route.params) &&
                    isRouteParamsSame(prevRoute.query, route.query)
                ) {
                    return;
                }

                break;
            }
        }

        //Push route params.
        changedViewsRouterHistory.push(
            _.pick(route, ['name', 'path', 'params', 'query'])
        );

        //Initialize callback
        callback();
    });
};
