import validate from "/var/www/scorentino.com/data/sub/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import pass_45state_45global from "/var/www/scorentino.com/data/sub/app/middleware/pass-state.global.js";
import manifest_45route_45rule from "/var/www/scorentino.com/data/sub/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  pass_45state_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/var/www/scorentino.com/data/sub/app/middleware/auth.js"),
  "guest-incompleted": () => import("/var/www/scorentino.com/data/sub/app/middleware/guest-incompleted.js"),
  guest: () => import("/var/www/scorentino.com/data/sub/app/middleware/guest.js"),
  "registration-verified": () => import("/var/www/scorentino.com/data/sub/app/middleware/registration-verified.js")
}