<template>
    <OptionsModal :modal="modal">
        <template v-if="message">
            <!-- prettier-ignore -->
            <a @click="useLazyClick($event, async () => await deleteEvent(message))" class="--red">
                {{ __('Vymazať správu') }}
            </a>
        </template>
    </OptionsModal>
</template>

<script setup>
import _ from 'lodash';

const props = defineProps({
    modal: { default: 'message-actions' },
});

const chatStore = useChatStore();

//Get termine from local events, to keep updates also when this modal is opened
const message = computed(() => useGetModalData(props.modal)?.message);

const deleteEvent = async (message) => {
    useOpenModalCloseCurrent('modal-confirm', {
        title: __('Vymazať správu'),
        // prettier-ignore
        message : __('Naozaj si prajete vymazať túto správu?'),
        success: async () => {
            try {
                // prettier-ignore
                let response = await useAxios().$deleteOnline(
                    '/api/chat/' + message.thread_id + '/messages/' + message.id
                );

                useAutoAjaxResponse(response);

                // Remove message from store
                _.remove(chatStore.threads[message.thread_id]?.messages || [], {
                    id: message.id,
                });
            } catch (e) {
                useAutoAjaxResponse(e);

                //Do not close modal on error
                return false;
            }
        },
    });
};
</script>

<style lang="scss" scoped></style>
