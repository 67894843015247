<template>
    <header class="desktop-header">
        <AppLogo class="desktop-header__logo" @click="useGoToFeed()" />

        <AppFilter :withTypes="true" @search="goToSearch()">
            <template v-slot:actions="{ form }">
                <FormButton
                    visual="bordered"
                    full
                    class="filter__actions-btn"
                    v-if="form.type === 'event'"
                    @click="useOpenModal('create-event')"
                >
                    <span class="icon --event"></span>
                    {{ __('Vytvoriť vlastnú partičku') }}
                </FormButton>
            </template>
        </AppFilter>

        <FormButton
            visual="bordered"
            class="new-event"
            @click="useOpenModal('create-event')"
        >
            <span class="icon --event"></span>
            {{ __('Vytvoriť partičku') }}
        </FormButton>
    </header>
</template>

<script setup>
const route = useRoute();
const goToSearch = () => {
    if (useIsDesktop() && route.name == 'app-feed-search') {
        useEmitter().emit('search');
    } else {
        pushStateRoute({ name: 'app-feed-search' }, { search: true }, true);
    }
};
</script>

<style lang="scss" scoped>
.desktop-header {
    position: fixed;
    height: 8rem;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: var(--ion-background-color);
    border-bottom: 1px solid var(--card-border-color);
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;
    display: none;
    gap: 1rem;

    @include more-than(md) {
        display: flex;

        body:has(.--auth-layout) & {
            display: none !important;
        }
    }
}

.new-event {
    position: relative;
    padding-left: 5rem;
    font-size: 1.2rem;
}

.icon {
    position: absolute;
    left: 1.8rem;
    width: 2rem;
    height: 2rem;

    &.--event {
        background: url($basePath + 'icons/event.svg') no-repeat center;
        background-size: 100% auto;
    }
}
</style>
