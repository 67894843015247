export const useOtpStore = defineStore('otp', {
    persist: true,

    state() {
        return {
            otp: null,
            verified: false,
            token: null,
            options: {},
        };
    },

    actions: {
        reset() {
            this.otp = null;
            this.verified = false;
            this.token = null;
            this.options = {};
        },
        proceedToRoute(options) {
            const route = useRoute();
            let { to, from, next } = options;

            from = from || {
                name: route.name,
                params: route.params,
            };

            options.from = from;

            this.options = options;

            useRouter().push(
                to || {
                    name: 'code',
                    query: {
                        //For development purposes to see token in query
                        token: this.otp.token,
                    },
                }
            );
        },
        onCompleted() {
            //Bind data store from callback data
            if (this.options.callback) {
                useBindPiniaStore(this.options.callback);
            }

            //Redirect to the route
            else if (this.options.next) {
                useRouter().push(this.options.next);
            }
        },
    },

    getters: {
        request() {
            if ( !this.otp ){
                return {};
            }

            return {
                token: this.token,
                verificator: this.otp.verificator,
            };
        },
    },
});
